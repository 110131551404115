import { SvgIconProps, createSvgIcon } from '@mui/material';

const LightIcon = createSvgIcon(
	<>
		<circle cx="12" cy="12" r="5.25" strokeWidth="1.5" stroke="currentColor" />
		<path
			d="M12 4V1"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M12 23L12 20"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M4 12H1"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M23 12H20"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M6.12034 6.57445L3.99902 4.45312"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M20.001 19.5469L18.001 17.5469"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M17.8797 6.57445L20.001 4.45312"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
		<path
			d="M3.99902 19.5469L5.99902 17.5469"
			strokeWidth="1.5"
			strokeLinecap="round"
			stroke="currentColor"
		/>
	</>,
	'Sun'
);

export function LightMode({ sx, ...props }: SvgIconProps) {
	return (
		<LightIcon
			sx={{ width: 24, height: 24, fill: 'none', ...sx }}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
