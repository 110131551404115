export const NAME_REG_EXP = /^[a-zA-Zа-яА-яёЁ-\s]+$/g;
export const TEXT_FIELD_REG_EXP =
	/^[a-zA-Zа-яА-яёЁ0-9][a-zA-Zа-яА-яёЁ0-9\s*/.,:;_/'/`/’(/)*-]+$/;
export const TEXT_FIELD_WITH_BRACKETS_REG_EXP =
	/^[a-zA-Zа-яА-яёЁ0-9({[<][a-zA-Zа-яА-яёЁ0-9\s*/.,:;_/'/`/’(/)*-{}[\]<>&]+$/;
export const EMAIL_REG_EXP =
	/^[!#-'*+/-9=?A-Z^-~-.]+@([A-Za-z-])+(\.[\w-]{2,6})+$/g;
export const MODSEN_DOMAIN_REG_EXP = /^[\w.-]+@modsen-software\.com$/;
export const URL_REG_EXP = /^https?:\/\//i;
export const PHONE_REG_EXP = /^([+][\s0-9]+[,]?)$/gm;
export const PRICE_REG_EXP = /^(\d{1,3})*(\.\d{1,2})?$/gm;
export const LETTERS_AND_NUMBERS_REG_EXP = /[a-zA-Zа-яА-яёЁ\d]/;
export const ONLY_PUNCTUATION_OR_SPACES_EXP =
	/^[!#№@"$;%:^?&*()_=+~`{}[\]\\|/"':;><.,\s-]+$/;

export const regexes = [
	/year|quarter/,
	/Clients|Projects/,
	/Departments/,
	/CEO|CTO/,
	/Head|Engineer/,
	/Departments|Export as/,
];
