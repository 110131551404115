import moment from 'moment';

import { generateYearsCompanyExistence } from '@/helpers/date';

export const date = new Date();
export const currentDate = moment(date);

export const COMPANY_FOUNDATION_DATE = 2020;

export const MONTH_IN_YEAR = 12;

export const DAY_AFTER_THREE_MONTHS = moment(date).add({ months: 3 });

export const TIME_PERIOD = {
	Year: 'Year',
	Month: 'Month',
	Quarter: 'Quarter',
};

export const quarters = [
	'January-March',
	'April-June',
	'July-September',
	'October-December',
];

export const weekDays = [
	'Sunday',
	'Monday',
	'Tuesday',
	'Wednesday',
	'Thirsday',
	'Friday',
	'Saturday',
];

export const months = [
	'January',
	'February',
	'March',
	'April',
	'May',
	'June',
	'July',
	'August',
	'September',
	'October',
	'November',
	'December',
];

export const dateNavigatorSelectors = [
	{
		title: TIME_PERIOD.Year,
		items: generateYearsCompanyExistence(),
	},
	{
		title: TIME_PERIOD.Month,
		items: months,
	},
	{
		title: TIME_PERIOD.Quarter,
		items: quarters,
	},
];

export const MIN_SELECT_YEAR = 1900;
export const MAX_SELECT_YEAR = 2100;
