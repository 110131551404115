import { Pagination } from '@mms/mms-ui-library';
import {
	Skeleton,
	SortDirection as MUISortDirection,
	TableCell,
	TableRow,
	TableBody as TBody,
} from '@mui/material';
import { MouseEvent } from 'react';

import {
	ROWS_PER_PAGE_OPTIONS_DEFAULT,
	SortDirection,
} from '@/constants/index';

import {
	BoxFullWidth,
	MaterialTableFullWidth,
	StyledPaper,
	StyledScrollbar,
	TablePaginationWrapper,
} from './styles';
import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import type { TableProps } from './types';

export function Table({
	loading,
	data,
	onOpen,
	loadingRows = 5,
	fetching,
	page,
	setPage,
	rowsPerPage,
	rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS_DEFAULT,
	setRowsPerPage,
	totalRows,
	order,
	setOrder,
	orderBy,
	setOrderBy,
	extendImg = false,
	extendAvatar = false,
	title,
	blockedItems,
	isShowPagination = true,
	isEquipment = false,
	height,
}: TableProps) {
	const handleRequestSort = (_event: MouseEvent, property: string) => {
		const isAsc = orderBy === property && order === SortDirection.ASC;
		setOrder?.(isAsc ? SortDirection.DESC : SortDirection.ASC);
		setOrderBy?.(property);
	};
	const handleChangePage = (newPage: number) => {
		setPage?.(newPage);
	};
	const handleChangeRowsPerPage = (perPage: number) => {
		setRowsPerPage?.(perPage);
		setPage?.(0);
	};

	return (
		<BoxFullWidth height={height}>
			<StyledPaper page={title} height={height}>
				<StyledScrollbar
					scroll
					withPagination={isShowPagination}
					height={height}
					maxWidth=""
					maxHeight=""
				>
					<MaterialTableFullWidth aria-labelledby="tableTitle" stickyHeader>
						{loading || fetching ? (
							<TBody>
								<TableRow>
									{Array(data?.headCells?.length ?? 0)
										.fill(null)
										.map((cell, cellIndex) => (
											<TableCell key={`${cell}-${cellIndex}`}>
												<Skeleton variant="rectangular" animation="wave" />
											</TableCell>
										))}
								</TableRow>
							</TBody>
						) : (
							<TableHead
								order={order as MUISortDirection}
								orderBy={orderBy as string}
								onRequestSort={handleRequestSort}
								headCells={data.headCells}
							/>
						)}
						{loading || fetching ? (
							<TBody>
								{Array(loadingRows)
									.fill(null)
									.map((el, index) => (
										<TableRow key={`${el}-${index}`}>
											{Array(data?.headCells?.length ?? 0)
												.fill(null)
												.map((cell, cellIndex) => (
													<TableCell key={`${cell}-${cellIndex}`}>
														<Skeleton variant="rectangular" animation="wave" />
													</TableCell>
												))}
										</TableRow>
									))}
							</TBody>
						) : (
							<TableBody
								rows={data?.rows}
								order={order as MUISortDirection}
								orderBy={orderBy as string}
								page={page as number}
								rowsPerPage={rowsPerPage as number}
								colSpan={data.headCells.length + 1}
								onOpen={onOpen}
								rowCount={data?.rows?.length}
								headCells={data.headCells}
								extendImg={extendImg}
								extendAvatar={extendAvatar}
								blockedItems={blockedItems}
								title={title}
								isEquipment={isEquipment}
							/>
						)}
					</MaterialTableFullWidth>
				</StyledScrollbar>
				{!loading && !fetching && isShowPagination && (
					<TablePaginationWrapper>
						<Pagination
							perPageOptions={rowsPerPageOptions}
							total={totalRows ?? 0}
							perPage={rowsPerPage ?? 0}
							currentPage={page as number}
							onCurrentPageChange={handleChangePage}
							onPerPageChange={handleChangeRowsPerPage}
						/>
					</TablePaginationWrapper>
				)}
			</StyledPaper>
		</BoxFullWidth>
	);
}
