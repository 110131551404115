import axios, { AxiosError } from 'axios';
import type { Moment } from 'moment';
import { useMutation, UseMutationOptions } from 'react-query';

import {
	EQUIPMENT_CREATED,
	GET_EQUIPMENT_API_ENDPOINT,
	NotificationType,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { getDateAsResponseType } from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';
import { EquipmentItemRequest, EquipmentItemResponse } from '@/types/Equipment';

export const useCreateEquipment = (
	toast: ToastContextValues,
	options?: UseMutationOptions<
		EquipmentItemResponse,
		AxiosError,
		EquipmentItemRequest
	>
) =>
	useMutation({
		...options,
		mutationFn: async ({
			name,
			equipmentType,
			serialNumber,
			purchasePrice,
			purchaseCurrency,
			purchasePriceUsd,
			purchaseDate,
			purchasePlace,
			guaranteeDate,
			characteristics,
			approverId,
			location,
			comment,
			activeHolderRequest,
		}) => {
			try {
				const { data } = await axios.post(GET_EQUIPMENT_API_ENDPOINT, {
					name,
					equipmentType,
					serialNumber,
					purchasePrice: Number(purchasePrice),
					purchasePriceUsd,
					purchaseDate: getDateAsResponseType(purchaseDate as Moment),
					purchasePlace,
					guaranteeDate: getDateAsResponseType(guaranteeDate as Moment),
					characteristics,
					approverId,
					location,
					comment: comment || null,
					activeHolder: activeHolderRequest,
					purchaseCurrency,
				});
				toast.open(
					createToast(NotificationType.SUCCESS, null, EQUIPMENT_CREATED)
				);

				return data;
			} catch (error) {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return Promise.reject(error);
			}
		},
	});
