import logoDarkMode from '@/assets/logo/logoDarkMode.png';
import logoWhiteMode from '@/assets/logo/logoWhiteMode.png';
import { useColorMode } from '@/context/Theme';

import { Image, Title } from './styles';

export function Heading() {
	const { mode } = useColorMode();

	return (
		<>
			<Image
				src={mode === 'light' ? logoWhiteMode : logoDarkMode}
				alt="logo"
				loading="lazy"
			/>
			<Title>Management System</Title>
		</>
	);
}
