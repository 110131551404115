import { getFullName } from '@/helpers/utils';
import { LookupUserResponse } from '@/types/UserInfo';

export const normalizeUser =
	(users: LookupUserResponse[] | undefined, isFetching: boolean) => () =>
		isFetching || !users?.length
			? []
			: users.map(({ id, lastName, firstName }) => ({
					id,
					name: getFullName(firstName, lastName),
			  }));
