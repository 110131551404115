import { Block, PopUp, Warning } from '@mms/mms-ui-library';
import Stack from '@mui/material/Stack';
import { Moment } from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { PopUpBody } from '../PopUpBody';

import { ProtectedView } from '@/components/ProtectedView';
import {
	ALREADY_BOOKED,
	BOOK_ANOTHER_DATE,
	REPEAT_WEEKS_MAX_VALUE,
	REPEAT_WEEKS_MIN_VALUE,
	BookingQueriesKeys,
	Directions,
	RequestTypes,
	Roles,
	SURE_TO_BLOCK,
	BLOCK_DIALOG_HEADER,
	isEmployee,
} from '@/constants/index';
import { useAuth, BookingContext, useToast } from '@/context/index';
import {
	getTextFromMultipleDate,
	getDeskBlockDialogSubtext,
	getTooltipTitle,
} from '@/helpers/booking';
import { useCheckCollisions, useCreateBooking } from '@/queries/booking';

import { BookButton } from './BookButton';
import { Repetitive } from './Repetitive';
import { Wrapper } from './styles';
import type { BookingControlsProps } from './types';

export function BookingControls({ isControlDisabled }: BookingControlsProps) {
	const { CEO, CTO } = Roles;
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const [isChecked, setIsChecked] = useState(false);
	const [open, setOpen] = useState(false);
	const [weeksCount, setWeeksCount] = useState(1);
	const { role, id } = useAuth();

	const { selected, firstDay, lastDay, employee, init, selectedWorkplace } =
		useContext(BookingContext);
	const weeksInterval = isChecked ? weeksCount : undefined;

	const toast = useToast();
	const queryClient = useQueryClient();
	const dateBookingAlreadyExist = getTextFromMultipleDate([
		firstDay as Moment,
		lastDay as Moment,
	]);

	const flexibleWarning =
		id === employee?.id || !employee ? 'You have' : `${employee.name} has`;

	useEffect(() => {
		if (!isControlDisabled) {
			setWeeksCount(1);
		}
	}, [isControlDisabled]);

	useEffect(() => {
		setIsChecked(false);
	}, [firstDay]);

	const handleChange = useCallback(() => {
		if (isChecked) {
			setWeeksCount(1);
		}
		setIsChecked(!isChecked);
	}, [isChecked]);

	const handleWeeksChange = useCallback(
		(direction: string) => () => {
			const isUp = direction === Directions.Up;
			const newValue = isUp ? weeksCount + 1 : weeksCount - 1;

			if (
				(isUp && newValue <= REPEAT_WEEKS_MAX_VALUE) ||
				(!isUp && newValue >= REPEAT_WEEKS_MIN_VALUE)
			) {
				setWeeksCount(newValue);
			}
		},
		[weeksCount]
	);

	const { data } = useCheckCollisions(
		selected,
		firstDay,
		lastDay,
		weeksCount,
		toast
	);

	const isButtonsDisabled = selected === 0 || !firstDay || !lastDay;
	const isBlockDisabled =
		isButtonsDisabled || Boolean(selectedWorkplace?.isBlocked);

	const handleClose = () => setOpen(false);

	const { mutate, isError } = useCreateBooking(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(BookingQueriesKeys.bookings);
				queryClient.refetchQueries(BookingQueriesKeys.reservations);
				init();
			},
			onError: () => {
				setOpen(true);
			},
		},
		toast
	);

	const handleBookClick = useCallback(() => {
		if (selected === 0) {
			return;
		}
		mutate({
			workplaceNumber: selected,
			weeksInterval,
			fromDate: firstDay,
			toDate: lastDay,
			requestType: RequestTypes.CreateSelf,
			userId:
				id === employee?.id || isEmployee(role) ? undefined : employee?.id,
		});
	}, [selected, weeksCount, firstDay, lastDay, id, employee?.id, isChecked]);

	const handleBlock = useCallback(() => {
		if (selected === 0) {
			return;
		}
		mutate({
			workplaceNumber: selected,
			fromDate: firstDay,
			toDate: lastDay,
			requestType: RequestTypes.CreateBlock,
			userId: employee?.id,
		});

		setIsDialogOpen(false);
	}, [selected, firstDay, lastDay, employee?.id, id]);

	const initiateDeskBlock = useCallback(() => {
		if (isButtonsDisabled) {
			return;
		}
		setIsDialogOpen(true);
	}, [isButtonsDisabled]);

	const handleCloseDialog = useCallback(() => {
		setIsDialogOpen(false);
	}, []);

	const selectedDate = getTextFromMultipleDate([firstDay] as Moment[]);

	return (
		<>
			<Wrapper>
				<Repetitive
					isChecked={isChecked}
					isControlDisabled={isControlDisabled}
					weeksCount={weeksCount}
					handleWeeksChange={handleWeeksChange}
					handleChange={handleChange}
				/>
				<Stack direction="row" gap={1}>
					<BookButton
						disabled={(isButtonsDisabled || data) ?? false}
						tooltipTitle={getTooltipTitle(lastDay as Moment, selected) || ''}
						onClick={handleBookClick}
						color="accent"
					>
						Book a desk
					</BookButton>
					<ProtectedView roles={[CEO, CTO]}>
						<BookButton
							disabled={isBlockDisabled}
							color="primary"
							tooltipTitle={getTooltipTitle(lastDay as Moment, selected) || ''}
							onClick={initiateDeskBlock}
						>
							Block a desk
						</BookButton>
					</ProtectedView>
				</Stack>
			</Wrapper>
			{isError && open && (
				<PopUp
					title="WARNING"
					headerIcon={<Warning />}
					onClose={handleClose}
					type="approve"
					headerType="warning"
				>
					<PopUpBody
						text={`${ALREADY_BOOKED} ${selectedDate}. ${BOOK_ANOTHER_DATE}`}
					/>
				</PopUp>
			)}
			{!isButtonsDisabled && isDialogOpen && (
				<PopUp
					title={BLOCK_DIALOG_HEADER}
					headerIcon={<Block />}
					onClose={handleCloseDialog}
					type="approve"
					showCloseButton={false}
					controls={{
						negativeControl: { onClick: handleCloseDialog },
						positiveControl: { onClick: handleBlock },
					}}
				>
					<PopUpBody
						text={SURE_TO_BLOCK}
						subtext={getDeskBlockDialogSubtext(firstDay, selected)}
					/>
				</PopUp>
			)}
		</>
	);
}
