import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Routes } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';

import type { ProtectedRouteProps } from './types';

/**
 * Hide routes if user doesn't have token and data of current user
 */
export function ProtectedRoute({
	redirectPath = Routes.signIn,
	children,
}: PropsWithChildren<ProtectedRouteProps>) {
	const { email } = useAuth();
	const location = useLocation();

	if (!email) {
		return <Navigate to={redirectPath} replace state={{ from: location }} />;
	}

	return children as JSX.Element;
}
