import { Button, DropDown } from '@mms/mms-ui-library';
import { memo } from 'react';

import { exportAsConfigOptions } from '@/constants/exportConfigs';

import { ExportControlsWrapper } from './styles';
import type { ExportControlsProps } from './types';

function ExportControlsInner({
	exportFileType,
	isMobile,
	handleExportFile,
	handleExportFileChange,
}: ExportControlsProps) {
	return (
		<ExportControlsWrapper isMobile={isMobile}>
			<DropDown
				options={exportAsConfigOptions}
				dataTestId="select-export"
				width="medium"
				onChange={handleExportFileChange}
				value={exportFileType}
			/>
			<Button icon="download" type="button" onClick={handleExportFile} />
		</ExportControlsWrapper>
	);
}

export const ExportControls = memo(ExportControlsInner);
