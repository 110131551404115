import { Typography } from '@mms/mms-ui-library';
import Backdrop from '@mui/material/Backdrop';
import { Moment } from 'moment';
import { useMemo, useCallback, memo, useContext } from 'react';

import { MAX_SEATS } from '@/constants/index';
import { BookingContext } from '@/context/index';
import { useFilledDesksWithRangeDate } from '@/hooks/useFilledDesksWithRangeDate';

import { Desk } from './Desk';
import { Legend } from './Legend';
import {
	BookingWorkplaceWrapper,
	Wall,
	Workplace,
	WorkplaceWrapper,
} from './styled';
import type { DeskType } from './types';

function BookingWorkplaceInner() {
	const {
		selected,
		setSelected,
		workplaces,
		firstDay,
		lastDay,
		error,
		setWorkplaces,
	} = useContext(BookingContext);
	useFilledDesksWithRangeDate(
		firstDay as Moment,
		lastDay as Moment,
		setWorkplaces,
		error
	);
	const handleClick = useCallback(
		(workplaceNumber: number) => {
			if (lastDay) {
				setSelected(workplaceNumber);
			}
		},
		[lastDay]
	);

	const desks = useMemo(
		() =>
			Array.from({ length: MAX_SEATS }, (_, i) => ({
				workplaceNumber: i + 1,
			})).reduce((acc, desk) => {
				const newDesk: DeskType = workplaces.find(
					({ workplaceNumber }) => workplaceNumber === desk.workplaceNumber
				) || { ...desk };

				acc.push(
					<Desk
						{...newDesk}
						key={newDesk.workplaceId ?? `desk-${newDesk.workplaceNumber}`}
						number={newDesk.workplaceNumber}
						onClick={handleClick}
						isSelected={selected === desk.workplaceNumber}
					/>
				);

				return acc;
			}, [] as JSX.Element[]),
		[selected, workplaces]
	);

	return (
		<BookingWorkplaceWrapper>
			<Typography variant="large">Booking Desk</Typography>
			<WorkplaceWrapper>
				<Workplace maxHeight="772px" maxWidth="100%" scroll>
					{desks}
					<Wall />
					{error && <Backdrop sx={{ position: 'absolute' }} open invisible />}
				</Workplace>
				<Legend />
			</WorkplaceWrapper>
		</BookingWorkplaceWrapper>
	);
}

export const BookingWorkplace = memo(BookingWorkplaceInner);
