import { debounce as _debounce } from 'lodash';
import { useRef, useState, useEffect, RefObject } from 'react';

const DEBOUNCE_WAIT_TIME = 300;

interface UseElementSizeOptions {
	debounce?: boolean;
}

interface Size {
	width: number;
	height: number;
}

export const useElementSize = <T extends HTMLDivElement>({
	debounce,
}: UseElementSizeOptions = {}): {
	ref: RefObject<T>;
	size: Size;
} => {
	const ref = useRef<T>(null);
	const [size, setSize] = useState<Size>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		if (!ref.current) {
			return;
		}

		const resizeHandler: ResizeObserverCallback = (entries) => {
			const entry = entries[0];

			if (entry.contentRect) {
				setSize({
					width: entry.contentRect.width,
					height: entry.contentRect.height,
				});
			}
		};

		const debouncedResizeHandler = _debounce(resizeHandler, DEBOUNCE_WAIT_TIME);
		const resizeObserver = new ResizeObserver(
			debounce ? debouncedResizeHandler : resizeHandler
		);

		resizeObserver.observe(ref.current);

		// eslint-disable-next-line consistent-return
		return () => resizeObserver.disconnect();
	}, [ref, debounce]);

	return { ref, size };
};
