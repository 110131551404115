import { Scrollbar } from '@mms/mms-ui-library';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import { Field } from 'formik';
import { Select as FormikSelect } from 'formik-mui';

import { ITEM_PADDING_TOP, MenuProps } from './styles';
import type { SelectFieldProps } from './types';

export function SelectField({
	options,
	label,
	isProfile = false,
	...otherProps
}: SelectFieldProps) {
	return (
		<Field
			component={FormikSelect}
			label={label}
			variant="standard"
			IconComponent={ExpandMoreIcon}
			MenuProps={MenuProps}
			{...otherProps}
		>
			<Scrollbar
				maxHeight={`${
					MenuProps.PaperProps.style.maxHeight - ITEM_PADDING_TOP
				}px`}
				maxWidth="100%"
				scroll
			>
				{options.map(({ id, description, name }) => (
					<MenuItem key={id} value={isProfile ? name : description}>
						{description}
					</MenuItem>
				))}
			</Scrollbar>
		</Field>
	);
}
