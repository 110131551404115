import * as yup from 'yup';

import { MAX_SELECT_YEAR, MIN_SELECT_YEAR } from '@/constants/date';
import { checkOptionsNotZero, checkYearInValidRange } from '@/forms/utils';

export const validationSchema = yup.object().shape({
	name: yup
		.string()
		.max(250, 'Max. length 250 characters.')
		.required('Enter Equipment name.'),
	equipmentType: yup
		.string()
		.test('all-spaces-check', 'Select Equipment type.', checkOptionsNotZero)
		.required('Select Equipment type.')
		.nullable(),
	serialNumber: yup
		.string()
		.max(100, 'Max. length 100 characters.')
		.required('Enter Serial number.'),
	purchasePrice: yup.string().required('Enter Purchase price.'),
	purchaseCurrency: yup.string(),
	purchasePriceUsd: yup.string().required('Enter price.'),
	purchasePriceUsdCurrency: yup.string(),
	purchaseDate: yup
		.string()
		.required('Select Purchase date.')
		.nullable()
		.test(
			'valid-year-check',
			`Date range should be from ${MIN_SELECT_YEAR} to ${MAX_SELECT_YEAR}.`,
			checkYearInValidRange
		),
	purchasePlace: yup
		.string()
		.max(100, 'Max. length 100 characters.')
		.required('Enter Purchase place.'),
	guaranteeDate: yup
		.string()
		.required('Select Guarantee date.')
		.nullable()
		.test(
			'valid-year-check',
			`Date range should be from ${MIN_SELECT_YEAR} to ${MAX_SELECT_YEAR}.`,
			checkYearInValidRange
		),
	characteristics: yup
		.string()
		.max(1000, 'Max. length 1000 characters.')
		.required('Enter Equipment characteristics.'),
	approver: yup
		.string()
		.test('all-spaces-check', 'Select Equipment type.', checkOptionsNotZero)
		.required('Select Approver.')
		.nullable(),
	location: yup
		.string()
		.test('all-spaces-check', 'Select Equipment type.', checkOptionsNotZero)
		.required('Select Location.')
		.nullable(),
	comment: yup.string().max(1000, 'Max. length 1000 characters.').nullable(),
});
