import { Roles } from '../constants';

import type { InitialAuthState } from '@/context/types';

export const initialAuthState: InitialAuthState = {
	token: null,
	role: Roles.SE,
};

export const removeAuthState = () => {
	sessionStorage.clear();
};

export const getAuthState = (): InitialAuthState => {
	let state = initialAuthState;
	try {
		const storedAuthState = sessionStorage.getItem('auth');

		if (storedAuthState) {
			state = JSON.parse(storedAuthState);
		}

		return state;
	} catch (error) {
		return state;
	}
};

export const setAuthState = (authState: Partial<InitialAuthState>): void => {
	const state = { ...getAuthState(), ...authState };
	const serializedState = JSON.stringify(state);

	sessionStorage.setItem('auth', serializedState);
};

export const isUserLoggedIn = () => Boolean(sessionStorage.getItem('auth'));
