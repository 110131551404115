import { Toast } from '@mms/mms-ui-library';

import { NotificationType } from '@/constants/notifications';

export const createToast = (
	type: NotificationType,
	title: string | JSX.Element | null,
	description: string | JSX.Element,
	toastId?: string | number
) => ({
	message: (
		<Toast status={type} title={title} text={description} onClose={() => {}} />
	),
	settings: toastId ? { type, toastId } : { type },
});
