import { SyntheticEvent, useMemo, useState } from 'react';

import { EquipmentControls } from '@/components/Equipment/EquipmentControls';
import { EquipmentFilters } from '@/components/Equipment/EquipmentControls/types';
import { PageHeader } from '@/components/PageHeader';
import { SearchInput } from '@/components/SearchInput';
import { Table } from '@/components/Table';
import {
	isHeadOfDepartment,
	SortDirection,
	ROWS_PER_PAGE,
	ROWS_PER_PAGE_OPTIONS,
	INITIAL_ORDER_BY_VALUE,
	EQUIPMENT_PAGE_VERTICAL_GAPS,
} from '@/constants/index';
import { PageTitles } from '@/constants/pageTitles';
import { EquipmentProvider } from '@/context/Equipment';
import { useToast, useAuth } from '@/context/index';
import { EquipmentForm } from '@/forms/EquipmentForm';
import { parseMomentDateToUTCString } from '@/helpers/date';
import { useDebounce } from '@/hooks/useDebounce';
import { useElementSize } from '@/hooks/useElementSize';
import { usePageWithSearch } from '@/hooks/usePageWithSearch';
import { useEquipmentsValues } from '@/pages/hooks/useEquipmentsValues';
import { useShowModal } from '@/pages/hooks/useShowModal';
import { PageWrapper } from '@/pages/styles';
import { useGetEquipments } from '@/queries/equipment/useGetEquipments';

export function EquipmentPage() {
	const { role, position } = useAuth();
	const [rowsPerPage, setRowsPerPage] = useState(Number(ROWS_PER_PAGE));
	const [order, setOrder] = useState(SortDirection.ASC);
	const [orderBy, setOrderBy] = useState(INITIAL_ORDER_BY_VALUE);
	const { page, setPage, search, setSearch } = usePageWithSearch();
	const [filters, setFilters] = useState<EquipmentFilters>({});
	const debouncedSearch = useDebounce(search, 500);
	const toast = useToast();
	const { data, dataUpdatedAt, isLoading, isFetching, refetch } =
		useGetEquipments(
			toast,
			page + 1,
			Number(rowsPerPage),
			order,
			orderBy,
			debouncedSearch,
			filters?.availability === 'Available' ? true : null,
			filters?.user?.id,
			filters?.equipmentType?.name,
			filters?.approver?.id,
			filters?.equipmentLocation?.name,
			parseMomentDateToUTCString(filters?.firstDay),
			parseMomentDateToUTCString(filters?.lastDay),
			{ enabled: Boolean(position) }
		);
	const values = useEquipmentsValues(data!, dataUpdatedAt, page, rowsPerPage);

	const { id, handleOpen, handleClose, isOpen } = useShowModal();

	const handleEdit = (
		_event?: SyntheticEvent,
		equipmentId?: string | number
	) => {
		handleOpen(equipmentId);
	};
	const { ref: pageWrapperRef, size: pageWrapperSize } = useElementSize({
		debounce: true,
	});

	const { ref: EquipmentControlsRef, size: EquipmentControlsSize } =
		useElementSize({
			debounce: true,
		});

	// TODO: need to set correct Table height by css, not using scripts
	const tableHeight = useMemo(
		() =>
			pageWrapperSize.height -
			EquipmentControlsSize.height -
			EQUIPMENT_PAGE_VERTICAL_GAPS,
		[pageWrapperSize.height, EquipmentControlsSize.height]
	);

	return (
		<>
			<PageWrapper ref={pageWrapperRef}>
				<PageHeader
					title={PageTitles.Equipment}
					divider={false}
					onAdd={!isHeadOfDepartment(role) ? handleOpen : undefined}
					searchInput={
						<SearchInput
							value={search}
							onChange={setSearch}
							placeholder="Search"
						/>
					}
					onRefresh={refetch}
				/>
				<div ref={EquipmentControlsRef}>
					<EquipmentControls
						{...{
							orderBy,
							filters,
							setFilters,
							debouncedSearch,
						}}
						filtersOptions={data?.filterValues}
					/>
				</div>
				<Table
					data={values as any}
					loading={isLoading}
					fetching={isFetching}
					rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
					totalRows={data?.total}
					title={PageTitles.Equipment}
					isEquipment
					extendImg={false}
					extendAvatar={false}
					blockedItems={[]}
					isShowPagination
					onOpen={!isHeadOfDepartment(role) ? handleEdit : undefined}
					{...{
						page,
						setPage,
						rowsPerPage,
						setRowsPerPage,
						order,
						setOrder,
						orderBy,
						setOrderBy,
					}}
					height={tableHeight}
				/>
			</PageWrapper>

			{isOpen && (
				<EquipmentProvider>
					<EquipmentForm
						refetch={refetch}
						id={id as string}
						onClose={handleClose}
					/>
				</EquipmentProvider>
			)}
		</>
	);
}
