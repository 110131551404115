export const FILE_EXTENSIONS = {
	csv: 'csv',
	xlsx: 'xlsx',
	excel: 'excel',
};

export const FILE_EXTENSION_MAPPING = {
	csv: 'csv',
	excel: 'xlsx',
};
