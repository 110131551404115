import { Input } from '@mms/mms-ui-library';
import { styled, Box } from '@mui/material';

import { CrossIcon } from '@/assets/buttons/CrossIcon';

export const TextFieldStyled = styled(Input)(({ theme }) => ({
	width: 260,
	'& input': {
		height: '37px',
		fontSize: theme.typography.pxToRem(15),
	},
}));

export const BoxWrapper = styled(Box)(() => ({
	width: '100%',
	textAlign: 'start',
}));

export const CloseIconStyled = styled(CrossIcon)(({ theme }) => ({
	cursor: 'pointer',
	fontSize: theme.typography.sizes[1],
	padding: theme.spacing(0.25),
	borderRadius: '100%',
	'&:hover': { background: theme.palette.divider },
}));
