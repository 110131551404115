import { getFullName } from '@/helpers/utils';
import { ResponseUser } from '@/queries/types';
import { KnowledgeType } from '@/types/KnowledgeBase';

const compareByNameAsc = (a: KnowledgeType, b: KnowledgeType) => {
	if (a.name < b.name) {
		return -1;
	}

	if (a.name > b.name) {
		return 1;
	}

	return 0;
};

const compareByIdDesc = (a: KnowledgeType, b: KnowledgeType) =>
	Number(b.id) - Number(a.id);

const normalizeUsers = (users: ResponseUser[]) =>
	users.map(({ id, lastName, firstName }) => ({
		id,
		name: getFullName(firstName, lastName),
		description: getFullName(firstName, lastName),
	}));

export const getAvailabilityOptions = (
	availabilityOptions?: KnowledgeType[],
	hasAvailable?: boolean
) => {
	if (!availabilityOptions) {
		return [];
	}

	if (!hasAvailable) {
		return availabilityOptions.filter(({ name }) => name !== 'Available');
	}

	return availabilityOptions.sort(compareByIdDesc);
};

export const getApproversOptions = (users?: ResponseUser[]) => {
	if (!users) {
		return [];
	}

	return normalizeUsers(users);
};

export const getKnowledgeBaseOptions = (
	allOptions?: KnowledgeType[],
	currentOptions?: number[]
) => {
	if (!allOptions || !currentOptions) {
		return [];
	}

	return allOptions.filter(({ id }) => currentOptions.includes(Number(id)));
};

export const getUsersOptions = (users?: ResponseUser[]) => {
	if (!users) {
		return [];
	}

	const normalizedUsers = normalizeUsers(users).filter(
		(user) => user.id !== 0 && user.name !== 'Modsen company'
	);

	return [
		{ id: 0, name: 'Modsen company', description: 'Modsen company' },
		...normalizedUsers,
	];
};
