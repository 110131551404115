export const exportAsConfig = [
	{
		value: 'csv',
	},
	{
		value: 'xlsx',
	},
];

export const exportAsConfigOptions = exportAsConfig.map((item) => item.value);
