const id = 'All';

export const extraFiltersBy = {
	All: 'All',
	Personal: 'Personal',
};

export const filterByPeriod = {
	weekFilter: 'This Week',
	monthFilter: 'This Month',
};

export const initialProjectsValue = {
	id,
	name: 'All Projects',
};

export const initialClientsValue = {
	id,
	name: 'All Clients',
};

export const initialUserFilterValue = {
	id,
	name: 'All Users',
};

export const initialDepartmentsFilterValue = {
	id,
	name: 'All Departments',
};

export const initialAvailabilityFilterValue = 'All';

export const initialEquipmentTypeFilterValue = {
	id: 'All',
	name: 'All Equipment',
};

export const initialEquipmentLocationFilterValue = {
	id: 'All',
	name: 'All Locations',
};

export const initialApproversFilterValue = {
	id: 'All',
	name: 'All Approvers',
};

export const DateFilterVariants = {
	CurrentMonth: 'Current month',
	LastMonth: 'Last month',
	WholeYear: 'Whole year',
};

export const dateFilterOptions = Object.values(DateFilterVariants);

export enum FilterKeys {
	Availability = 'availability',
	EquipmentType = 'equipmentType',
	EquipmentLocation = 'equipmentLocation',
	Approver = 'approver',
	User = 'user',
	FirstDay = 'firstDay',
	LastDay = 'lastDay',
}
