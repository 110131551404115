import Stack from '@mui/material/Stack';
import type { PickersDayProps } from '@mui/x-date-pickers';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import type { DayCalendarSlotsComponentsProps } from '@mui/x-date-pickers/internals';
import moment, { Moment, MomentInput } from 'moment';
import {
	useCallback,
	useRef,
	useState,
	useContext,
	memo,
	useEffect,
	useMemo,
	PropsWithChildren,
	SyntheticEvent,
	ComponentType,
} from 'react';
import { useQueryClient } from 'react-query';

import { CalendarUI } from '@/components/CalendarUI';
import {
	currentDate,
	DAY_AFTER_THREE_MONTHS,
	BookingQueriesKeys,
} from '@/constants/index';
import { BookingContext, useToast } from '@/context/index';
import { HighlightedDays } from '@/context/types';
import {
	getEndOfMonthForRequest,
	getStartOfMonthForRequest,
} from '@/helpers/booking';
import { useGetReservation } from '@/queries/booking/index';

import { Day } from './Day';
import { Input } from './Input';

function BookingTimeInner({ children }: PropsWithChildren) {
	const {
		firstDay,
		setFirstDay,
		lastDay,
		setLastDay,
		highlightedDays,
		setWorkplaces,
		setSelected,
		setError,
		error,
	} = useContext(BookingContext);
	const toast = useToast();
	const [disabled, setDisabled] = useState(false);
	const [hovered, setHovered] = useState(false);
	const [hoveredDateRange, setHoveredDateRange] = useState<(Moment | null)[]>(
		[]
	);

	const isUserClick = useRef(false);

	const [endOfMonth, setEndOfMonth] = useState(
		getEndOfMonthForRequest(currentDate)
	);
	const [startOfMonth, setStartOfMonth] = useState(
		getStartOfMonthForRequest(currentDate)
	);

	const newHighlightedDays: Record<keyof HighlightedDays, number[]> =
		useMemo(() => {
			const result: HighlightedDays = {
				bookedList: [],
				yourList: [],
				blockedList: [],
			};
			Object.keys(highlightedDays).forEach((key) => {
				result[key as keyof HighlightedDays] = highlightedDays[
					key as keyof HighlightedDays
				]
					.filter((day: MomentInput) =>
						moment(day).isBetween(startOfMonth, endOfMonth, 'day', '[]')
					)
					.map((day: MomentInput) => moment(day).date());
			});

			return result;
		}, [highlightedDays, startOfMonth, endOfMonth]);

	const { isLoading, data, dataUpdatedAt } = useGetReservation(
		startOfMonth,
		endOfMonth,
		toast,
		error
	);
	const renderLoading = useCallback(() => <DayCalendarSkeleton />, [isLoading]);

	const isSameDate = moment(firstDay).isSame(lastDay, 'day');
	const queryClient = useQueryClient();

	useEffect(() => {
		if (data) {
			const currentWorkplace = data.find(({ date }: { date: string }) =>
				lastDay?.isValid()
					? moment(date).isSame(lastDay, 'day')
					: moment(date).isSame(currentDate.clone(), 'day')
			);
			setWorkplaces(currentWorkplace?.userWorkplaces ?? []);
		}

		return () => {
			setWorkplaces([]);
			queryClient.cancelQueries([BookingQueriesKeys.reservations]);
		};
	}, [dataUpdatedAt, lastDay, firstDay]);

	const handleMonthChange = useCallback((days: Moment) => {
		setEndOfMonth(getEndOfMonthForRequest(days));
		setStartOfMonth(getStartOfMonthForRequest(days));
		queryClient.cancelQueries([BookingQueriesKeys.reservations]);
	}, []);

	const getAvailableDateRange = useCallback(
		(day: Moment | null) => {
			if (day?.isBefore(currentDate, 'day')) {
				return [currentDate, firstDay];
			}

			if (day?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				return [firstDay, DAY_AFTER_THREE_MONTHS];
			}

			const dateRange = [day, firstDay];

			return day?.isBefore(firstDay) ? dateRange : dateRange.reverse();
		},
		[firstDay]
	);

	const handleMouseEnterDay = useCallback(
		(event: SyntheticEvent, day: unknown) => {
			if (!hovered) {
				return;
			}

			if ((day as Moment).isSame(firstDay)) {
				setHoveredDateRange([]);

				return;
			}

			setHoveredDateRange(getAvailableDateRange(day as Moment));
		},
		[hovered, firstDay]
	);

	const handleMouseLeaveDay = useCallback(() => {
		setHoveredDateRange([]);
	}, []);

	const handleChangeFirstDay = useCallback(
		(newValue: Moment | null) => {
			if (newValue?.isBefore(currentDate.clone(), 'day')) {
				setFirstDay(newValue);
				setError('minDate');

				return;
			}

			if (newValue?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				setFirstDay(newValue);
				setError('maxDate');

				return;
			}

			if (!newValue?.isValid()) {
				setLastDay(newValue);
				setError('pastDate');
				setDisabled(true);

				return;
			}

			if (newValue.isBefore(moment(), 'day')) {
				setLastDay(newValue);
				setError('pastDate');

				return;
			}

			setDisabled(false);
			setError(null);
			setFirstDay(newValue);
			setLastDay(newValue);
			setSelected(0);
		},
		[disabled]
	);

	const handleChangeLastDay = useCallback(
		(newValue: Moment | null) => {
			if (newValue?.isBefore(firstDay, 'day')) {
				setLastDay(newValue);
				setError('minDate');

				return;
			}

			if (newValue?.isAfter(DAY_AFTER_THREE_MONTHS, 'day')) {
				setLastDay(newValue);
				setError('maxDate');

				return;
			}

			if (newValue?.isBefore(moment(), 'day')) {
				setLastDay(newValue);
				setError('pastDate');

				return;
			}

			if (firstDay?.isBefore(moment(), 'day')) {
				setLastDay(newValue);
				setError('pastDate');

				return;
			}
			setError(null);
			setLastDay(newValue);
		},
		[firstDay, lastDay, setError, setLastDay]
	);

	// If you want to change management to date from inputs to calendar, use handleChange instead handleChangeFirstDay, handleChangeLastDay and disable inputs.
	const handleChange = useCallback(
		(newValue: Moment | null) => {
			if (!lastDay && firstDay && newValue?.isBefore(firstDay, 'day')) {
				setFirstDay(newValue);
				setLastDay(firstDay);
				setHovered(false);
				setHoveredDateRange([]);
				isUserClick.current = false;

				return;
			}

			if (!firstDay || lastDay) {
				setFirstDay(newValue);
				setHovered(true);
				setLastDay(null);
			} else {
				setLastDay(newValue);
				setHovered(false);
				setHoveredDateRange([]);
			}
			setEndOfMonth(getEndOfMonthForRequest(newValue));
			setStartOfMonth(getStartOfMonthForRequest(newValue));
			setSelected(0);
			isUserClick.current = false;
		},
		[firstDay, lastDay, setFirstDay, setLastDay]
	);

	return (
		<Stack sx={{ width: 290 }} spacing={2}>
			{children}
			<CalendarUI
				className="booking-calendar"
				value={lastDay}
				loading={isLoading}
				onChange={handleChange}
				onMonthChange={handleMonthChange}
				renderLoading={renderLoading}
				slots={{ day: Day as ComponentType<PickersDayProps<Moment>> }}
				slotProps={{
					day: {
						selectedDay: currentDate.clone(),
						highlightedDays: newHighlightedDays,
						firstDay,
						lastDay,
						isSameDate,
						hovered,
						hoveredDateRange,
						onMouseEnter: handleMouseEnterDay,
						onMouseLeave: handleMouseLeaveDay,
					} as DayCalendarSlotsComponentsProps<Moment>,
				}}
				disabled
				disablePast
			/>
			<Stack
				direction="row"
				justifyContent="space-between"
				spacing={2}
				height={76} // 60 + 16, 60px - input, 16px - validation
			>
				<Input label="From" value={firstDay} onChange={handleChangeFirstDay} />
				<Input
					label="To"
					value={lastDay}
					disabled={disabled}
					onChange={handleChangeLastDay}
				/>
			</Stack>
		</Stack>
	);
}

export const BookingTime = memo(BookingTimeInner);
