import axios from 'axios';
import { useMutation } from 'react-query';

import {
	GET_EQUIPMENT_HISTORY_API_ENDPOINT,
	NotificationType,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';

export const useDeleteLastEquipmentHistoryItem = (toast: ToastContextValues) =>
	useMutation({
		mutationFn: async (id: string) => {
			try {
				const { data } = await axios.delete(
					`${GET_EQUIPMENT_HISTORY_API_ENDPOINT}/${id}/history`
				);

				return data;
			} catch {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return Promise.reject();
			}
		},
	});
