import ba from '@/assets/departments/ba.png';
import devops from '@/assets/departments/devops.png';
import hr from '@/assets/departments/hr.png';
import java from '@/assets/departments/java.png';
import js from '@/assets/departments/js.png';
import marketing from '@/assets/departments/marketing.png';
import mobile from '@/assets/departments/mobile.png';
import net from '@/assets/departments/net.png';
import php from '@/assets/departments/php.png';
import pmo from '@/assets/departments/pmo.png';
import python from '@/assets/departments/python.png';
import qa from '@/assets/departments/qa.png';
import sales from '@/assets/departments/sales.png';

export const DEPARTMENT_IMAGES = {
	js,
	java,
	net,
	python,
	qa,
	hr,
	marketing,
	pmo,
	sales,
	devops,
	php,
	mobile,
	ba,
};
