import { Stack, useTheme } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';

import { CustomWidthTooltip } from '@/components/Table/components/CustomWidthTooltip';
import { TypographyTooltip } from '@/components/Table/styles';

import { StyledCellText } from './styles';
import type { TableCellContentProps } from './types';

export function TableCellContent({
	data,
	withTooltip = false,
	cellWidth,
}: TableCellContentProps) {
	const theme = useTheme();
	const ref = useRef<HTMLSpanElement | null>(null);
	const [isTooltip, setIstooltip] = useState(false);

	useEffect(() => {
		if (ref.current) {
			setIstooltip(ref.current.clientWidth !== ref.current.scrollWidth);
		}
	}, [data]);

	if (data === null) {
		return null;
	}

	if (Array.isArray(data)) {
		return <>{data.join(', ')}</>;
	}

	if (typeof data === 'object' && 'hours' in data && 'minutes' in data) {
		return (
			<Stack
				fontSize={theme.typography.pxToRem(16)}
				m={0}
				color={theme.palette.timeCell.secondary}
				fontWeight={400}
				direction="row"
			>
				{data.hours > 0 && (
					<>
						<StyledCellText
							variant="m-400"
							color={(theme.palette as any)['base-6']}
						>
							{data.hours}
						</StyledCellText>
						<StyledCellText
							variant="m-400"
							color={(theme.palette as any)['base-6']}
						>
							h
						</StyledCellText>
					</>
				)}
				<StyledCellText
					variant="m-400"
					color={(theme.palette as any)['base-6']}
				>
					{data.minutes}
				</StyledCellText>
				<StyledCellText
					variant="m-400"
					color={(theme.palette as any)['base-6']}
				>
					min
				</StyledCellText>
			</Stack>
		);
	}

	if (withTooltip) {
		return (
			<CustomWidthTooltip
				title={isTooltip ? data : ''}
				currentWidth={cellWidth}
			>
				<TypographyTooltip
					ref={ref}
					Component="p"
					variant="m-400"
					cellWidth={cellWidth}
					color={(theme.palette as any)['base-6']}
				>
					{data as unknown as ReactNode}
				</TypographyTooltip>
			</CustomWidthTooltip>
		);
	}

	return data;
}
