import { extraFiltersBy } from './filters';

export enum Roles {
	CEO = 'CEO',
	CTO = 'CTO',
	HD = 'HeadOfDepartment',
	SE = 'Employee',
	SA = 'SystemAdministrator',
}

export const AllRoles = [Roles.CEO, Roles.CTO, Roles.SE, Roles.HD, Roles.SA];
export const chiefRoles = [Roles.CEO, Roles.CTO];
export const managerRoles = [...chiefRoles, Roles.HD];
export const chiefsAndSA = [...managerRoles, Roles.SA];
export const employees = [Roles.SA, Roles.SE];

export const isNotChiefRole = (role: Roles) => !chiefRoles.includes(role);
export const isManagerRole = (role: Roles) => managerRoles.includes(role);
export const isEmployee = (role: Roles) => employees.includes(role);
export const isHeadOfDepartment = (role: Roles) => role === Roles.HD;
export const isCEO = (role: Roles) => role === Roles.CEO;
export const isCTO = (role: Roles) => role === Roles.CTO;

export const RoleExtraOptions = {
	...extraFiltersBy,
	AllUsers: 'All Users',
};

export const ExtraRoles = [
	RoleExtraOptions.AllUsers,
	RoleExtraOptions.Personal,
];
