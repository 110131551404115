import moment, { Moment } from 'moment';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';

import { useToast } from '@/context/Toast';
import { useGetReservation } from '@/queries/booking';
import { Workplace } from '@/types/Booking';

export const useFilledDesksWithRangeDate = (
	firstDay: Moment,
	lastDay: Moment,
	setWorkplaces: Dispatch<SetStateAction<Workplace[]>>,
	error: string | null
): void => {
	const toast = useToast();
	const { data, isSuccess } = useGetReservation(
		moment(firstDay)
			.clone()
			.hours(0)
			.minutes(0)
			.seconds(0)
			.milliseconds(0)
			.toISOString(true),
		moment(lastDay)
			.clone()
			.hours(0)
			.minutes(0)
			.seconds(0)
			.milliseconds(0)
			.toISOString(true),
		toast,
		error
	);
	const [desks, setDesks] = useState<Workplace[]>([]);

	useEffect(() => {
		const updatedDesks: Workplace[] = [];
		data?.forEach(({ userWorkplaces }) => {
			userWorkplaces.forEach((workplace: Workplace) => {
				const deskIndex = updatedDesks.findIndex((item: Workplace) =>
					item ? item.workplaceNumber === workplace.workplaceNumber : false
				);

				if (deskIndex < 0) {
					updatedDesks.push(workplace);

					return;
				}

				if (workplace.isBlocked) {
					updatedDesks[deskIndex] = { ...workplace };
				}
			});
		});
		setDesks(updatedDesks);
	}, [data]);

	useEffect(() => {
		if (!firstDay.isSame(lastDay, 'day') && isSuccess) {
			setWorkplaces(desks);
		}
	}, [firstDay, lastDay, setWorkplaces, desks]);
};
