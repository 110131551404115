import axios, { AxiosError } from 'axios';
import { UseMutationOptions, useMutation } from 'react-query';

import {
	SOMETHING_WENT_WRONG,
	NotificationType,
	GET_NOTIFICATIONS,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';

const NOTIFICATION_MARKED = 'Your notification has been marked as read.';

export const useReadNotification = (
	options: UseMutationOptions<unknown, AxiosError, number>,
	toast: ToastContextValues
) =>
	useMutation<unknown, AxiosError, number>({
		...options,
		mutationFn: (notificationId) =>
			axios
				.post(`${GET_NOTIFICATIONS}/${notificationId}/read`)
				.then(() => {
					toast.open(
						createToast(NotificationType.SUCCESS, null, NOTIFICATION_MARKED)
					);
				})
				.catch((error) => {
					toast.open(
						createToast(
							NotificationType.ERROR,
							null,
							error.response?.data?.Message || SOMETHING_WENT_WRONG
						)
					);
				}),
	});
