import { SyntheticEvent, useCallback, useState } from 'react';

import type { ShowModal } from './types';

export const useShowModal = () => {
	const [{ isOpen, id }, setDialogState] = useState<ShowModal>({
		isOpen: false,
		id: undefined,
	});

	const handleOpen = useCallback((itemId?: number | string) => {
		setDialogState({
			isOpen: true,
			id: itemId ?? undefined,
		});
	}, []);

	const handleClose = useCallback(
		() =>
			setDialogState((snapshot) => ({
				...snapshot,
				isOpen: false,
			})),
		[]
	);

	const updateRecord = useCallback(
		(_event?: SyntheticEvent<Element, Event>, itemId?: number | string) => {
			setDialogState({
				isOpen: true,
				id: itemId as number,
			});
		},
		[]
	);

	return { isOpen, id, handleOpen, handleClose, updateRecord };
};
