import { MenuItem } from '@mms/mms-ui-library';
import { ReactElement, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ProtectedView } from '@/components/ProtectedView';
import { PATH_KEY } from '@/constants/authConstants';
import { Routes } from '@/constants/routes';
import { useAuth } from '@/context/AuthContext';

import { ItemPayload } from './types';

type PropsTypes = {
	label: string;
	icon: ReactElement | JSX.Element;
	isOpen: boolean;
	selected: boolean;
	onClick: () => void;
	payload: ItemPayload;
};

export function RouteGuard({
	icon,
	label,
	isOpen,
	selected,
	onClick,
	payload: { roles, slug },
}: PropsTypes) {
	const { onLogout } = useAuth();
	const navigate = useNavigate();

	const handleClick = useCallback(() => {
		if (slug === Routes.signOut) {
			onLogout();
		} else {
			localStorage.setItem(PATH_KEY, slug);
			navigate(slug);
		}
		onClick();
	}, [slug, navigate]);

	return (
		<ProtectedView key={slug} roles={roles}>
			<MenuItem
				isOpen={isOpen}
				icon={icon}
				selected={selected}
				label={label}
				onClick={handleClick}
			/>
		</ProtectedView>
	);
}
