import { SvgIconProps, createSvgIcon } from '@mui/material';

const Person = createSvgIcon(
	<>
		<circle
			cx="7.00036"
			cy="4.89782"
			r="3.20641"
			stroke="currentColor"
			fill="none"
			strokeWidth="2"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.18 13.3113C10.7101 11.4401 9.01684 10.0547 6.99997 10.0547C4.9831 10.0547 3.28981 11.4401 2.81993 13.3113H0.777832C1.27907 10.3278 3.87406 8.05469 6.99997 8.05469C10.1259 8.05469 12.7209 10.3278 13.2221 13.3113H11.18Z"
			fill="currentColor"
		/>
	</>,
	'Person'
);

export function PersonIcon({ sx, ...props }: SvgIconProps) {
	return (
		<Person
			sx={{
				width: 14,
				height: 14,
				...sx,
			}}
			viewBox="0 0 14 14"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			{...props}
		/>
	);
}
