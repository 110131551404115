import { CSSObject, styled } from '@mui/material/styles';

export const StyledLeftHexagon = styled('svg', {
	shouldForwardProp: (prop: string) => prop !== 'matches',
})<{ matches?: boolean }>(
	({ matches }): CSSObject => ({
		shapeRendering: 'geometricPrecision',
		textRendering: 'geometricPrecision',
		imageRendering: 'auto',
		fillRule: 'evenodd',
		clipRule: 'evenodd',
		position: 'absolute',
		width: 721,
		height: 837,
		left: -188,
		top: -155,
		display: matches ? 'none' : 'inline',
	})
);

export const StyledRightHexagon = styled(StyledLeftHexagon)(() => ({
	width: 632,
	height: 566,
	right: -156,
	bottom: -178,
	left: 'unset',
	top: 'unset',
}));
