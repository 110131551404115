import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material';

import { DatePicker } from '@/fields/DatePicker';

export const StyledLabel = styled(Typography)(({ theme }) => ({
	color: (theme.palette as any)['base-6'],
	textTransform: 'capitalize',
}));

export const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
	flex: 1,
	'.MuiInputAdornment-root': {
		marginLeft: 0,
		button: {
			padding: 0,
			marginRight: 0,
		},
	},
	'.MuiInputBase-sizeSmall': {
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 400,
		lineHeight: theme.typography.pxToRem(24),
		paddingRight: 1,
		color: (theme.palette as any)['base-6'],
		svg: {
			color: theme.palette.secondary.main,
			transform: 'scale(0.6)',
		},
	},
	fieldset: {
		borderColor: theme.palette.primary.main,
	},
}));
