/* eslint-disable consistent-return */
// TODO: functions which have complex logic with date should'n be in @/helpers
import moment, { Moment } from 'moment';

import {
	months,
	quarters,
	date,
	COMPANY_FOUNDATION_DATE,
	TIME_PERIOD,
} from '@/constants/date';

export const generateYearsCompanyExistence = () => {
	const arr = [];

	for (let i = date.getUTCFullYear(); i >= COMPANY_FOUNDATION_DATE; i -= 1) {
		arr.push(i);
	}

	return arr;
};

const currentQuarter = () => {
	const currentDate = new Date();
	const currentMonth = months[currentDate.getMonth()];

	switch (currentMonth) {
		case 'January':
		case 'February':
		case 'March':
			return 'January-March';
		case 'April':
		case 'May':
		case 'June':
			return 'April-June';
		case 'July':
		case 'August':
		case 'September':
			return 'July-September';
		case 'October':
		case 'November':
		case 'December':
			return 'October-December';
		default:
			break;
	}
};

export function changeDate(title: string, direction: string, state: any) {
	const currentYear = date.getUTCFullYear();
	const currentMonth = date.getMonth();

	if (direction === 'forward') {
		switch (title) {
			case TIME_PERIOD.Year:
				if (state.year === currentYear) {
					return state;
				}

				return {
					year: state.year + 1,
				};
			case TIME_PERIOD.Month:
				if (
					state.month === months[currentMonth] &&
					state.year === currentYear
				) {
					return state;
				}

				if (state.month === 'December') {
					if (state.year !== currentYear) {
						return {
							year: state.year + 1,
							month: months[0],
						};
					}

					return state;
				}

				return {
					year: state.year,
					month: months[months.lastIndexOf(state.month) + 1],
				};
			case TIME_PERIOD.Quarter:
				if (
					quarters.findIndex((item) => item === state.quarter) >=
						quarters.findIndex((item) => item === currentQuarter()) &&
					state.year === currentYear
				) {
					return state;
				}

				if (state.quarter === 'October-December') {
					if (state.year === currentYear) {
						return state;
					}

					return {
						year: state.year + 1,
						quarter: quarters[0],
					};
				}

				return {
					year: state.year,
					quarter: quarters[quarters.lastIndexOf(state.quarter) + 1],
				};
			default:
				break;
		}
	} else if (direction === 'backward') {
		switch (title) {
			case TIME_PERIOD.Year:
				if (state.year === 2020) {
					return state;
				}

				return {
					year: state.year - 1,
				};
			case TIME_PERIOD.Month:
				if (state.month === 'January') {
					if (state.year === 2020) {
						return state;
					}

					return {
						year: state.year - 1,
						month: months[months.length - 1],
					};
				}

				return {
					year: state.year,
					month: months[months.lastIndexOf(state.month) - 1],
				};
			case TIME_PERIOD.Quarter:
				if (state.quarter === 'January-March') {
					if (state.year === 2020) {
						return state;
					}

					return {
						year: state.year - 1,
						quarter: quarters[quarters.length - 1],
					};
				}

				return {
					year: state.year,
					quarter: quarters[quarters.lastIndexOf(state.quarter) - 1],
				};
			default:
				break;
		}
	}
}

export const generateQuarter = (quarter: string) => {
	switch (quarter) {
		case 'January-March':
			return ['January', 'February', 'March'];
		case 'April-June':
			return ['April', 'May', 'June'];
		case 'July-September':
			return ['July', 'August', 'September'];
		case 'October-December':
			return ['October', 'November', 'December'];
		default:
			break;
	}
};

export const generateMonths = (array: string[]) =>
	array.map((item: string) => months.lastIndexOf(item) + 1);

export const parseMomentDateToUTCString = (dateMoment?: Moment | null) =>
	dateMoment
		? `${moment.utc(dateMoment).local().format().slice(0, -6)}Z`
		: null;
