import { styled } from '@mui/material';

import { SquareProps } from './types';

const SQUARE_SIDE = 21;

export const Square = styled('div')<SquareProps>(({ theme, type, color }) => ({
	width: SQUARE_SIDE,
	height: SQUARE_SIDE,
	borderRadius: 2,
	backgroundColor:
		color ||
		theme.palette.bookingWorkplace[
			type as keyof typeof theme.palette.bookingWorkplace
		],
	cursor: 'pointer',
}));
