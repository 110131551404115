import { Stack, styled } from '@mui/material';

export const Wrapper = styled(Stack)({
	gap: 20,
	width: 350,
});

export const DatePickerWrapper = styled(Stack)({
	gap: 12,
	flexDirection: 'row',
	justifyContent: 'space-between',
});
