import { Scrollbar } from '@mms/mms-ui-library';
import { Box } from '@mui/material';

import type { CustomTabPanelProps } from './types';

export function CustomTabPanel({
	children,
	value,
	index,
	...other
}: CustomTabPanelProps) {
	return (
		<Scrollbar
			maxHeight="calc(100vh - 250px)"
			maxWidth="100%"
			scroll="y"
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</Scrollbar>
	);
}
