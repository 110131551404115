import { SvgIconProps, createSvgIcon } from '@mui/material';

const PlusCircle = createSvgIcon(
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="7" cy="7" r="6.25" stroke="currentColor" strokeWidth="1.5" />
		<path
			d="M7 4.30078V9.70051M9.69986 7.00064H4.30014"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</svg>,
	'PlusCircle'
);

export function PlusCircleIcon({ sx, ...props }: SvgIconProps) {
	return (
		<PlusCircle
			sx={{
				width: 14,
				height: 14,
				...sx,
			}}
			viewBox="0 0 14 14"
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			{...props}
		/>
	);
}
