import { KnowledgeType } from '@/types/KnowledgeBase';

export const swapNameAndDescription = (knowledge: KnowledgeType) => ({
	...knowledge,
	name: knowledge.description,
	description: knowledge.name,
});

export const swapKnowledgees = (knowledgees: KnowledgeType[]) =>
	knowledgees.map(swapNameAndDescription);
