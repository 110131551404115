import { RefObject, useEffect } from 'react';

export function useOutsideClick(
	ref: RefObject<HTMLElement> | undefined,
	handler: () => void,
	ignoredClassName: string
) {
	useEffect(() => {
		const handleOutsideClick = (e: MouseEvent) => {
			const path = e.composedPath();

			if ((e.target as HTMLElement).closest(ignoredClassName)) {
				return;
			}

			if (ref?.current && !path.includes(ref.current)) {
				handler();
			}
		};

		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, [ref, handler]);
}
