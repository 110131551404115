import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { LocationStates, locationStatusKey } from '@/constants/locationStates';
import { Routes } from '@/constants/routes';
import { useAuth } from '@/context/AuthContext';

export const useNavigateAfterLogin = () => {
	const { location } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const shouldRedirectToProfile = !location;

		if (shouldRedirectToProfile) {
			sessionStorage.setItem(locationStatusKey, LocationStates.PartiallyLogged);
			navigate(Routes.setProfile);
		}
	}, []);
};
