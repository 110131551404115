import { useAuth } from '@/context/AuthContext';

import type { ProtectedViewProps } from './types';

export function ProtectedView({ roles, children }: ProtectedViewProps) {
	const { role } = useAuth();

	if (role) {
		return roles.includes(role) ? children : null;
	}

	return null;
}
