import { SvgIconProps, createSvgIcon, useTheme } from '@mui/material';

const Arrow = createSvgIcon(
	<path d="M1 6L5 2L9 6" strokeWidth="1.5" strokeLinecap="round" />,
	'Arrow'
);

export function ArrowIcon({ sx, ...props }: SvgIconProps) {
	const theme = useTheme();

	return (
		<Arrow
			sx={{ width: 10, height: 9, fill: 'none', ...sx }}
			viewBox="0 0 10 9"
			xmlns="http://www.w3.org/2000/svg"
			stroke={theme.palette.field.arrow}
			{...props}
		/>
	);
}
