import { useMemo } from 'react';

import { useToast } from '@/context/Toast';
import { normalizeUser } from '@/helpers/normalizeUser';
import { useAllUsersLookup } from '@/queries/user/useAllUsersLookup';

export const useAllUserFilter = () => {
	const toasts = useToast();

	const { data: users, isFetching } = useAllUsersLookup(toasts);

	return useMemo(normalizeUser(users, isFetching), [isFetching]);
};
