import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';

import { DAY_AFTER_THREE_MONTHS } from '@/constants/index';

export function InnerDay({ day, ...other }: PickersDayProps<Moment>) {
	const isDisabled = moment(day).isAfter(DAY_AFTER_THREE_MONTHS, 'day');

	return (
		<PickersDay
			className={isDisabled ? 'Mui-disabled' : ''}
			day={day}
			{...other}
		/>
	);
}
