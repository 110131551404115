import { Stack, Theme, styled } from '@mui/material';

export const PageWrapper = styled(Stack)(({ theme }) => ({
	gap: theme.spacing(2),
	paddingTop: 0,
	height: '100%',
}));

export const projectAutocomleteStyles = (theme: Theme) => ({
	width: 200,
	position: 'relative',
	'& .MuiInput-root::before': {
		borderBottom: 'none',
		content: 'none',
	},
	'& .MuiInput-root::after': {
		borderBottom: 'none',
	},
	'& .MuiInput-input': {
		cursor: 'pointer',
		caretColor: 'transparent',
	},
	'& .MuiInput-root': {
		margin: 0,
	},
	'& .MuiFormControl-root': {
		position: 'static',
	},
	'& .MuiAutocomplete-root': {
		position: 'relative',
	},
	'& .MuiFormLabel-root': {
		paddingRight: '10px',
		transform: 'none',
		top: 4,
		color: theme.palette.timeCell.main,
	},
});
