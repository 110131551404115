import { SvgIconProps, createSvgIcon } from '@mui/material';

const Edit = createSvgIcon(
	<>
		<g clipPath="url(#clip0_3019_1926)">
			<path
				d="M7.46189 2.02023L9.90296 4.46128L3.72394 10.6403L1.28425 8.19925L7.46189 2.02023ZM11.7553 1.4315L10.6666 0.34288C10.2459 -0.0778351 9.56277 -0.0778351 9.14063 0.34288L8.09783 1.38567L10.5389 3.82674L11.7553 2.61038C12.0816 2.28405 12.0816 1.75781 11.7553 1.4315ZM0.00679292 11.6303C-0.0376314 11.8303 0.142879 12.0094 0.342834 11.9608L3.063 11.3013L0.623311 8.86022L0.00679292 11.6303Z"
				fill="currentColor"
			/>
		</g>
		<defs>
			<clipPath id="clip0_3019_1926">
				<rect width="12" height="12" />
			</clipPath>
		</defs>
	</>,
	'Edit'
);

export function EditIcon({ sx, ...props }: SvgIconProps) {
	return (
		<Edit
			sx={{
				width: 12,
				height: 12,
				...sx,
			}}
			viewBox="0 0 12 12"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
