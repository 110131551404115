import { Autoselect, ThemeMode } from '@mms/mms-ui-library';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { MenuItem, useTheme } from '@mui/material';
import { memo, useState } from 'react';

import {
	AutoselectWrapper,
	StyledSelect,
} from '@/components/Equipment/EquipmentControls/styles';
import { getAvailabilityOptions } from '@/components/Equipment/EquipmentControls/utils';
import { PurchaseFilter } from '@/components/PurchaseFilter';
import { FilterKeys } from '@/constants/filters';
import {
	DEFAULT_PURCHASE_DATE_FILTER_VALUE,
	initialAvailabilityFilterValue,
} from '@/constants/index';
import { useKnowledgeBase } from '@/context/KnowledgeBase';

import {
	FiltersWrapper,
	StyledBox,
	StyledClearButton,
	StyledIconButton,
} from './styles';
import type { FiltersProps } from './types';

function FiltersInner({
	filtersValues,
	filtersOptions,
	autocompleteSelectFields,
	isMobile,
	clearAvailabilityFilter,
	resetAllFilters,
	removeFilter,
	handleAvailabilityFilter,
	handleFilterChanges,
}: FiltersProps) {
	const { knowledgeBase } = useKnowledgeBase();
	const theme = useTheme();

	const [purchaseFilterValue, setPurchaseFilterValue] = useState(
		DEFAULT_PURCHASE_DATE_FILTER_VALUE
	);

	const handleResetAllFields = () => {
		resetAllFilters();
		setPurchaseFilterValue(DEFAULT_PURCHASE_DATE_FILTER_VALUE);
	};

	return (
		<StyledBox>
			<FiltersWrapper isMobile={isMobile}>
				<StyledSelect
					value={
						filtersValues?.availability
							? filtersValues.availability
							: initialAvailabilityFilterValue
					}
					select
					label="Availability"
					InputProps={{
						endAdornment: (
							<StyledIconButton
								className="clear-button"
								size="small"
								onClick={clearAvailabilityFilter}
								title="Clear"
							>
								<ClearIcon fontSize="small" />
							</StyledIconButton>
						),
					}}
					onChange={handleAvailabilityFilter}
					size="small"
					SelectProps={{
						IconComponent: ExpandMoreIcon,
						MenuProps: { disablePortal: true },
					}}
				>
					{getAvailabilityOptions(
						knowledgeBase?.EquipmentAvailabilityType,
						filtersOptions?.hasAvailable
					).map(({ id, name }) => (
						<MenuItem id={id as string} key={id} value={name}>
							{name}
						</MenuItem>
					))}
				</StyledSelect>
				{autocompleteSelectFields.map(({ label, setValue, ...rest }) => (
					<AutoselectWrapper key={label}>
						<Autoselect
							label={label}
							valueField="name"
							onChange={setValue as any}
							{...rest}
							labelAndErrorBgColor={
								theme.palette.mode === ThemeMode.LIGHT
									? theme.palette['primary-2']
									: theme.palette['primary-11']
							}
						/>
					</AutoselectWrapper>
				))}
				<PurchaseFilter
					filterValue={purchaseFilterValue}
					setFilterValue={setPurchaseFilterValue}
					firstDay={filtersValues?.firstDay ? filtersValues.firstDay : null}
					lastDay={filtersValues?.lastDay ? filtersValues.lastDay : null}
					setFirstDay={handleFilterChanges(FilterKeys.FirstDay)}
					setLastDay={handleFilterChanges(FilterKeys.LastDay)}
					removeFilter={removeFilter}
				/>
				{Object.keys(filtersValues).length !== 0 && (
					<StyledClearButton type="button" onClick={handleResetAllFields}>
						Clear all
					</StyledClearButton>
				)}
			</FiltersWrapper>
		</StyledBox>
	);
}

export const Filters = memo(FiltersInner);
