import { Scrollbar } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

import { closedDrawerWidth } from './config';
import { MainContentProps } from './types';

export const AppWrapper = styled('div')(({ theme }) => ({
	height: '100%',
	paddingTop: theme.spaces.s,
	background: theme.palette['exc-mainPage-primary-1'],
}));

export const BurgerIconWrapper = styled('div')`
	display: flex;
	align-items: center;
	cursor: pointer;
`;

export const MainContent = styled('main')<MainContentProps>(
	({ theme, lowPriority, highPriority }) => ({
		marginLeft: lowPriority || highPriority ? closedDrawerWidth : 0,
		flexGrow: 1,
		padding: lowPriority || highPriority ? theme?.spacing(3) : 0,
		paddingTop: 70,
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			marginLeft: 0,
			padding: theme?.spacing(0.5),
		},
	})
);

export const StyledScroll = styled(Scrollbar)({
	height: '100%',
	width: '100%',
});

export const LoaderWrapper = styled('div')({
	position: 'absolute',
	width: '100%',
	top: 54,
	left: 0,
	zIndex: 1500,
});
