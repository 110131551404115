import { DateCalendarProps } from '@mui/x-date-pickers';
import { isEqual } from 'lodash';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState, memo } from 'react';
import ReactDOMServer from 'react-dom/server';

import { CalendarIcon } from '@/assets/calendar/CalendarIcon';
import {
	ARROW_COLORS,
	FIXED_WEEK_NUMBER,
	currentDate,
} from '@/constants/index';

import { StyledCalendar } from './styled';

moment.updateLocale('en', {
	week: {
		dow: 1,
	},
});

function Calendar(props: DateCalendarProps<Moment>) {
	const [muiIconContent, setMuiIconContent] = useState<string | undefined>();

	useEffect(() => {
		const iconSvg = ReactDOMServer.renderToStaticMarkup(<CalendarIcon />);
		const svgIconWithXlmns = iconSvg.replace(
			'<svg ',
			`<svg xmlns="http://www.w3.org/2000/svg" `
		);
		const resultUrl = `url('data:image/svg+xml,${svgIconWithXlmns}')`;
		setMuiIconContent(resultUrl);
	}, []);

	const dayOfWeek = useMemo(() => (day: string) => day.slice(0, 3), []);

	return (
		<StyledCalendar
			muiIconContent={muiIconContent}
			defaultValue={currentDate}
			slotProps={{
				previousIconButton: ARROW_COLORS,
				nextIconButton: ARROW_COLORS,
			}}
			views={['day']}
			showDaysOutsideCurrentMonth
			fixedWeekNumber={FIXED_WEEK_NUMBER}
			dayOfWeekFormatter={dayOfWeek}
			{...props}
		/>
	);
}

export const CalendarUI = memo(Calendar, isEqual);
