import axios from 'axios';
import { useQuery } from 'react-query';

import {
	GET_NOTIFICATIONS,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
	NotificationType,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';

import type { GetNotificationsResponse } from './types';

export const useGetNotifications = (toast: ToastContextValues) =>
	useQuery({
		queryKey: BookingQueriesKeys.notifications,
		queryFn: async () => {
			try {
				const { data } = await axios.get<GetNotificationsResponse>(
					GET_NOTIFICATIONS
				);

				return data;
			} catch (error) {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return undefined;
			}
		},
		refetchOnWindowFocus: false,
	});
