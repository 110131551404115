import { lazy } from 'react';
import { Routes as BrowserRoutes, Navigate, Route } from 'react-router-dom';

import { PrivateRoute } from '@/components/PrivateRoute';
import { ProtectedRoute } from '@/components/ProtectedRoute';
import {
	chiefsAndSA,
	isEmployee,
	managerRoles,
	Roles,
	Routes,
} from '@/constants/index';
import { useAuth } from '@/context/index';
import { Layout } from '@/layouts/main';
import { EquipmentPage } from '@/pages/Equipment';
import { SignInPage } from '@/pages/SignIn';

const NotFoundPage = lazy(async () => ({
	default: (await import('@/pages/NotFound')).NotFoundPage,
}));
const UsersPage = lazy(async () => ({
	default: (await import('@/pages/Users')).UsersPage,
}));
const ProjectsPage = lazy(async () => ({
	default: (await import('@/pages/Projects')).ProjectsPage,
}));
const ClientPage = lazy(async () => ({
	default: (await import('@/pages/Clients')).ClientPage,
}));
const TimeSheetPage = lazy(async () => ({
	default: (await import('@/pages/TimeSheet')).TimeSheetPage,
}));
const ReportsPage = lazy(async () => ({
	default: (await import('@/pages/Reports')).ReportsPage,
}));
const DashboardPage = lazy(async () => ({
	default: (await import('@/pages/Dashboard')).DashboardPage,
}));
const ProfilePage = lazy(async () => ({
	default: (await import('@/pages/Profile')).ProfilePage,
}));
const DepartmentsPage = lazy(async () => ({
	default: (await import('@/pages/Departments')).DepartmentsPage,
}));
const BookingPage = lazy(async () => ({
	default: (await import('@/pages/Booking')).BookingPage,
}));
const AuthCallbackPage = lazy(async () => ({
	default: (await import('@/pages/AuthCallback')).AuthCallbackPage,
}));

export function AppRouter() {
	const { role } = useAuth();

	return (
		<BrowserRoutes>
			<Route path={Routes.signIn} element={<SignInPage />} />
			<Route path={Routes.authCallback} element={<AuthCallbackPage />} />
			<Route
				element={
					<ProtectedRoute>
						<Layout />
					</ProtectedRoute>
				}
			>
				<Route
					index
					element={
						<Navigate
							to={isEmployee(role) ? Routes.timeSheet : Routes.dashboard}
						/>
					}
				/>
				<Route path={Routes.dashboard} element={<DashboardPage />} />
				<Route path={Routes.reports} element={<ReportsPage />} />
				<Route path={Routes.timeSheet} element={<TimeSheetPage />} />
				<Route path={Routes.booking} element={<BookingPage />} />
				<Route element={<PrivateRoute available={chiefsAndSA} />}>
					<Route path={Routes.equipment} element={<EquipmentPage />} />
				</Route>
				<Route element={<PrivateRoute available={managerRoles} />}>
					<Route path={Routes.clients} element={<ClientPage />} />
					<Route path={Routes.projects} element={<ProjectsPage />} />
					<Route path={Routes.users} element={<UsersPage />} />
					<Route path={Routes.departments} element={<DepartmentsPage />} />
				</Route>
				<Route path={Routes.updateProfile} element={<ProfilePage />} />
			</Route>
			<Route path={Routes.setProfile} element={<ProfilePage />} />
			<Route path={Routes.all} element={<NotFoundPage />} />
		</BrowserRoutes>
	);
}
