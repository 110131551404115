import { Typography } from '@mms/mms-ui-library';
import { styled, Grid } from '@mui/material';

export const Title = styled(Typography)(({ theme }) => ({
	lineHeight: theme.typography.pxToRem(16),
	color: theme.palette.primary.main,
	marginTop: theme.spacing(0.25),
}));

export const CustomGridItem = styled(Grid)(({ theme }) => ({
	'&.MuiGrid-item': {
		paddingTop: theme.spacing(2.5),
	},
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
	color: theme.palette.buttons.color,
	marginTop: theme.spacing(0.625),
	width: 105,
	overflowX: 'hidden',
}));
