import { createContext, useMemo, useContext, PropsWithChildren } from 'react';

import { useGetKnowledgeBase } from '@/queries/useGetKnowledgeBase';

import { useAuth } from './AuthContext';
import type { KnowledgeBaseContextValues } from './types';

const KnowledgeBaseContext = createContext({} as KnowledgeBaseContextValues);

export function KnowledgeBaseProvider({ children }: PropsWithChildren) {
	const { token } = useAuth();

	const getKnowledgeBase = useGetKnowledgeBase({
		enabled: Boolean(token),
		refetchOnWindowFocus: false,
	});

	const contextValue = useMemo(
		() =>
			({
				knowledgeBase: {
					...getKnowledgeBase?.data,
					loading: getKnowledgeBase?.isFetching,
				},
			} as KnowledgeBaseContextValues),
		[getKnowledgeBase.dataUpdatedAt, getKnowledgeBase.isFetching]
	);

	return (
		<KnowledgeBaseContext.Provider value={contextValue}>
			{children}
		</KnowledgeBaseContext.Provider>
	);
}

export const useKnowledgeBase = () => useContext(KnowledgeBaseContext);
