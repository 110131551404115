import { Button } from '@mms/mms-ui-library';

import { Heading } from '@/components/Heading';
import { Hexagons } from '@/components/Hexagons';
import { Preloader } from '@/components/Preloader';
import { PATH_KEY } from '@/constants/authConstants';
import {
	LOCATION,
	API_BASE_URL,
	MICROSOFT_LOGIN_REDIRECT,
} from '@/constants/index';
import { useAuth } from '@/context/AuthContext';

import { SignInWrapper, SignInStack, Wrapper } from './styles';

export function SignInPage() {
	const { loading } = useAuth();

	const login = () => {
		const origin = window.location.origin as LOCATION;
		localStorage.removeItem(PATH_KEY);
		window.location.href = `${API_BASE_URL[origin]}${MICROSOFT_LOGIN_REDIRECT}?returnUrl=${origin}/auth-callback`;
	};

	return (
		<SignInWrapper>
			{loading && <Preloader isBackgroundShowed={false} />}
			<Hexagons />
			<Wrapper>
				<Heading />
				<SignInStack>
					<Button onClick={login} type="custom" size="large">
						Login via Microsoft
					</Button>
				</SignInStack>
			</Wrapper>
		</SignInWrapper>
	);
}
