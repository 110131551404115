import {
	createContext,
	PropsWithChildren,
	useContext,
	useMemo,
	useState,
} from 'react';

import { validationSchema } from '@/forms/EquipmentForm/MainForm/schema';
import { checkFieldDates } from '@/helpers/checkFieldDates';
import type { EquipmentItemRequest } from '@/types/Equipment';

import type { EquipmentContextValues } from './types';

const EquipmentContext = createContext({} as EquipmentContextValues);

function EquipmentProvider({ children }: PropsWithChildren) {
	const [equipmentModel, setEquipmentModel] = useState<EquipmentItemRequest>(
		Object.keys(validationSchema.fields).reduce((acc: any, value: string) => {
			acc[value] = checkFieldDates(value) ? null : '';

			if (value === 'purchasePriceUsdCurrency') {
				acc[value] = 'USD';
			}

			if (value === 'purchaseCurrency') {
				acc[value] = 'BYN';
			}

			return acc;
		}, {})
	);

	const value = useMemo(
		() => ({ equipmentModel, setEquipmentModel }),
		[equipmentModel, setEquipmentModel]
	);

	return (
		<EquipmentContext.Provider value={value}>
			{children}
		</EquipmentContext.Provider>
	);
}

const useEquipmentContext = () => useContext(EquipmentContext);

export { EquipmentContext, EquipmentProvider, useEquipmentContext };
