import { styled } from '@mui/material/styles';

export const Image = styled('img')(({ theme }) => ({
	minWidth: 310,
	width: 540,
	height: 120,

	[theme.breakpoints.down('sm')]: {
		width: '90%',
		height: 'auto',
	},
}));

export const Title = styled('h1')(({ theme }) => ({
	textTransform: 'uppercase',
	margin: theme.spacing(2.5, 'auto'),
	color: theme.palette['primary-8'],
	fontWeight: 800,
	fontSize: theme.typography.pxToRem(56),
	lineHeight: theme.typography.pxToRem(68),
	minWidth: 310,

	[theme.breakpoints.down('sm')]: {
		fontSize: theme.typography.pxToRem(40),
		lineHeight: theme.typography.pxToRem(40),
		textAlign: 'center',
	},
}));
