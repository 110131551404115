import { Input } from '@mms/mms-ui-library';
import { Field, useFormikContext } from 'formik';
import { TextFieldProps } from 'formik-mui';
import { useCallback, FocusEvent, ChangeEvent } from 'react';
import { JSX } from 'react/jsx-runtime';

import type { MMSTextFieldProps } from './types';

export function TextField({
	submitOnBlur,
	label,
	id,
	taskName,
	name,
	onChange,
	onChangeAdditionalCallback,
	size = 'fullWidth',
	...otherProps
}: MMSTextFieldProps) {
	const { setFieldTouched } = useFormikContext();

	const handleBlur = useCallback(
		(event: FocusEvent<HTMLInputElement>) => {
			if (submitOnBlur && id) {
				submitOnBlur(id, event.target.value, taskName as string);
			}
		},
		[taskName]
	);

	const handleOnChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		onChange(name, e.target.value);
		setFieldTouched(name, true);

		if (onChangeAdditionalCallback) {
			onChangeAdditionalCallback();
		}
	}, []);

	return (
		<Field name={name}>
			{(props: JSX.IntrinsicAttributes & TextFieldProps) => {
				const {
					meta: { value, error, touched },
				} = props;

				const errorMessage = (touched || value) && error;
				const onBlur = errorMessage ? undefined : submitOnBlur && handleBlur;

				return (
					<Input
						id={name}
						name={name}
						onChange={handleOnChange}
						label={label ?? ''}
						size={size}
						onBlur={onBlur}
						value={value || ''}
						error={errorMessage}
						errorText={error}
						{...otherProps}
					/>
				);
			}}
		</Field>
	);
}
