export const checkIfRowBlocked = (
	blockedItems: Array<string | number>,
	itemId: string | number
) => {
	if (blockedItems && Array.isArray(blockedItems)) {
		if (blockedItems.includes(itemId)) {
			return true;
		}
	}

	return false;
};
