import { Stack } from '@mui/material';
import { memo, useContext, useMemo } from 'react';

import {
	currentDate,
	DAY_AFTER_THREE_MONTHS,
	ENTER_VALID_DATE,
	SELECT_DATE_INPUT,
} from '@/constants/index';
import { BookingContext } from '@/context/Booking';
import { InnerDay } from '@/pages/Booking/components';

import { StyledDatePicker, StyledLabel } from './styles';
import { InputInnerProps } from './types';

function InputInner({
	label,
	disablePast,
	disableFuture,
	isMinDate,
	minDate,
	maxDate,
	...other
}: InputInnerProps) {
	const { setSelected, error } = useContext(BookingContext);

	const errorMessage = useMemo(() => {
		switch (error) {
			case 'pastDate':
			case 'maxDate':
			case 'minDate': {
				return ENTER_VALID_DATE;
			}

			case 'invalidDate': {
				return SELECT_DATE_INPUT;
			}

			default: {
				return null;
			}
		}
	}, [error, setSelected]);

	return (
		<Stack>
			<StyledLabel variant="s-600">{label}</StyledLabel>
			<StyledDatePicker
				textFieldProps={{
					helperText: errorMessage,
					error: Boolean(errorMessage),
				}}
				slotsProps={{
					day: InnerDay,
				}}
				layoutProps={{
					disablePast: disablePast !== undefined ? disablePast : true,
					disableFuture: disableFuture !== undefined ? disableFuture : false,
					minDate: isMinDate !== false ? minDate || currentDate : undefined,
					maxDate: maxDate || DAY_AFTER_THREE_MONTHS,
				}}
				{...other}
			/>
		</Stack>
	);
}

export const Input = memo(InputInner);
