import { useMediaQuery, useTheme } from '@mui/material';

import { StyledLeftHexagon, StyledRightHexagon } from './styles';

export function Hexagons() {
	const theme = useTheme();
	const hexagonColor = theme.palette.secondary.main;
	const matches = useMediaQuery('(max-width:600px)');

	return (
		<>
			<StyledLeftHexagon
				xmlns="http://www.w3.org/2000/svg"
				fill={hexagonColor}
				matches={matches}
			>
				<path
					d="M184.158 599.98L260.042 672.37L235.293 774.283L134.66 803.805L58.7755 731.415L83.5249 629.503L184.158 599.98Z"
					stroke={hexagonColor}
					fill="none"
					strokeWidth="6"
				/>
				<path
					opacity="0.69"
					d="M244.97 339.619L220.217 441.53L296.099 513.922L396.734 484.403L421.486 382.491L345.604 310.099L244.97 339.619Z"
					stroke={hexagonColor}
					fill="none"
					strokeWidth="6"
				/>
				<path
					opacity="0.48"
					d="M481.972 164.947L560.36 239.73L664.319 209.236L689.889 103.958L611.501 29.1742L507.542 59.6688L481.972 164.947Z"
				/>
				<path d="M349.768 304.396L428.157 379.179L532.115 348.685L557.685 243.407L479.297 168.623L375.339 199.118L349.768 304.396Z" />
				<path d="M163.035 258.705L241.423 333.488L345.382 302.993L370.952 197.715L292.564 122.932L188.605 153.427L163.035 258.705Z" />
				<path
					opacity="0.48"
					d="M30.4946 397.474L108.883 472.257L212.841 441.763L238.411 336.485L160.023 261.702L56.0647 292.196L30.4946 397.474Z"
				/>
			</StyledLeftHexagon>
			<StyledRightHexagon
				xmlns="http://www.w3.org/2000/svg"
				fill={hexagonColor}
				matches={matches}
			>
				<path
					opacity="0.48"
					d="M233.781 81.6812L342.303 125.906L358.265 242.001L265.704 313.872L157.182 269.647L141.221 153.552L233.781 81.6812Z"
					stroke={hexagonColor}
					strokeWidth="6"
					fill="none"
				/>
				<path d="M264.589 322.18L281.022 441.707L185.725 515.702L73.9952 470.17L57.5619 350.643L152.859 276.648L264.589 322.18Z" />
				<path
					opacity="0.62"
					d="M476.683 293.695L493.116 413.222L397.819 487.218L286.089 441.686L269.656 322.159L364.952 248.163L476.683 293.695Z"
				/>
				<path d="M557.133 95.2968L573.566 214.824L478.269 288.819L366.539 243.287L350.106 123.76L445.403 49.765L557.133 95.2968Z" />
			</StyledRightHexagon>
		</>
	);
}
