import axios from 'axios';
import { useQuery } from 'react-query';

import {
	ClientQuerieKeys,
	NotificationType,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { GET_CLIENT_PROJECTS } from '@/constants/queryPaths';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import { ClientProjectsResponse } from '@/types/Clients';

export const useClientProjects = (
	clientId: string,
	isOpen: boolean,
	toast?: ToastContextValues
) =>
	useQuery({
		queryKey: [ClientQuerieKeys.clients, clientId],
		queryFn: () =>
			axios
				.get<ClientProjectsResponse[]>(GET_CLIENT_PROJECTS, {
					params: {
						clientId,
					},
				})
				.then((res) => res.data)
				.catch((error) =>
					toast?.open(
						createToast(
							NotificationType.ERROR,
							null,
							error.response.data.Message || SOMETHING_WENT_WRONG
						)
					)
				),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: isOpen,
	});
