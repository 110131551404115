import { createSvgIcon, SvgIconProps } from '@mui/material';

const Icon = createSvgIcon(
	<path
		d="M16 8L8 16M8 8L16 16"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
	/>,
	'Cross Icon'
);

export function CrossIcon({ sx, ...props }: SvgIconProps) {
	return (
		<Icon
			sx={{
				width: 24,
				height: 24,
				...sx,
			}}
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
