import { Tooltip, Typography } from '@mms/mms-ui-library';
import { useTheme, Stack, Divider, Box, Link } from '@mui/material';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LightMode } from '@/assets/userMenu/lightMode';
import { NightMode } from '@/assets/userMenu/nightMode';
import { ProfileIcon } from '@/assets/userMenu/Profile';
import { ServiceDeskIcon } from '@/assets/userMenu/ServiceDesk';
import { SignOutIcon } from '@/assets/userMenu/signOut';
import { PATH_KEY } from '@/constants/authConstants';
import { SERVICE_DESK_LINK } from '@/constants/links';
import { Routes } from '@/constants/routes';
import { useColorMode, useAuth } from '@/context/index';
import { getFullName } from '@/helpers/utils';
import { ThemeMode } from '@/theme';

import {
	DropdownFooter,
	DropdownHeader,
	DropdownWrapper,
	Email,
	HeaderWrapper,
	AvatarWrapper,
	Surname,
	Position,
	CustomMenuItem,
	CustomAvatar,
	TimeZoneTypography,
	ContentWrapper,
} from './styles';
import type { DropdownProps } from './types';
import { localTime, timezoneName } from './utils';

function UserMenuDropdownInner({ onClose }: DropdownProps) {
	const {
		lastName,
		firstName,
		avatarColor,
		rightPosition,
		email,
		avatarFileId,
		onLogout,
	} = useAuth();

	const { toggleThemeMode } = useColorMode();
	const navigate = useNavigate();
	const theme = useTheme();

	const name = getFullName(firstName, lastName);

	const iconColor = theme.palette.icons.primary;

	const handleProfileEdit = () => {
		onClose();
		localStorage.setItem(PATH_KEY, Routes.updateProfile);
		navigate(Routes.updateProfile);
	};

	return (
		<DropdownWrapper>
			<HeaderWrapper>
				<DropdownHeader />
				<AvatarWrapper>
					<CustomAvatar
						firstName={firstName}
						lastName={lastName}
						fileId={avatarFileId}
						color={theme.palette.common.white}
						bgColor={avatarColor}
					/>
				</AvatarWrapper>
			</HeaderWrapper>
			<ContentWrapper>
				<Tooltip text={name} fallbackPlacements={['bottom-end', 'left']}>
					<Surname align="center" Component="h5" variant="medium">
						{name}
					</Surname>
				</Tooltip>
				<Position align="center" Component="h6" variant="m-500">
					{rightPosition}
				</Position>
				<Tooltip text={email} position="bottom-end">
					<Email Component="p" variant="m-300">
						{email}
					</Email>
				</Tooltip>
				<TimeZoneTypography align="center" Component="h6" variant="xs-400">
					<Stack flexDirection="row" justifyContent="center">
						Time zone: <Box sx={{ ml: 1.5 }}>{localTime}</Box>
					</Stack>
					<Box sx={{ mt: 0.7 }}>{timezoneName}</Box>
				</TimeZoneTypography>
				<DropdownFooter>
					<Box onClick={handleProfileEdit}>
						<CustomMenuItem>
							<Typography variant="m-400" color={theme.palette['base-6']}>
								Profile
							</Typography>
							<ProfileIcon color={iconColor} />
						</CustomMenuItem>
					</Box>
					<Divider color={theme.palette.userMenuHeader.borderItems} />
					<Box>
						<CustomMenuItem onClick={toggleThemeMode}>
							{theme.palette.mode === ThemeMode.DARK ? (
								<>
									<Typography variant="m-400" color={theme.palette['base-6']}>
										Light theme
									</Typography>
									<LightMode color={iconColor} />
								</>
							) : (
								<>
									<Typography variant="m-400" color={theme.palette['base-6']}>
										Dark theme
									</Typography>
									<NightMode color={iconColor} />
								</>
							)}
						</CustomMenuItem>
					</Box>
					<Divider color={theme.palette.userMenuHeader.borderItems} />
					<Link
						href={SERVICE_DESK_LINK}
						color="inherit"
						underline="none"
						rel="noopener noreferrer"
						target="_blank"
					>
						<CustomMenuItem>
							<Typography variant="m-400" color={theme.palette['base-6']}>
								Service desk
							</Typography>{' '}
							<ServiceDeskIcon color={iconColor} />
						</CustomMenuItem>
					</Link>
					<Divider color={theme.palette.userMenuHeader.borderItems} />
					<Box>
						<CustomMenuItem onClick={onLogout}>
							<Typography variant="m-400" color={theme.palette['base-6']}>
								Log out
							</Typography>
							<SignOutIcon color={iconColor} />
						</CustomMenuItem>
					</Box>
				</DropdownFooter>
			</ContentWrapper>
		</DropdownWrapper>
	);
}

export const UserMenuDropdown = memo(UserMenuDropdownInner);
