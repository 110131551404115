import { SvgIconProps, createSvgIcon, useTheme } from '@mui/material';

const Search = createSvgIcon(
	<path
		d="M16 15.5L22 20.5M18 10C18 14.6944 14.1944 18.5 9.5 18.5C4.80558 18.5 1 14.6944 1 10C1 5.30558 4.80558 1.5 9.5 1.5C14.1944 1.5 18 5.30558 18 10Z"
		strokeWidth="1.5"
	/>,
	'Search'
);

export function SearchIcon({ sx, ...props }: SvgIconProps) {
	const theme = useTheme();

	return (
		<Search
			sx={{ width: 16, height: 14, fill: 'none', ...sx }}
			stroke={theme.palette.table.headColor}
			viewBox="0 0 23 22"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
