import moment, { Moment } from 'moment';

import { currentDate } from '@/constants/date';

export function parseDateRangeToString(
	firstDay: Moment | null,
	lastDay: Moment | null
) {
	const lastDayToParse = lastDay || currentDate;
	const formatPattern = 'DD MMM, YYYY';

	if (!firstDay) {
		return '';
	}

	if (firstDay.format(formatPattern) === lastDayToParse.format(formatPattern)) {
		return `${moment(firstDay).format(formatPattern)}`;
	}

	if (moment(firstDay).year() === moment(lastDayToParse).year()) {
		return `${moment(firstDay).format('DD MMM')} - ${moment(
			lastDayToParse
		).format(formatPattern)}`;
	}

	return `${moment(firstDay).format(formatPattern)} - ${moment(
		lastDayToParse
	).format(formatPattern)}`;
}
