import {
	CLSReportCallback,
	FIDReportCallback,
	FCPReportCallback,
	LCPReportCallback,
	TTFBReportCallback,
} from 'web-vitals';

export const reportWebVitals = (
	onPerfEntry:
		| CLSReportCallback
		| FIDReportCallback
		| FCPReportCallback
		| LCPReportCallback
		| TTFBReportCallback
) => {
	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS(onPerfEntry as CLSReportCallback);
			getFID(onPerfEntry as FIDReportCallback);
			getFCP(onPerfEntry as FCPReportCallback);
			getLCP(onPerfEntry as LCPReportCallback);
			getTTFB(onPerfEntry as TTFBReportCallback);
		});
	}
};
