import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const LeftBlockWrapper = styled(Stack)(({ theme }) => ({
	padding: theme.spacing(2.25),
	position: 'sticky',
	top: 0,

	'.booking-calendar': {
		opacity: 0.85,
		'.MuiPickersArrowSwitcher-button': {
			display: 'none',
		},
	},

	[theme.breakpoints.down(1600)]: {
		transform: 'scale(0.8) translate(-10%, -10%)',
		width: '50%',
	},
	[theme.breakpoints.down('lg')]: {
		padding: 0,
	},
	[theme.breakpoints.down(720)]: {
		transform: 'scale(0.95) translate(0%, 0%)',
		width: '100%',
		alignItems: 'center',
	},
}));
