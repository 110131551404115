import { Typography } from '@mms/mms-ui-library';
import { alpha } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { Avatar } from '@/components/Avatar';
import { COLORS_ENUM } from '@/constants/index';

import type { ColorType, StyledDeskProps } from './types';

const cssHelper = (func: (param: ColorType) => object, param: ColorType) => ({
	...func(param),
	'&:hover': {
		cursor: 'unset',
	},
	'&:active': {},
});

const checkCamelCase = (prop: string) =>
	!['isBlocked', 'isBooked', 'isRotated', 'isBookedByUser'].includes(prop);

export const StyledDesk = styled(Paper, {
	shouldForwardProp: checkCamelCase,
})<StyledDeskProps>(
	({
		theme,
		isBlocked,
		isBooked,
		isBookedByUser,
		isRotated,
		selected,
		number,
	}) => {
		const setColor = (color: string) => ({
			backgroundColor: color,
			h6: {
				color,
			},
		});

		const setColorToRotated = (color: string) => ({
			h6: {
				transform: 'rotate(180deg)',
				color,
			},
		});

		return {
			display: 'flex',
			gridArea: `desk-${number}`,
			flexDirection: 'row-reverse',
			alignItems: 'flex-start',
			justifyContent: 'flex-end',
			border: `2px solid ${theme.palette['primary-1']}`,
			borderRadius: 14,
			backgroundColor: theme.palette['base-2'],
			boxShadow: 'none',
			padding: theme.spacing(1),
			position: 'relative',
			transition: 'all .3s ease-in-out',
			'&:after': {
				content: '""',
				position: 'absolute',
				backgroundColor: theme.palette['base-2'],
				width: 12,
				height: 26,
				right: '-22px',
				top: '50%',
				transform: 'translate(-50%, -50%)',
				borderTopRightRadius: '100%',
				borderBottomRightRadius: '100%',
			},
			'&:hover': {
				cursor: 'pointer',
				borderColor: theme.palette['primary-1'],
			},
			'&:active': {
				backgroundColor: theme.palette['base-2'],
				borderColor: theme.palette['accent-6'],
			},
			...(isBlocked && {
				...cssHelper(setColor, '#0088D1'),
			}),
			...(isBooked && {
				...cssHelper(setColor, '#14AE5C'),
			}),
			...(isBookedByUser && {
				...cssHelper(setColor, theme.palette['base-2']),
			}),
			...(selected && {
				borderColor: theme.palette['accent-6'],
				'&:hover': {
					cursor: 'pointer',
					borderColor: theme.palette['accent-6'],
					backgroundColor: alpha(theme.palette.bookingWorkplace.selected, 0.5),
				},
				h6: {
					color: theme.palette.bookingWorkplace.title.selected,
				},
			}),
			...(isRotated && {
				transform: 'rotate(180deg)',
				alignItems: 'flex-end',
				justifyContent: 'flex-start',
				h6: {
					transform: 'rotate(180deg)',
				},
				...(isBooked && {
					...cssHelper(setColorToRotated, COLORS_ENUM.Booked),
				}),
				...(isBlocked && {
					...cssHelper(setColorToRotated, COLORS_ENUM.Blocked),
				}),
				...(isBookedByUser && {
					...cssHelper(setColorToRotated, COLORS_ENUM.Selected),
				}),
				...(selected && {
					...setColorToRotated(COLORS_ENUM.Selected),
					'&:hover': {
						cursor: 'pointer',
						borderColor: theme.palette.bookingWorkplace.selected,
						backgroundColor: alpha(
							theme.palette.bookingWorkplace.selected,
							0.5
						),
					},
				}),
			}),
			'&.desk-14, &.desk-15, &.desk-16, &.desk-17': {
				transform: 'translateY(-50%) rotate(180deg)',
			},
			'&.desk-18, &.desk-19, &.desk-20, &.desk-21': {
				transform: 'translateY(-50%)',
			},
			[theme.breakpoints.down(1400)]: {
				'&.desk-14, &.desk-15, &.desk-16, &.desk-17': {
					transform: 'rotate(180deg)',
				},
				'&.desk-18, &.desk-19, &.desk-20, &.desk-21': {
					transform: 'unset',
				},
			},
		};
	}
);

export const DeskTitle = styled(Typography)(({ theme }) => ({
	color: (theme.palette as any)['base-4'],
	userSelect: 'none',
}));

export const BookedAvatar = styled(Avatar)({
	justifyContent: 'flex-start',
	width: 'fit-content',
	height: 'fit-content',
	fontSize: '16px',
	lineHeight: '20px',
	backgroundColor: 'transparent',
	borderRadius: 0,
});
