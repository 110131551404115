import { Typography } from '@mms/mms-ui-library';
import { alpha, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

import { RemoveIcon } from '@/assets/buttons/RemoveIcon';

import { StyledListItemProps } from './types';

export const StyledListItem = styled('div')<StyledListItemProps>(
	({ theme, type }) => ({
		display: 'flex',
		justifyContent: 'pace-between',
		transition: 'all 0.2s ease-in-out',
		'&::before': {
			content: '" "',
			flex: 1,
			backgroundColor: theme.palette?.bookingWorkplace[type],
		},
		'&:hover': {
			backgroundColor: alpha(theme.palette.timeSheets.mainHover, 0.08),
		},
	})
);
export const ListItemContent = styled(Stack)(({ theme }) => ({
	flex: 40,
	padding: theme.spacing(1.25, 1.625),

	[theme.breakpoints.down('lg')]: {
		padding: theme.spacing(0.5),
	},
}));

export const MainTextWithMargin = styled(Typography)(({ theme }) => ({
	marginBottom: theme.spacing(0.375),
}));

export const IconCloseWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	maxWidth: 56,
	paddingRight: theme.spacing(1),
	justifyContent: 'flex-start',
	alignItems: 'center',
}));

export const StyledIconRemove = styled(RemoveIcon)(({ theme }) => ({
	width: 10,
	height: 10,
	color: theme.palette.secondary.main,
}));
