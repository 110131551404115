import { SvgIconProps, createSvgIcon } from '@mui/material';

const Trash = createSvgIcon(
	<path d="M0.928571 13.3333C0.928571 14.25 1.76429 15 2.78571 15H10.2143C11.2357 15 12.0714 14.25 12.0714 13.3333V5C12.0714 4.08333 11.2357 3.33333 10.2143 3.33333H2.78571C1.76429 3.33333 0.928571 4.08333 0.928571 5V13.3333ZM12.0714 0.833333H9.75L9.09071 0.241667C8.92357 0.0916666 8.68214 0 8.44071 0H4.55929C4.31786 0 4.07643 0.0916666 3.90929 0.241667L3.25 0.833333H0.928571C0.417857 0.833333 0 1.20833 0 1.66667C0 2.125 0.417857 2.5 0.928571 2.5H12.0714C12.5821 2.5 13 2.125 13 1.66667C13 1.20833 12.5821 0.833333 12.0714 0.833333Z" />,
	'Trash Dialog'
);
export function TrashInDialog({ sx, ...props }: SvgIconProps) {
	return (
		<Trash
			sx={{
				width: 13,
				height: 15,
				...sx,
			}}
			viewBox="0 0 13 15"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
