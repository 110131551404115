import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

export const LegendWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'row',
	gap: theme.spaces.m,
	marginTop: 'auto',
}));

export const SquareWrapper = styled('div')({
	width: 'min-content',
	height: 21,
	transition: 'all 3s ease-in-out',
	cursor: 'pointer',
	overflow: 'hidden',
	'&:hover': {
		width: 'fit-content !important',
		p: {
			opacity: '100 !important',
			width: '80px !important',
		},
	},
	p: {
		whiteSpace: 'nowrap',
		width: 0,
		opacity: 0,
		transition: 'all .3s ease-in-out',
		'&:first-letter': {
			textTransform: 'uppercase',
		},
	},
});
