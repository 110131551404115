import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HeaderContainer = styled(Stack)(({ theme }) => ({
	flexDirection: 'row',
	justifyContent: 'space-between',
	alignItems: 'center',
	borderBottom: 3,
	borderColor: theme.palette.primary.main,
}));
