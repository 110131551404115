import { createSvgIcon, SvgIconProps } from '@mui/material';

const Icon = createSvgIcon(
	<path
		d="M9 5L5 1L1 5"
		stroke="currentColor"
		strokeLinecap="round"
		fill="none"
	/>,
	'Up Icon'
);

export function ArrowUpIcon({ ...props }: SvgIconProps) {
	return (
		<Icon width={10} height={6} viewBox="0 0 10 6" fill="none" {...props} />
	);
}
