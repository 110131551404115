import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import {
	EquipmentQueriesKeys,
	GET_EQUIPMENT_API_ENDPOINT,
	NotificationType,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import type { EquipmentItemResponse } from '@/types/Equipment';

export const useGetEquipmentById = (toast: ToastContextValues, id: string) =>
	useQuery<EquipmentItemResponse, AxiosError>({
		queryKey: [EquipmentQueriesKeys.editing, id],
		queryFn: async () => {
			try {
				const { data } = await axios.get<EquipmentItemResponse>(
					`${GET_EQUIPMENT_API_ENDPOINT}/${id}`
				);

				return data;
			} catch {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return Promise.reject();
			}
		},
		refetchOnWindowFocus: false,
		enabled: Boolean(id),
		refetchOnMount: true,
	});
