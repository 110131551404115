import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import MaterialTableCell from '@mui/material/TableCell';
import MaterialTableRow from '@mui/material/TableRow';

import { CustomWidthTooltip } from '@/components/Table/components/CustomWidthTooltip';
import {
	BoxProject,
	BoxProjectTime,
	MaterialTableCellSkeleton,
	MaterialTableRowStyled,
	MaterialTableTwoCell,
	StyledMinHours,
	TypographyProjectName,
	TypographyStatus,
	TypographyStyled,
} from '@/components/Table/components/styles';
import { ActivityStatus } from '@/constants/index';
import { getRemainingMinutes, minsToHrs } from '@/helpers/translateTimeUtils';
import { useClientProjects } from '@/queries/useClientProjects';

import type { ClientProjectsProps } from './types';

const MAX_NAME_LENGTH_FOR_TOOLTIP = 36;

export function ClientProjects({
	clientId,
	isOpen,
	currentId,
}: ClientProjectsProps) {
	const { Active, Inactive } = ActivityStatus;
	const { data, isFetching } = useClientProjects(clientId, isOpen);

	if (isFetching) {
		return (
			<MaterialTableRow>
				<MaterialTableCellSkeleton colSpan={4}>
					<Skeleton height={2} variant="rectangular" animation="wave" />
				</MaterialTableCellSkeleton>
			</MaterialTableRow>
		);
	}

	if (data) {
		return (
			<>
				{data.map(({ name, trackedTime, isActive }, i) => (
					<MaterialTableRowStyled key={name}>
						<MaterialTableTwoCell>
							<Collapse
								in={currentId.includes(clientId)}
								timeout="auto"
								unmountOnExit
							>
								<Stack>
									<BoxProject
										display="flex"
										justifyContent="flex-start"
										index={i}
										active={Number(isActive)}
									>
										<CustomWidthTooltip
											currentWidth="100%"
											title={
												name.length > MAX_NAME_LENGTH_FOR_TOOLTIP ? name : ''
											}
										>
											<TypographyProjectName variant="m-400">
												{name}
											</TypographyProjectName>
										</CustomWidthTooltip>
										<TypographyStatus variant="s-400">
											{isActive ? Active : Inactive}
										</TypographyStatus>
									</BoxProject>
								</Stack>
							</Collapse>
						</MaterialTableTwoCell>
						<MaterialTableCell sx={{ padding: 0, border: 'none' }}>
							<Collapse
								in={currentId.includes(clientId)}
								timeout="auto"
								unmountOnExit
							>
								<Stack>
									<BoxProjectTime
										display="flex"
										justifyContent="flex-start"
										index={i}
										active={Number(isActive)}
									>
										<TypographyStyled variant="s-400">
											{minsToHrs(trackedTime) > 0 ? (
												<>
													{minsToHrs(trackedTime)}{' '}
													<StyledMinHours>h</StyledMinHours>{' '}
												</>
											) : (
												''
											)}
											{getRemainingMinutes(trackedTime)}{' '}
											<StyledMinHours>min</StyledMinHours>
										</TypographyStyled>
									</BoxProjectTime>
								</Stack>
							</Collapse>
						</MaterialTableCell>
						<MaterialTableCell sx={{ border: 'none' }}>
							<Collapse
								in={currentId.includes(clientId)}
								timeout="auto"
								unmountOnExit
							/>
						</MaterialTableCell>
					</MaterialTableRowStyled>
				))}
			</>
		);
	}

	return null;
}
