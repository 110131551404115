import { Scrollbar, Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material';

export const HistoryTypography = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.pxToRem(20),
	lineHeight: theme.spacing(4),
	color: theme.palette.secondary.main,
}));

export const HistoryPositionContainer = styled('div')(({ theme }) => ({
	position: 'relative',
	width: '100%',
	maxWidth: 548,
	height: 440,
	overflow: 'hidden',
}));

export const HistoryWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	borderRadius: 6,
	border: `1px solid ${theme.palette['primary-4']}`,
	padding: theme.spacing(1.5, 0, 0, 2),
	position: 'absolute',
	height: 900,
}));

export const List = styled(Scrollbar)(({ theme }) => ({
	marginTop: theme.spacing(2.5),
}));
