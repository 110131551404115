import { Switch } from '@mms/mms-ui-library';
import AccordionDetails from '@mui/material/AccordionDetails';
import FormControl from '@mui/material/FormControl';
import { memo } from 'react';

import { RepeatWeeks } from '@/pages/Booking/components';

import {
	StyledFormControlLabel,
	StyledAccordionSummary,
	StyledAccordion,
} from './styled';
import type { RepetitiveInnerProps } from './types';

function RepetitiveInner({
	isChecked,
	isControlDisabled,
	weeksCount,
	handleWeeksChange,
	handleChange,
}: RepetitiveInnerProps) {
	return (
		<>
			<FormControl>
				<StyledFormControlLabel
					control={
						<Switch
							checked={isChecked && !isControlDisabled}
							onChange={handleChange}
							name="repeat-switch"
							disabled={isControlDisabled}
						/>
					}
					label="Repeat booking"
				/>
			</FormControl>
			<StyledAccordion
				expanded={isChecked && !isControlDisabled}
				disableGutters
				elevation={0}
			>
				<StyledAccordionSummary />
				<AccordionDetails>
					<RepeatWeeks value={weeksCount} onChange={handleWeeksChange} />
				</AccordionDetails>
			</StyledAccordion>
		</>
	);
}

export const Repetitive = memo(RepetitiveInner);
