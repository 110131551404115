import { Box, Button, IconButton, styled } from '@mui/material';

import {
	getFiltersStyles,
	getInputStyles,
	makeInnerItemsFullWidth,
} from '@/components/Equipment/EquipmentControls/styles';
import type { ThemeWithMobileProps } from '@/components/Equipment/EquipmentControls/types';
import { checkIfForwardProps } from '@/helpers/checkIfForwardProps';
import { WHITE } from '@/theme';

export const FiltersWrapper = styled(Box, {
	shouldForwardProp: checkIfForwardProps('isMobile'),
})<ThemeWithMobileProps>(({ theme, isMobile }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	flexWrap: 'wrap',
	flexDirection: isMobile ? 'column' : 'row',
	rowGap: theme.spacing(1.25),
	width: '95%',
	'& > *:not(.MuiAutocomplete-popper)': {
		...getInputStyles(theme),
	},
	[theme.breakpoints.between(800, 1600)]: {
		display: 'grid',
		gridAutoRows: '1fr',
		gridTemplateColumns: 'repeat(3,minmax(150px,1fr))',
		gap: theme.spacing(1),
		...makeInnerItemsFullWidth(),
	},
	...getFiltersStyles(theme, isMobile),
}));

export const StyledBox = styled('div')(({ theme }) => ({
	display: 'flex',
	justifyContent: 'space-between',
	gap: theme.spacing(3),
	width: '100%',
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	position: 'absolute',
	right: theme.spacing(4.125),
	padding: theme.spacing(0.5),
}));

const StyledButton = styled(Button)(({ theme }) => ({
	textTransform: 'none',
	width: 112,
	minWidth: 100,
	height: 40,
	fontSize: theme.typography.pxToRem(16),
	color: theme.palette.secondary.contrastText,
	backgroundColor: theme.palette.secondary.main,
	'&:hover': {
		backgroundColor: theme.palette.secondary.main,
		opacity: 0.8,
	},
}));

export const StyledClearButton = styled(StyledButton)(({ theme }) => ({
	width: 65,
	minWidth: 65,
	padding: 0,
	marginRight: 0,
	color: theme.palette.secondary.main,
	backgroundColor: WHITE,
	'&:hover': {
		backgroundColor: WHITE,
	},
}));
