import { Typography } from '@mms/mms-ui-library';
import { IconButton, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BookingsBlock = styled('div')(({ theme }) => ({
	border: `1px solid ${theme.palette['primary-4']}`,
	backgroundColor: theme.palette['primary-1'],
	borderRadius: 6,
	paddingBottom: theme.spacing(5),
	height: '100%',
	overflow: 'hidden',
	minWidth: 550,
	maxWidth: 750,
	[theme.breakpoints.down('lg')]: {
		minWidth: 460,
	},
	[theme.breakpoints.down(720)]: {
		minWidth: 310,
	},
}));

export const BookingsHeaderWrapper = styled(Stack)(({ theme }) => ({
	borderTopLeftRadius: 6,
	borderTopRightRadius: 6,
	background: theme.palette['exc-primaryHeader-primary-10'],
	borderBottom: `1px solid ${theme.palette['primary-4']}`,
	color: theme.palette.secondary.contrastText,
	justifyContent: 'space-between',
	alignItems: 'center',
	height: 40,
	flexDirection: 'row',
	padding: theme.spacing(0, 2.5),
	'& > button': {
		padding: 0,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
}));

export const BookingsHeader = styled(Typography)(({ theme }) => ({
	color: (theme.palette as any)['exc-primaryHeader-primary-1'],
	[theme.breakpoints.down('sm')]: {
		fontSize: theme.typography.pxToRem(14),
	},
}));

export const BookingsBody = styled(Stack)(({ theme }) => ({
	position: 'relative',
	height: '100%',
	flexDirection: 'row',
	'.PrivateSwipeArea-root': {
		width: 0,
		position: 'relative',
	},
	'.MuiDrawer-root.MuiDrawer-modal.MuiModal-root': {
		position: 'absolute',
		width: '100%',
		overflow: 'hidden',
		'.MuiBackdrop-root.MuiModal-backdrop': {
			position: 'absolute',
		},
		'.MuiPaper-root.MuiPaper-elevation': {
			position: 'absolute',
		},
	},
	[theme.breakpoints.down('lg')]: {
		justifyContent: 'space-between',
	},
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	display: 'none !important',
	[theme.breakpoints.down(720)]: {
		display: 'flex !important',
	},
}));
