export const Keys = {
	Backspace: 'Backspace',
	Delete: 'Delete',
};

export const BookingQueriesKeys = {
	notifications: 'notifications',
	bookings: 'bookings',
	reservations: 'reservations',
	collisions: 'collisions',
};

export const ClientQuerieKeys = {
	clients: 'clients',
	clientsLookup: 'clients-lookup',
};

export const ReportsQueriesKyes = {
	reports: 'reports',
};

export const ProjectsQueriesKeys = {
	projects: 'projects',
	projectsByClientLookup: 'projects-by-client-lookup',
};

export const DashboardQueriesKeys = {
	dashboardManager: 'dashboard-manager',
	dashboardManagerTimeStack: 'dashboard-manager-time-stack',
	dashboardManagerUsers: 'dashboard-manager-users',
	dashboardManagerUser: 'dashboard-manager-user',
	dashboardManagerUserProject: 'dashboard-manager-user-project',
	dashboardManagerPieChart: 'dashboard-manager-pie-chart',
};

export const UsersQueriesKeys = {
	users: 'users',
	chiefs: 'chief-users',
	allUsers: 'users-all-lookup',
};

export const EquipmentQueriesKeys = {
	file: 'equipment-document',
	equipments: 'equipments',
	user: 'user-equipments',
	info: 'user-info',
	editing: 'equipment-editing-info',
	history: 'equipment-history',
};

export const SignatureQueriesKeys = {
	signature: 'signature',
	file: 'file',
};

export const AuthQueryKeys = {
	redirect: 'login-redirect',
};
