import { CalendarIcon } from '@mms/mms-ui-library';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { useCallback } from 'react';

import { CalendarUI } from '@/components/CalendarUI';
import { ARROW_COLORS } from '@/constants/calendar';

import { CustomDatePickerProps } from './types';

export function DatePicker({
	layoutProps,
	error,
	helperText,
	textFieldProps,
	slotsProps,
	...props
}: CustomDatePickerProps) {
	const dayOfWeekFormatter = useCallback((day: string) => `${day}`, []);

	return (
		<MuiDatePicker
			views={['day']}
			format="DD/MM/YYYY"
			slots={{
				layout: CalendarUI as any,
				openPickerIcon: CalendarIcon,
				...slotsProps,
			}}
			slotProps={{
				layout: {
					sx: { transform: 'scale(0.9)' },
					...layoutProps,
				},
				textField: {
					size: 'small',
					error,
					helperText: helperText as string,
					color: 'secondary',
					...textFieldProps,
				},
				previousIconButton: ARROW_COLORS,
				nextIconButton: ARROW_COLORS,
				openPickerButton: ARROW_COLORS,
			}}
			dayOfWeekFormatter={dayOfWeekFormatter}
			closeOnSelect={false}
			{...props}
		/>
	);
}
