import { Navigate, Outlet } from 'react-router-dom';

import { Routes } from '@/constants/routes';
import { useAuth } from '@/context/index';

import { PrivateRouteProps } from './type';

/**
 * Hide routes if user doesn't match the role
 */
export function PrivateRoute({
	available,
	redirectPath = Routes.all,
}: PrivateRouteProps) {
	const { role } = useAuth();

	if (!available.includes(role)) {
		return <Navigate to={redirectPath} replace />;
	}

	return <Outlet />;
}
