import { getFullName } from '@/helpers/formatName';
import type { LookupUserResponse } from '@/types/UserInfo';

export const getEmployeeOptions = (
	data: LookupUserResponse[] | undefined,
	isFetching: boolean
) =>
	isFetching || !data?.length
		? []
		: data.map(({ id, lastName, firstName, positionDescription }) => ({
				id,
				name: getFullName(firstName, lastName),
				positionDescription,
		  }));
