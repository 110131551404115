import { Tooltip } from '@mms/mms-ui-library';
import ClearIcon from '@mui/icons-material/Clear';
import { useTheme } from '@mui/material';
import { memo, useCallback, useState, useRef } from 'react';

import { CalendarIcon } from '@/assets/calendar/CalendarIcon';
import { FromTo } from '@/components/FromTo';
import { DEFAULT_PURCHASE_DATE_FILTER_VALUE } from '@/constants/equipment';
import { FilterKeys } from '@/constants/filters';
import { useOutsideClick } from '@/hooks/useOutsideClick';

import {
	Wrapper,
	StyledPaper,
	StyledIconButton,
	StyledInputAdornment,
	StyledTextField,
} from './styles';
import type { PurchaseFilterProps } from './types';

function PurchaseFilterInner({
	filterValue,
	setFilterValue,
	firstDay,
	lastDay,
	setFirstDay,
	setLastDay,
	removeFilter,
}: PurchaseFilterProps) {
	const [showInputs, setShowInputs] = useState(false);
	const wrapperRef = useRef(null);
	const theme = useTheme();

	const ignoredClassName = '.MuiDateCalendar-root';

	const onBlur = useCallback(() => {
		setShowInputs(false);
	}, []);

	const onFocus = useCallback(() => {
		setShowInputs(true);
	}, []);

	const handleClearPurchaseFilters = useCallback(() => {
		if (firstDay || lastDay) {
			removeFilter(FilterKeys.FirstDay);
			removeFilter(FilterKeys.LastDay);
			setFilterValue(DEFAULT_PURCHASE_DATE_FILTER_VALUE);
		}
	}, [firstDay, lastDay]);

	useOutsideClick(wrapperRef, onBlur, ignoredClassName);

	return (
		<Wrapper ref={wrapperRef}>
			<Tooltip
				open={
					Boolean(filterValue) &&
					filterValue !== DEFAULT_PURCHASE_DATE_FILTER_VALUE &&
					!showInputs
				}
				header={filterValue}
			>
				<StyledTextField
					label={DEFAULT_PURCHASE_DATE_FILTER_VALUE}
					value={filterValue}
					size="small"
					InputProps={{
						readOnly: true,
						endAdornment: (
							<StyledInputAdornment position="end">
								<StyledIconButton
									className="clear-button"
									size="small"
									onClick={handleClearPurchaseFilters}
									title="Clear"
								>
									<ClearIcon className="clear-icon" />
								</StyledIconButton>
								<CalendarIcon />
							</StyledInputAdornment>
						),
					}}
					autoFocus={showInputs}
					onFocus={onFocus}
				/>
			</Tooltip>
			{showInputs && (
				<StyledPaper elevation={8}>
					<FromTo
						firstDay={firstDay}
						lastDay={lastDay}
						setFirstDay={setFirstDay}
						setLastDay={setLastDay}
						setFilterValue={setFilterValue}
						direction="column"
						rowGap={theme.spacing(2.5)}
					/>
				</StyledPaper>
			)}
		</Wrapper>
	);
}

export const PurchaseFilter = memo(PurchaseFilterInner);
