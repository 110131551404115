import { useMemo } from 'react';

import { TableRow } from '../types';

import { MaterialTableCellStyledMap } from '@/components/Table/styles';
import { TableCellContent } from '@/components/Table/TableCellContent';
import { getCurrentWidth } from '@/components/Table/utils';
import { cellWidth } from '@/constants/index';

import { TableCellProps } from './types';

export function TableCell({
	cells,
	row,
	blockedCell,
	isEquipment,
}: TableCellProps) {
	const memoizedCell = useMemo(
		() =>
			cells.filter(
				(cell) =>
					![
						'avatarFileId',
						'position',
						!isEquipment && 'department',
						'description',
						'timeZone',
					].includes(cell)
			),
		[getCurrentWidth, cells, row]
	);

	const handleCopy = (data: keyof TableRow) => () => {
		navigator.clipboard.writeText(data);
	};

	return (
		<>
			{memoizedCell.map((cell) => (
				<MaterialTableCellStyledMap
					isEquipment={isEquipment}
					active={row.status}
					blockedCell={blockedCell}
					key={cell}
					cell={cell}
					align="left"
					onClick={
						isEquipment
							? handleCopy(row[cell as keyof TableRow] as keyof TableRow)
							: undefined
					}
				>
					<TableCellContent
						data={(row[cell as keyof TableRow] || '-') as string}
						data-value={row[cell as keyof TableRow]}
						withTooltip
						cellWidth={cellWidth[cell] as number}
					/>
				</MaterialTableCellStyledMap>
			))}
		</>
	);
}
