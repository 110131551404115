import { Stack } from '@mui/material';
import { Moment } from 'moment';
import { useCallback } from 'react';

import type { EquipmentFilterValue } from '@/components/Equipment/EquipmentControls/types';
import { currentDate } from '@/constants/date';
import { DEFAULT_PURCHASE_DATE_FILTER_VALUE } from '@/constants/equipment';
import { Input } from '@/pages/Booking/components/BookingTime/Input';

import type { FromToProps } from './types';
import { parseDateRangeToString } from './utils';

export function FromTo({
	firstDay,
	lastDay,
	setFirstDay,
	setLastDay,
	setFilterValue,
	...stackProps
}: FromToProps) {
	const handleFromChange = useCallback(
		(value: Moment | null) => {
			if (value && !value.isValid()) {
				setFirstDay(null);
				setLastDay(null);
				setFilterValue(DEFAULT_PURCHASE_DATE_FILTER_VALUE);

				return;
			}

			if (value && value.isAfter(currentDate, 'day')) {
				setFirstDay(null);
				setLastDay(null);
				setFilterValue(DEFAULT_PURCHASE_DATE_FILTER_VALUE);

				return;
			}
			setFirstDay(value as EquipmentFilterValue);
			setLastDay(null);
			setFilterValue(parseDateRangeToString(value, currentDate));
		},
		[setFirstDay, setLastDay, setFilterValue]
	);

	const handleToChange = useCallback(
		(value: Moment | null) => {
			if (value && !value.isValid()) {
				setLastDay(null);
				setFilterValue(parseDateRangeToString(firstDay, null));

				return;
			}

			if (
				value &&
				(value.isBefore(firstDay, 'day') || value.isAfter(currentDate, 'day'))
			) {
				setLastDay(null);
				setFilterValue(parseDateRangeToString(firstDay, null));

				return;
			}

			setLastDay(value as EquipmentFilterValue);
			setFilterValue(parseDateRangeToString(firstDay, value));
		},
		[firstDay, setLastDay, setFilterValue]
	);

	return (
		<Stack {...stackProps}>
			<Input
				label="From"
				value={firstDay}
				onChange={handleFromChange}
				disablePast={false}
				disableFuture
				isMinDate={false}
			/>
			<Input
				label="To"
				value={lastDay}
				disabled={!firstDay}
				onChange={handleToChange}
				disablePast={false}
				disableFuture
				isMinDate={Boolean(firstDay)}
				minDate={firstDay || undefined}
			/>
		</Stack>
	);
}
