import { Typography } from '@mms/mms-ui-library';
import { Stack, Box, IconButton, useTheme, Divider } from '@mui/material';
import { PropsWithChildren } from 'react';

import { RefreshIcon } from '@/assets/buttons/RefreshIcon';
import { ButtonAdd } from '@/components/ButtonAdd';
import { PageTitles } from '@/constants/pageTitles';

import { HeaderContainer } from './styles';
import type { PageHeaderProps } from './types';

export function PageHeader({
	children,
	title,
	onRefresh,
	onAdd,
	searchInput,
	divider = true,
}: PropsWithChildren<PageHeaderProps>) {
	const theme = useTheme();
	const handleAdd = () => {
		if (onAdd) {
			onAdd(0);
		}
	};

	return (
		<>
			<HeaderContainer>
				<Typography variant="large" color={(theme.palette as any)['primary-8']}>
					{title}
				</Typography>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box mr={title === PageTitles.Dashboard ? 14.5 : 1}>{children}</Box>
					{searchInput && (
						<Box sx={{ mr: theme.spacing(1) }}>{searchInput}</Box>
					)}
					{typeof onAdd === 'function' && <ButtonAdd onAdd={handleAdd} />}
					{typeof onRefresh === 'function' && (
						<Box>
							<IconButton
								onClick={onRefresh}
								size="large"
								aria-label="refresh"
								component="label"
							>
								<RefreshIcon />
							</IconButton>
						</Box>
					)}
				</Stack>
			</HeaderContainer>
			{divider ? (
				<Divider
					sx={{
						margin: `${theme.spacing(4)} 0`,
						boxShadow: 2,
					}}
				/>
			) : null}
		</>
	);
}
