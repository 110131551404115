import { Badge, Card, Stack, TextField, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';

import type { StyledCardProps } from './types';

export const getInputStyles = ({ spacing, palette }: Theme) => ({
	marginRight: spacing(1),
	'& fieldset': {
		border: `1px solid ${palette.buttons.main}`,
		borderRadius: 6,
	},
	'& fieldset:hover': {
		borderColor: palette.buttons.secondary,
	},
	'.MuiSvgIcon-root': {
		fill: palette.secondary.main,
	},
});

export const makeInnerItemsFullWidth = () => ({
	'& > *:not(.MuiAutocomplete-popper, button),& > *:not(.MuiAutocomplete-popper) > div':
		{
			width: '100% !important',
			minWidth: 150,
		},
});

export const getFiltersStyles = (theme: Theme, isMobile: boolean) =>
	isMobile && {
		display: 'flex',
		rowGap: theme.spacing(1.25),
		width: '100%',
		...makeInnerItemsFullWidth(),
	};

export const StyledBadge = styled(Badge)({
	'& > .MuiPaper-root': {
		width: '100%',
	},
});

export const StyledSelect = styled(TextField)(({ theme }) => ({
	width: 200,
	minWidth: 150,
	'& .MuiSelect-icon': { right: theme.spacing(1.125) },
	'& .clear-button': {
		display: 'none',
	},
	'&:hover .clear-button': {
		display: 'inline-flex',
	},
	...getInputStyles(theme),
}));

export const AutoselectWrapper = styled('div')({
	width: 200,
	minWidth: 150,
});

export const Wrapper = styled(Stack)(({ theme }) => ({
	gap: theme.spacing(1.25),
	width: '100%',
	margin: theme.spacing(2.5, 0),
	[theme.breakpoints.down(800)]: {
		margin: '0 auto',
	},
}));

export const StyledCard = styled(Card)<StyledCardProps>(
	({ theme, isOpen }) => ({
		overflowY: 'auto',
		'.MuiCardHeader-root': {
			padding: theme.spacing(1.0675),
			fontSize: theme.typography.pxToRem(16),
		},
		...getInputStyles(theme),
		marginRight: 0,
		'&': {
			border: `1px solid ${theme.palette.buttons.main}`,
			borderRadius: 6,
		},
		'&:hover': {
			borderColor: theme.palette.buttons.secondary,
		},
		'.MuiCardHeader-action .MuiSvgIcon-root': {
			fill: theme.palette.secondary.main,
			transform: isOpen && 'rotateZ(180deg)',
			transition: 'transform 0.3s',
		},
	})
);
