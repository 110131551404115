import { PopUp, Tooltip, Update } from '@mms/mms-ui-library';
import { Box, Grid, IconButton } from '@mui/material';
import { useMemo, memo, useState } from 'react';

import { CrossIcon } from '@/assets/buttons/CrossIcon';
import { useToast } from '@/context/Toast';
import type { HistoryItem } from '@/forms/EquipmentForm/types';
import { EquipmentHistoryProps } from '@/forms/EquipmentForm/UserForm/History/types';
import { formatDateString } from '@/helpers/dateToISOString';
import { getFullName } from '@/helpers/utils';
import { useDeleteLastEquipmentHistoryItem } from '@/queries/equipment/useDeleteLastEquipmentHistoryItem';

import { Title, CustomGridItem, Subtitle } from './styles';

const MAX_FIELD_LENGTH = 12;

function EquipmentHistoryItem({
	lastName,
	firstName,
	department,
	issueDate,
	returnDate,
	equipmentId,
	refetch,
	setHistoryItems,
}: HistoryItem & Omit<EquipmentHistoryProps, 'historyItems'>) {
	const toast = useToast();
	const { mutate } = useDeleteLastEquipmentHistoryItem(toast);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const toggleIsConfirmOpen = () => setIsConfirmOpen(!isConfirmOpen);
	const handleApply = () => {
		toggleIsConfirmOpen();
		mutate(equipmentId, {
			onSuccess: (response) => {
				refetch();
				setHistoryItems((history) => [...history.slice(1)]);
			},
		});
	};
	const handleDelete = () => {
		toggleIsConfirmOpen();
	};

	const sliceField = (field: string) =>
		field.length > MAX_FIELD_LENGTH
			? `${field.slice(0, MAX_FIELD_LENGTH)}...`
			: field;

	const gridItems = useMemo(
		() => [
			{
				id: 1,
				title: 'User:',
				value: getFullName(sliceField(lastName), sliceField(firstName)),
			},
			{ id: 2, title: 'Department:', value: department },
			{
				id: 3,
				title: 'Issue date:',
				value: formatDateString(issueDate),
			},
			{
				id: 4,
				title: 'Return date:',
				value: formatDateString(returnDate),
			},
		],
		[lastName, firstName, issueDate, returnDate]
	);

	const isFirstNameLarge = firstName.length > MAX_FIELD_LENGTH;
	const isLastNameLarge = lastName.length > MAX_FIELD_LENGTH;

	return (
		<Box mb={1.625}>
			<Grid container spacing={2}>
				{gridItems.map(({ id, title, value }) => (
					<CustomGridItem key={id} item xs>
						<Title Component="h6" variant="s-600">
							{title}
						</Title>
						{isFirstNameLarge || isLastNameLarge ? (
							<Tooltip
								open={isFirstNameLarge || isLastNameLarge}
								text={getFullName(lastName, firstName)}
							>
								<Subtitle
									Component="h6"
									sx={{
										width: title === '' ? 'fit-content' : 'none',
									}}
									variant="s-400"
								>
									{value}
								</Subtitle>
							</Tooltip>
						) : (
							<Subtitle
								Component="h6"
								sx={{
									width: title === '' ? 'fit-content' : 'none',
								}}
								variant="s-400"
							>
								{value}
							</Subtitle>
						)}
					</CustomGridItem>
				))}
				<CustomGridItem item>
					{!returnDate ? (
						<IconButton
							type="button"
							onClick={handleDelete}
							color="secondary"
							sx={{ paddingLeft: 0 }}
						>
							<CrossIcon />
						</IconButton>
					) : (
						<Box sx={{ width: 30 }} />
					)}
				</CustomGridItem>
			</Grid>

			{isConfirmOpen && (
				<PopUp
					title="HISTORY UPDATE"
					onClose={toggleIsConfirmOpen}
					headerIcon={<Update />}
					type="approve"
					showCloseButton={false}
					controls={{
						negativeControl: { onClick: toggleIsConfirmOpen },
						positiveControl: { onClick: handleApply },
					}}
				>
					Are you sure want to clear the user?
				</PopUp>
			)}
		</Box>
	);
}

export const EquipmentHistoryItemMemo = memo(EquipmentHistoryItem);
