import { styled } from '@mui/material/styles';

import type { StyledToastContainerProps } from '../types';

export const StyledCloseButton = styled('span')<StyledToastContainerProps>(
	({ styles }) => ({
		color: styles.palette.text.primary,
		userSelect: 'none',
		padding: styles.spacing(2, 3, 0, 0),
		height: 'fit-content',
	})
);
