export const BookPlaceType = {
	Booked: 'booked',
	Selected: 'selected',
	Blocked: 'blocked',
};

export const BookType = {
	Single: 'single',
	Repetitive: 'repetitive',
	Multiple: 'multiple',
};

export const REPEAT_WEEKS_MIN_VALUE = 1;
export const REPEAT_WEEKS_MAX_VALUE = 4;

export enum RequestTypes {
	CreateSelf = 'CreateSelf',
	CreateBlock = 'CreateBlock',
	CreateForUser = 'CreateForUser',
}

export const BLOCK_DIALOG_HEADER = 'DESK BLOCKING';
export const UNBLOCK_DIALOG_HEADER = 'DESK UNBLOCKING';
export const CANCEL_DIALOG_HEADER = 'BOOKING CANCELING';

export const INVERTED_TABLE_NUMBERS = [
	1, 3, 5, 8, 10, 12, 14, 15, 16, 17, 22, 23, 24, 25, 26,
];
export const COLORS_ENUM = {
	Available: 'available',
	Blocked: 'blocked',
	Booked: 'booked',
	Selected: 'selected',
};
export const COLORS = Object.values(COLORS_ENUM);

export const MAX_SEATS = 31;
