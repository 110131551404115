import { Typography } from '@mms/mms-ui-library';
import { memo } from 'react';

import { Square } from '@/components/Square';
import { COLORS, COLORS_ENUM } from '@/constants/index';

import { SquareWrapper, LegendWrapper } from './styled';

function LegendInner() {
	return (
		<LegendWrapper>
			{COLORS.map((color) => (
				<SquareWrapper key={color}>
					<Square type={color} />
					<Typography Component="p" variant="m-400">
						{color === COLORS_ENUM.Selected ? 'Your desk' : color}
					</Typography>
				</SquareWrapper>
			))}
		</LegendWrapper>
	);
}

export const Legend = memo(LegendInner);
