import { Tooltip } from '@mms/mms-ui-library';
import { useCallback, memo } from 'react';

import {
	INVERTED_TABLE_NUMBERS,
	Roles,
	BookPlaceType,
} from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { getFullName } from '@/helpers/utils';

import { StyledDesk, DeskTitle, BookedAvatar } from './styled';
import type { DeskInnerProps } from './types';

const { CEO, CTO, HD } = Roles;
const { Blocked, Booked } = BookPlaceType;

function DeskInner({
	firstName,
	lastName,
	department,
	userId,
	workplaceNumber,
	isBlocked,
	isSelected,
	onClick,
}: DeskInnerProps) {
	const { id: personId, department: userDepartment, role } = useAuth();
	const user = lastName && firstName && getFullName(firstName, lastName);

	const isRotated = INVERTED_TABLE_NUMBERS.includes(workplaceNumber);
	const isBooked = !isBlocked && !!userId && userId !== personId;
	const isBookedByUser = userId === personId;

	const handleClick = useCallback(
		() => onClick(isSelected ? 0 : workplaceNumber),
		[onClick, isSelected]
	);

	const showUserInitials =
		role === HD
			? (role === HD && userDepartment === department) ||
			  isBookedByUser ||
			  !isBlocked
			: !isBlocked ||
			  isBookedByUser ||
			  [CEO, CTO].includes(role as Roles) ||
			  userDepartment === department;

	return (
		<Tooltip
			open={Boolean(user)}
			position="right"
			fallbackPlacements={['left', 'bottom', 'top']}
			header={`Desk is ${isBlocked ? Blocked : Booked}`}
			text={showUserInitials ? user : null}
		>
			<StyledDesk
				isRotated={isRotated}
				isBooked={isBooked}
				isBlocked={isBlocked}
				isBookedByUser={isBookedByUser}
				onClick={handleClick}
				number={workplaceNumber}
				selected={isSelected}
				className={`desk-${workplaceNumber}`}
			>
				<DeskTitle Component="h6" variant="xs-500">
					<>
						M-{workplaceNumber}
						{showUserInitials && user && (
							<BookedAvatar
								fileId=""
								firstName={firstName}
								lastName={lastName}
							/>
						)}
					</>
				</DeskTitle>
			</StyledDesk>
		</Tooltip>
	);
}

export const Desk = memo(DeskInner);
