import { Scrollbar } from '@mms/mms-ui-library';
import CancelIcon from '@mui/icons-material/Cancel';
import MenuIcon from '@mui/icons-material/Menu';
import { SwipeableDrawer } from '@mui/material';
import { useCallback, useState, useRef, useMemo } from 'react';

import { useResize } from '@/hooks/useResize';
import { BookingList, BookingManagement } from '@/pages/Booking/components';

import {
	BookingsBlock,
	BookingsHeader,
	BookingsBody,
	BookingsHeaderWrapper,
	StyledIconButton,
} from './styled';

export function BookingBlock() {
	const [open, setOpen] = useState(false);
	const blockRef = useRef<HTMLDivElement>(null);
	const [innerWidth] = useResize();
	const isMobileView = useMemo(() => innerWidth <= 720, [innerWidth]);

	const toggleDrawer = useCallback(
		(newOpen: boolean) => () => {
			setOpen(newOpen);
		},
		[]
	);

	return (
		<BookingsBlock>
			<BookingsHeaderWrapper>
				<BookingsHeader variant="small">Bookings (Minsk)</BookingsHeader>
				<StyledIconButton
					color="inherit"
					aria-label="open drawer"
					onClick={toggleDrawer(!open)}
					edge="start"
				>
					{open ? <CancelIcon /> : <MenuIcon />}
				</StyledIconButton>
			</BookingsHeaderWrapper>
			<Scrollbar maxHeight="100%" maxWidth="100%" scroll>
				<BookingsBody ref={blockRef}>
					<BookingManagement />
					{!isMobileView && <BookingList />}
					{isMobileView && (
						<SwipeableDrawer
							container={blockRef.current}
							anchor="right"
							swipeAreaWidth={0}
							open={open}
							onClose={toggleDrawer(false)}
							onOpen={toggleDrawer(true)}
							disableSwipeToOpen={false}
							ModalProps={{
								keepMounted: true,
							}}
						>
							<BookingList />
						</SwipeableDrawer>
					)}
				</BookingsBody>
			</Scrollbar>
		</BookingsBlock>
	);
}
