import { Button, CurrencyInput } from '@mms/mms-ui-library';
import type { SelectChangeEvent } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import moment, { Moment } from 'moment';
import numeral from 'numeral';
import { ChangeEvent, useEffect } from 'react';
import { useQueryClient } from 'react-query';

import { FormContent } from '../styles';

import {
	INITIAL_ORDER_BY_VALUE,
	NUMERAL_PRICE_FORMAT,
} from '@/constants/equipment';
import { ROWS_PER_PAGE } from '@/constants/pagination';
import { EquipmentQueriesKeys } from '@/constants/queriesKeys';
import { Roles } from '@/constants/roles';
import { DefaultSortFields, SortDirection } from '@/constants/sort';
import { useEquipmentContext } from '@/context/Equipment';
import { useKnowledgeBase } from '@/context/KnowledgeBase';
import { useToast } from '@/context/Toast';
import { DatePicker } from '@/fields/DatePicker';
import { SelectField } from '@/fields/Select';
import { TextField } from '@/fields/Text';
import { TextArea } from '@/fields/TextArea';
import { checkFieldDates } from '@/helpers/checkFieldDates';
import { updateGridItems } from '@/helpers/updateGridItems';
import { getFullName } from '@/helpers/utils';
import { usePageWithSearch } from '@/hooks/usePageWithSearch';
import { useCreateEquipment } from '@/queries/equipment';
import { useGetEquipmentById } from '@/queries/equipment/useGetEquipmentById';
import { useUpdateEquipment } from '@/queries/equipment/useUpdateEquipment';
import { useUsersLookup } from '@/queries/useUsersLookup';
import type {
	EquipmentItemResponse,
	EquipmentItemRequest,
} from '@/types/Equipment';
import type { KnowledgeType } from '@/types/KnowledgeBase';

import { validationSchema } from './schema';
import type { MainEquipmentFormProps } from './types';

const { CEO, CTO, HD } = Roles;

export function MainEquipmentForm({
	setEquipmentId,
	id: equipmentId,
	setIsFormValid,
	refetch,
	onLoad,
	submitFormButtonRef,
}: MainEquipmentFormProps) {
	const queryClient = useQueryClient();
	const toast = useToast();
	const { equipmentModel, setEquipmentModel } = useEquipmentContext();
	const { page, search } = usePageWithSearch();
	const onSuccess = {
		onSuccess: (response: EquipmentItemResponse) => {
			setEquipmentId(response.id);
			refetch();
		},
	};
	const { mutate } = useCreateEquipment(toast, onSuccess);
	const { mutate: updateMutation, isLoading } = useUpdateEquipment(toast, {
		onSuccess: (response) => {
			queryClient.setQueryData(
				[
					EquipmentQueriesKeys.equipments,
					page + 1,
					Number(ROWS_PER_PAGE),
					SortDirection.ASC,
					INITIAL_ORDER_BY_VALUE,
					search,
					null,
					undefined,
					undefined,
					undefined,
					undefined,
					null,
					null,
				],
				(prev: any) => {
					updateGridItems(
						prev,
						response,
						DefaultSortFields.TYPE,
						SortDirection.ASC
					);
					refetch();
				}
			);
		},
	});
	const { data: equipmentResponse, isFetching: isFetchingPreData } =
		useGetEquipmentById(toast, equipmentId ?? '');
	useEffect(() => {
		if (!isLoading && equipmentResponse) {
			setEquipmentModel(equipmentResponse as EquipmentItemRequest);
		}
	}, [isLoading, equipmentResponse]);
	const { knowledgeBase } = useKnowledgeBase();
	const { EquipmentLocationType, EquipmentType, MoneyCurrencyType } =
		knowledgeBase;
	const { data, isFetching: isFetchingUsers } = useUsersLookup('', [
		CEO,
		CTO,
		HD,
	]);
	const approver = (data || []).map(({ id, firstName, lastName }) => ({
		id,
		name: getFullName(firstName, lastName),
		description: getFullName(firstName, lastName),
	})) as KnowledgeType[];

	const options: Record<string, KnowledgeType[]> = {
		type: [...(EquipmentType || [])],
		location: [...(EquipmentLocationType || [])],
		approver,
	};

	useEffect(() => {
		onLoad(isFetchingPreData || isFetchingUsers);
	}, [isFetchingPreData, isFetchingUsers]);

	const handleSubmitForm = (
		values: EquipmentItemRequest,
		{ validateForm }: FormikHelpers<EquipmentItemRequest>
	) => {
		if (!validateForm(values)) {
			return;
		}
		const request = {
			...values,
			purchasePrice: numeral(values.purchasePrice).value() ?? undefined,
			purchasePriceUsd: numeral(values.purchasePriceUsd).value() ?? undefined,
			equipmentType: EquipmentType.find(
				(type) => type.description === values.equipmentType
			)?.id as string,
			location: EquipmentLocationType.find(
				(type) => type.description === values.location
			)?.id as string,
			approverId: approver.find(
				(type) => type.description === (values.approver as unknown as string)
			)?.id as number,
		};

		if (equipmentId) {
			updateMutation({ ...request, equipmentId }, onSuccess);
		} else {
			mutate({ ...request }, onSuccess);
		}
	};

	const {
		approver: equipmentApprover,
		equipmentType,
		location,
		name,
		comment,
		characteristics,
		guaranteeDate,
		purchaseDate,
		purchasePlace,
		purchasePrice,
		purchasePriceUsd,
		serialNumber,
		purchaseCurrency,
	} = equipmentResponse || equipmentModel;

	const prefilledValues = equipmentId
		? {
				name,
				equipmentType,
				serialNumber,
				purchaseCurrency,
				purchasePrice: numeral(purchasePrice).format(NUMERAL_PRICE_FORMAT),
				purchasePriceUsd:
					numeral(purchasePriceUsd).format(NUMERAL_PRICE_FORMAT),
				purchasePriceUsdCurrency: 'USD',
				purchaseDate: moment(purchaseDate),
				purchasePlace,
				guaranteeDate: moment(guaranteeDate),
				characteristics,
				approver: getFullName(
					equipmentApprover?.firstName as string,
					equipmentApprover?.lastName as string
				),
				location,
				comment,
		  }
		: null;

	return (
		<Formik
			validationSchema={validationSchema}
			initialValues={
				(equipmentId ? prefilledValues : equipmentModel) as EquipmentItemRequest
			}
			isInitialValid={Boolean(equipmentModel.name)}
			onSubmit={handleSubmitForm}
			enableReinitialize
			validateOnChange
			validateOnBlur={false}
		>
			{({
				values,
				errors,
				isValid,
				touched,
				setFieldValue,
				setFieldTouched,
			}) => {
				if (isValid) {
					setEquipmentModel(values);
				}

				const handleChange =
					(value: string) =>
					(
						e:
							| ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
							| SelectChangeEvent<unknown>
							| Moment
							| null
					) => {
						setFieldValue(
							value,
							checkFieldDates(value)
								? e
								: (
										e as
											| ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
											| SelectChangeEvent<unknown>
								  ).target.value,
							true
						);
						setFieldTouched(value, true);
					};

				const handleInputChange = (fieldName: string, fieldValue: string) => {
					setFieldValue(fieldName, fieldValue, true);
					setFieldTouched(fieldName, true);
				};

				const handleInputCurrencyChange =
					(fieldName: string) => (value: string) => {
						setFieldValue(fieldName, value, true);
						setFieldTouched(fieldName, true);
					};

				if (Object.keys(errors).length === 0 && equipmentId) {
					setIsFormValid(true);
				} else {
					setIsFormValid(false);
				}

				return (
					<FormContent>
						<TextField
							label="Equipment name"
							name="name"
							style={{ height: 40 }}
							onChange={handleInputChange}
						/>
						<SelectField
							label="Equipment type"
							variant="outlined"
							name="equipmentType"
							size="small"
							disabled={false}
							options={options.type}
							error={
								Boolean(errors.equipmentType) && Boolean(touched.equipmentType)
							}
							onChange={handleChange('equipmentType')}
							fullWidth
						/>
						<TextField
							label="Serial number"
							name="serialNumber"
							size="fullWidth"
							onChange={handleInputChange}
						/>
						<DatePicker
							label="Purchase date"
							error={
								Boolean(touched.purchaseDate) && Boolean(errors.purchaseDate)
							}
							helperText={touched.purchaseDate ? errors.purchaseDate : ''}
							textFieldProps={{
								name: 'purchaseDate',
								value: values.purchaseDate,
							}}
							onChange={handleChange('purchaseDate')}
						/>
						<CurrencyInput
							size="fullWidth"
							label="Purchase price"
							value={values.purchasePrice?.toString() || ''}
							error={
								Boolean(errors.purchasePrice) && Boolean(touched.purchasePrice)
							}
							errorText={errors.purchasePrice}
							currency={values.purchaseCurrency || ''}
							onValueChange={handleInputCurrencyChange('purchasePrice')}
							onCurrencyChange={handleInputCurrencyChange('purchaseCurrency')}
						/>
						<CurrencyInput
							size="fullWidth"
							label="Price in dollars"
							value={values.purchasePriceUsd?.toString() || ''}
							error={
								Boolean(errors.purchasePriceUsd) &&
								Boolean(touched.purchasePriceUsd)
							}
							errorText={errors.purchasePriceUsd}
							currency={values.purchasePriceUsdCurrency || ''}
							currenciesOptions={['USD']}
							onValueChange={handleInputCurrencyChange('purchasePriceUsd')}
							onCurrencyChange={handleInputCurrencyChange(
								'purchaseCurrencyUsd'
							)}
						/>
						<DatePicker
							label="Guarantee date"
							error={
								Boolean(touched.guaranteeDate) && Boolean(errors.guaranteeDate)
							}
							helperText={touched.guaranteeDate ? errors.guaranteeDate : ''}
							textFieldProps={{
								name: 'guaranteeDate',
								value: values.guaranteeDate,
							}}
							onChange={handleChange('guaranteeDate')}
						/>

						<TextField
							label="Purchase place"
							name="purchasePlace"
							size="fullWidth"
							onChange={handleInputChange}
						/>
						<SelectField
							label="Approver"
							variant="outlined"
							name="approver"
							size="small"
							disabled={false}
							options={options.approver}
							error={Boolean(errors.approver) && Boolean(touched.approver)}
							onChange={handleChange('approver')}
							fullWidth
						/>
						<SelectField
							label="Location"
							variant="outlined"
							name="location"
							size="small"
							disabled={false}
							options={options.location}
							error={Boolean(errors.location) && Boolean(touched.location)}
							onChange={handleChange('location')}
							fullWidth
						/>
						<TextArea
							label="Equipment characteristics"
							name="characteristics"
							size="fullWidth"
							maxRows={1}
							error={
								Boolean(errors.characteristics) &&
								Boolean(touched.characteristics)
							}
						/>
						<TextArea
							label="Comment"
							name="comment"
							size="fullWidth"
							error={Boolean(errors.comment) && Boolean(touched.comment)}
						/>
						<Button
							type="submit"
							ref={submitFormButtonRef}
							style={{ display: 'none' }}
						>
							Submit
						</Button>
					</FormContent>
				);
			}}
		</Formik>
	);
}
