import { MouseEvent, useCallback, useState } from 'react';

import type { TableLabel, TableRow as TableRowType } from '../types';

import { TableRowContent } from '@/components/Table/TableRowContent';

import type { TableRowProps } from './types';
import { checkIfRowBlocked } from './utils';

export function TableRow({
	rows,
	onOpen,
	extendImg,
	extendAvatar,
	title,
	blockedItems,
	isEquipment,
}: TableRowProps) {
	const [currentId, setCurrentId] = useState<Array<string>>([]);

	const handleSetOpen = useCallback(
		(id: string, isRowBlocked: boolean) => () => {
			if (!isRowBlocked) {
				setCurrentId((prev) =>
					prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
				);
			}
		},
		[]
	);

	const handleEdit = useCallback(
		(id: string, isRowBlocked?: boolean) =>
			(event: MouseEvent<HTMLButtonElement>) => {
				if (onOpen) {
					setCurrentId((prev) => prev.filter((item) => item !== id));
					event.stopPropagation();

					if (!isRowBlocked) {
						onOpen(event, id);
					}
				}
			},
		[]
	);

	if (rows) {
		return rows.slice()?.map((row, index) => {
			const labelId = `enhanced-table-checkbox-${index}`;
			const keys = Object.keys(row);
			const other = keys.includes('id') // remove first column with icon and id field
				? keys.filter((cell) => cell !== 'id').slice(1)
				: keys.slice(1);
			const rowId = row.id ? row.id : (row as TableRowType).name.name;
			const isRowBlocked = checkIfRowBlocked(blockedItems, rowId);

			return (
				<TableRowContent
					key={rowId}
					row={row}
					other={other as Array<TableLabel>}
					isRowBlocked={isRowBlocked}
					labelId={labelId}
					title={title}
					extendAvatar={extendAvatar}
					extendImg={extendImg}
					handleEdit={handleEdit}
					onOpen={onOpen}
					handleSetOpen={handleSetOpen}
					currentId={currentId}
					isEquipment={isEquipment}
				/>
			);
		});
	}

	return null;
}
