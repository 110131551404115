import { Loader } from '@mms/mms-ui-library';

import { Overlay } from './styles';
import { PreloaderProps } from './types';

export function Preloader({ isBackgroundShowed = true }: PreloaderProps) {
	return (
		<Overlay open invisible={isBackgroundShowed}>
			<Loader />
		</Overlay>
	);
}
