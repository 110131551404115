import { Typography } from '@mms/mms-ui-library';
import { Divider, IconButton, Stack, useTheme } from '@mui/material';
import { memo } from 'react';

import { isNotChiefRole } from '@/constants/roles';
import {
	getBookingPlaceType,
	getFullName,
	createPeriodInText,
	getBookType,
} from '@/helpers/index';

import {
	ListItemContent,
	StyledListItem,
	MainTextWithMargin,
	IconCloseWrapper,
	StyledIconRemove,
} from './styled';
import type { ItemInnerProps } from './types';

function ItemInner({
	currentUserId,
	userRole,
	userId,
	isBlock,
	lastName,
	firstName,
	weeksInInterval,
	dateList,
	workplaceNumber,
	handleDelete,
}: ItemInnerProps) {
	const theme = useTheme();

	return (
		<>
			<StyledListItem
				type={getBookingPlaceType(currentUserId, userId, isBlock)}
			>
				<ListItemContent direction="row">
					<Stack alignItems="flex-start">
						{userId !== currentUserId && lastName && firstName && (
							<MainTextWithMargin
								variant="s-600"
								color={(theme.palette as any)['base-6']}
							>
								{getFullName(firstName, lastName)}
							</MainTextWithMargin>
						)}
						<Typography
							variant="s-600"
							color={(theme.palette as any)['base-6']}
						>
							{createPeriodInText(weeksInInterval, dateList)}
						</Typography>
						<Typography
							variant="m-400"
							color={(theme.palette as any)['base-6']}
						>
							{`M-${workplaceNumber} - ${getBookType(
								weeksInInterval,
								dateList
							)}`}
						</Typography>
					</Stack>
				</ListItemContent>
				<IconCloseWrapper>
					{!(isBlock && isNotChiefRole(userRole)) && (
						<IconButton size="small" onClick={handleDelete}>
							<StyledIconRemove />
						</IconButton>
					)}
				</IconCloseWrapper>
			</StyledListItem>
			<Divider />
		</>
	);
}

const areItemPropsEqual = (
	prevProps: { userId: number },
	nextProps: { userId: number }
) => prevProps.userId === nextProps.userId;

export const Item = memo(ItemInner, areItemPropsEqual);
