import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import { GET_USERS_API_ENDPOINT } from '@/constants/queryPaths';
import { getKeywordQuery } from '@/helpers/queries';
import { LookupUserResponse } from '@/types/UserInfo';

export const useUsersLookup = (
	search = '',
	roles: Array<string> = [],
	options?: UseQueryOptions<Array<LookupUserResponse>, AxiosError>
) => {
	const query = getKeywordQuery(search, roles);

	return useQuery<Array<LookupUserResponse>, AxiosError>({
		...options,
		queryKey: ['users-lookup', search],
		queryFn: () =>
			axios
				.get<Array<LookupUserResponse>>(
					`${GET_USERS_API_ENDPOINT}/lookup${query}`
				)
				.then((res) => res.data),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
};
