import MaterialTableBody from '@mui/material/TableBody';
import MaterialTableCell from '@mui/material/TableCell';
import MaterialTableRow from '@mui/material/TableRow';

import { TableRow } from '@/components/Table/TableRow';

import type { TableBodyProps } from './types';

export function TableBody({
	emptyRows,
	colSpan,
	...otherProps
}: TableBodyProps) {
	return (
		<MaterialTableBody>
			<TableRow {...otherProps} />
			{emptyRows && emptyRows > 0 && (
				<MaterialTableRow
					style={{
						height: 53 * emptyRows,
					}}
				>
					<MaterialTableCell colSpan={colSpan} />
				</MaterialTableRow>
			)}
		</MaterialTableBody>
	);
}
