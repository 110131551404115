import { Theme } from '@mui/material';
import { Moment } from 'moment';
import { Dispatch, SetStateAction } from 'react';
import { ToastOptions } from 'react-toastify';

import { Roles } from '@/constants/roles';
import { ThemeMode } from '@/theme';
import type { EquipmentItemRequest } from '@/types/Equipment';
import type { KnowledgeBase } from '@/types/KnowledgeBase';
import type { UserInfo } from '@/types/UserInfo';

export interface ContextUserInfo extends UserInfo {
	refetching: boolean;
	loading: boolean;
	refetch: () => void;
}

export interface KnowledgeBaseContextValues {
	knowledgeBase: {
		loading: boolean;
	} & KnowledgeBase;
}

export interface ColorModeContextValues {
	mode: ThemeMode;
	toggleThemeMode: () => void;
	theme: Theme;
}

export interface ToastContextValues {
	open: (params: {
		message: string | JSX.Element | null;
		settings: ToastOptions;
	}) => void;
}

export interface HighlightedDays {
	bookedList: number[];
	yourList: number[];
	blockedList: number[];
}

interface Workplace {
	department: string;
	firstName: string;
	isBlocked: boolean;
	lastName: string;
	userId: number;
	workplaceId: number;
	workplaceNumber: number;
}

type Employee = {
	id: number;
	name: string;
	positionDescription: string;
} | null;

export interface BookingContextInitialValues {
	selected: number;
	firstDay: Moment | null;
	lastDay: Moment | null;
	employee: Employee;
	initialEmployee: Employee;
	highlightedDays: HighlightedDays;
	workplaces: Workplace[];
	error: null | string;
	selectedWorkplace?: Workplace;
}

export interface BookingContextValues extends BookingContextInitialValues {
	setSelected: (workplaceNumber: number) => void;
	setFirstDay: (day: Moment | null) => void;
	setLastDay: (day: Moment | null) => void;
	setEmployee: Dispatch<SetStateAction<Employee>>;
	setHighlightedDays: (days: HighlightedDays) => void;
	setWorkplaces: Dispatch<SetStateAction<Workplace[]>>;
	setError: Dispatch<SetStateAction<string | null>>;
	init: () => void;
}

export type Payload = number | [] | null;

export interface Action {
	type: string;
	payload: Payload;
}

export interface EquipmentContextValues {
	equipmentModel: EquipmentItemRequest;
	setEquipmentModel: Dispatch<SetStateAction<EquipmentItemRequest>>;
}
export interface AuthContextInitialValues {
	token: string | null;
}

export type AuthState = {
	token: string | null;
	role: Roles;
	loading: boolean;
	handleReceivedToken?: HandleReceivedToken;
	onLogout?: () => void;
} & Partial<UserInfo>;

export type InitialAuthState = Omit<
	AuthState,
	'handleReceivedToken' | 'onLogout' | 'loading'
>;

export type HandleReceivedToken = (token: string) => void;

export enum SessionBroadcastEvents {
	requestSession = 'requestSession',
	sendSession = 'sendSession',
}

export type SessionBroadcastMessage = {
	type: string;
	data?: string;
};
