import {
	MAX_SELECT_YEAR,
	MIN_SELECT_YEAR,
	ONLY_PUNCTUATION_OR_SPACES_EXP,
	LETTERS_AND_NUMBERS_REG_EXP,
} from '@/constants/index';

const SECOND_SYMBOL_INDEX = 1;

export const checkToAllSpaces = (value: string | undefined) =>
	!(value?.split('').every((char) => char === ' ') && value?.split('').length);

export const checkToAllSpacesFromSecondSymbol = (value: string | undefined) =>
	value && value.length > SECOND_SYMBOL_INDEX
		? value
				.slice(SECOND_SYMBOL_INDEX)
				.split('')
				.every((char) => char === ' ')
		: false;

export const checkIfNoLettersOrNumbers = (value: string | undefined) =>
	!value?.match(LETTERS_AND_NUMBERS_REG_EXP);

export const checkToAllHyphens = (value: string | undefined) =>
	!(value?.split('').every((char) => char === '-') && value?.split('').length);

export const checkToAllHyphensAndSpaces = (value: string | undefined) =>
	!(
		value?.split('').every((char) => char === '-' || char === ' ') &&
		value?.split('').length
	);

export const checkToMultipleHyphensAtOnce = (value: string | undefined) =>
	!value?.includes('--');

export const checkToMultipleSpacesAtOnce = (value: string | undefined) =>
	!value?.includes('  ');

export const checkToMultipleSpacesAndHyphensAtOnce = (
	value: string | undefined
) => !['- -', '- - ', ' - -'].some((el) => value?.includes(el));
export const checkNumberLength = (limit: number, value?: string) => {
	if (value !== undefined) {
		return value.toString().length <= limit;
	}

	return true;
};

export const checkOptionsNotZero = (item?: string) => item !== '0';

export const checkYearInValidRange = (date?: string | null) => {
	if (!date) {
		return true;
	}

	const yearStartIndex = 11; // start year index in strings like 'Mon 01 Jan 2200 00:00:00 GMT'

	// Date.parse(), new Date(), .... return formatted year(0022=>2022) that is not what we expect
	const year = Number(date.slice(yearStartIndex, yearStartIndex + 4));

	return year < MAX_SELECT_YEAR && year > MIN_SELECT_YEAR;
};

export const checkUrlToAvatar = (value?: string) => {
	if (!value) {
		return false;
	}

	if (!['http://', 'https://'].some((item) => value.startsWith(item))) {
		return false;
	}

	if (!['.jpg', '.jpeg', '.png'].some((item) => value.endsWith(item))) {
		return false;
	}

	try {
		(() => new URL(value))();
	} catch {
		return false;
	}

	return true;
};

export const checkEmptyField = (value?: string) => Boolean(value);

export const checkIncludesOtherThanSpecialSymbols = (value?: string) =>
	!ONLY_PUNCTUATION_OR_SPACES_EXP.test(value!);
