import { Scrollbar } from '@mms/mms-ui-library';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	CardContent,
	CardHeader,
	Collapse,
	IconButton,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ExportControls } from '@/components/Equipment/EquipmentControls/ExportControls';
import { Filters } from '@/components/Equipment/EquipmentControls/Filters';
import { FilterKeys } from '@/constants/filters';
import {
	initialApproversFilterValue,
	initialEquipmentLocationFilterValue,
	initialEquipmentTypeFilterValue,
	initialUserFilterValue,
	FILE_EXTENSIONS,
} from '@/constants/index';
import { useKnowledgeBase } from '@/context/KnowledgeBase';
import { useToast } from '@/context/Toast';
import { parseMomentDateToUTCString } from '@/helpers/date';
import {
	swapKnowledgees,
	swapNameAndDescription,
} from '@/helpers/swapKnowledgees';
import { useEquipmentFilter } from '@/hooks/useEquipmentFilter';
import { useExportEquipment } from '@/queries/equipment/useExportEquipment';

import { FiltersProps } from './Filters/types';
import { StyledBadge, StyledCard, Wrapper } from './styles';
import {
	type EquipmentControlsProps,
	type EquipmentFilterOption,
	type Target,
} from './types';
import {
	getApproversOptions,
	getKnowledgeBaseOptions,
	getUsersOptions,
} from './utils';

export function EquipmentControls({
	filters,
	setFilters,
	filtersOptions,
	orderBy,
	debouncedSearch,
}: EquipmentControlsProps) {
	const { filtersValues, resetAllFilters, handleFilterChanges, removeFilter } =
		useEquipmentFilter(filters);

	const theme = useTheme();

	const [exportFileType, setExportFileType] = useState(FILE_EXTENSIONS.xlsx);

	const toast = useToast();

	const { refetch: getFile } = useExportEquipment(
		toast,
		exportFileType === FILE_EXTENSIONS.xlsx
			? FILE_EXTENSIONS.excel
			: exportFileType,
		orderBy,
		filtersValues[FilterKeys.Availability] === 'Available' ? true : null,
		(filtersValues[FilterKeys.User] as EquipmentFilterOption)?.id,
		(filtersValues[FilterKeys.EquipmentType] as EquipmentFilterOption)?.name,
		(filtersValues[FilterKeys.Approver] as EquipmentFilterOption)?.id,
		(filtersValues[FilterKeys.EquipmentLocation] as EquipmentFilterOption)
			?.name,
		filtersValues?.firstDay
			? parseMomentDateToUTCString(filtersValues.firstDay)
			: null,
		filtersValues?.lastDay
			? parseMomentDateToUTCString(filtersValues.lastDay)
			: null,
		debouncedSearch
	);

	const isMobile = useMediaQuery(theme.breakpoints.down(800));

	const { knowledgeBase } = useKnowledgeBase();

	const [isMobileFiltersOpen, setIsMobileFiltersOpen] = useState(false);

	const mobileFiltersCardRef = useRef(null);

	useEffect(() => {
		setFilters(filtersValues);
	}, [filtersValues]);

	useEffect(() => {
		setIsMobileFiltersOpen(false);
	}, [isMobile]);

	const handleAvailabilityFilter = useCallback(
		({ target: { value } }: Target) =>
			handleFilterChanges(FilterKeys.Availability)(value),
		[]
	);

	const toggleIsMobileFilterOpen = useCallback(() => {
		setIsMobileFiltersOpen((prevState) => !prevState);
	}, [isMobileFiltersOpen]);

	const closeIsMobileFilterOpen = useCallback(
		() => setIsMobileFiltersOpen(false),
		[]
	);

	const handleExportFileChange = useCallback((value: string) => {
		setExportFileType(value);
	}, []);

	const handleExportFile = useCallback(() => {
		getFile();
	}, []);

	const clearAvailabilityFilter = useCallback(() => {
		if (filtersValues.availability) {
			removeFilter(FilterKeys.Availability);
		}
	}, [filtersValues.availability]);

	const typesValues = (
		filtersValues?.equipmentType?.name
			? filtersValues.equipmentType
			: initialEquipmentTypeFilterValue
	) as EquipmentFilterOption;

	const autocompleteSelectFields = useMemo(
		() => [
			{
				value: filtersValues?.user?.name
					? filtersValues.user
					: initialUserFilterValue,
				options: getUsersOptions(filtersOptions?.users),
				setValue: handleFilterChanges(FilterKeys.User),
				label: 'User search',
				valueToClean: initialUserFilterValue,
			},
			{
				value: typesValues.description
					? swapNameAndDescription(typesValues)
					: typesValues,
				options: swapKnowledgees(
					getKnowledgeBaseOptions(
						knowledgeBase?.EquipmentType,
						filtersOptions?.equipmentTypes
					)
				),
				setValue: handleFilterChanges(FilterKeys.EquipmentType),
				label: 'Equipment type',
				valueToClean: initialEquipmentTypeFilterValue,
			},
			{
				value: filtersValues?.approver?.name
					? filtersValues.approver
					: initialApproversFilterValue,
				options: getApproversOptions(filtersOptions?.approvers),
				setValue: handleFilterChanges(FilterKeys.Approver),
				label: 'Approver',
				valueToClean: initialApproversFilterValue,
			},
			{
				value: filtersValues?.equipmentLocation?.name
					? filtersValues.equipmentLocation
					: initialEquipmentLocationFilterValue,
				options: getKnowledgeBaseOptions(
					knowledgeBase?.EquipmentLocationType,
					filtersOptions?.equipmentLocations
				),
				setValue: handleFilterChanges(FilterKeys.EquipmentLocation),
				label: 'Location',
				valueToClean: initialEquipmentLocationFilterValue,
			},
		],
		[filtersValues, knowledgeBase, filtersOptions, isMobile]
	);

	const filtersData = {
		filtersValues,
		filtersOptions,
		autocompleteSelectFields,
		isMobile,
		clearAvailabilityFilter,
		resetAllFilters,
		removeFilter,
		handleAvailabilityFilter,
		handleFilterChanges,
	} as FiltersProps;

	const exportData = {
		exportFileType,
		isMobile,
		handleExportFile,
		handleExportFileChange,
	};

	if (isMobile) {
		return (
			<Wrapper>
				<ClickAwayListener onClickAway={closeIsMobileFilterOpen}>
					<StyledBadge
						badgeContent={
							!isMobileFiltersOpen ? Object.keys(filters).length : null
						}
						color="primary"
					>
						<StyledCard ref={mobileFiltersCardRef}>
							<CardHeader
								title="Filters"
								action={
									<IconButton
										onClick={toggleIsMobileFilterOpen}
										size="small"
										aria-label="expand"
									>
										<ExpandMoreIcon />
									</IconButton>
								}
							/>

							<Collapse in={isMobileFiltersOpen} timeout="auto" unmountOnExit>
								<Scrollbar maxHeight="70vh" maxWidth="fit-content" scroll>
									<CardContent>
										<Filters {...filtersData} />
									</CardContent>
								</Scrollbar>
							</Collapse>
						</StyledCard>
					</StyledBadge>
				</ClickAwayListener>
				<ExportControls {...exportData} />
			</Wrapper>
		);
	}

	return (
		<Wrapper direction="row">
			<Filters {...filtersData} />
			<ExportControls {...exportData} />
		</Wrapper>
	);
}
