import { Photo } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';

import { useGetFile } from '@/queries/useGetFile';

import type { AvatarProps } from './types';

export function Avatar({ fileId, color, ...otherProps }: AvatarProps) {
	const theme = useTheme();
	const { data, isFetching } = useGetFile(fileId);
	const source = data ? `data:image/jpeg;base64,${data}` : undefined;

	return (
		<Photo
			src={isFetching ? undefined : source}
			color={theme.palette.secondary.contrastText}
			bgColor={color || '#D9D9D9'}
			{...otherProps}
		/>
	);
}
