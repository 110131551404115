import { Box, styled } from '@mui/material';

import { getFiltersStyles } from '@/components/Equipment/EquipmentControls/styles';
import type { ThemeWithMobileProps } from '@/components/Equipment/EquipmentControls/types';
import { checkIfForwardProps } from '@/helpers/checkIfForwardProps';

export const ExportControlsWrapper = styled(Box, {
	shouldForwardProp: checkIfForwardProps('isMobile'),
})<ThemeWithMobileProps>(({ theme, isMobile }) => ({
	display: 'flex',
	justifyContent: 'flex-start',
	flexDirection: isMobile ? 'column' : 'row',
	rowGap: theme.spacing(1.25),
	columnGap: theme.spaces.s,
	...getFiltersStyles(theme, isMobile),
}));
