import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Fragment, useEffect, useRef, useState } from 'react';

import {
	BoxForBullet,
	BoxForDepartmentIcon,
	EditButton,
	ImgDepartment,
	ListItemButtonStyled,
	ListItemIconStyled,
	MaterialTableCellEditButton,
	MaterialTableCellStyledFirst,
	MaterialTableRowStyled,
	StackRow,
	TypographyFirstCell,
} from '../styles';
import { TableCell } from '../TableCell';
import { TableRow } from '../types';

import { EditIcon } from '@/assets/buttons/EditIcon';
import { ArrowIcon } from '@/assets/editing/Arrow';
import { Bullet } from '@/components/Bullet';
import { ClientProjects } from '@/components/Table/components/ClientProjects';
import { CustomWidthTooltip } from '@/components/Table/components/CustomWidthTooltip';
import {
	ActivityStatus,
	firstColumnWidth,
	PageTitles,
	isNotChiefRole,
	Roles,
} from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { DEPARTMENT_IMAGES } from '@/helpers/getDepartmentsImage';

import type { TableRowContentProps } from './types';

export function TableRowContent({
	row,
	other,
	isRowBlocked,
	labelId,
	title,
	extendAvatar,
	extendImg,
	handleEdit,
	handleSetOpen,
	currentId,
	onOpen,
	isEquipment,
}: TableRowContentProps) {
	const firstColumnRef = useRef<HTMLDivElement | null>(null);
	const { role } = useAuth();
	const [isTooltip, setIstooltip] = useState(false);
	const isPencilVisible = isNotChiefRole(role as Roles)
		? !isRowBlocked && (row as TableRow).status !== ActivityStatus.Inactive
		: true;

	useEffect(() => {
		if (firstColumnRef.current) {
			setIstooltip(
				firstColumnRef.current.clientWidth !==
					firstColumnRef.current.scrollWidth
			);
		}
	}, [(row as TableRow)?.name?.name?.length]);

	return (
		<Fragment key={row.id}>
			<MaterialTableRowStyled
				hover={!isRowBlocked}
				role="checkbox"
				tabIndex={-1}
			>
				{title === PageTitles.Clients ? (
					<MaterialTableCellStyledFirst
						component="th"
						id={labelId}
						scope="row"
						row={row}
						blockedCell={isRowBlocked}
						active={(row as TableRow).status}
					>
						<CustomWidthTooltip
							title={isTooltip ? (row as TableRow)?.name.name : ''}
							currentWidth={
								(firstColumnWidth[title] as number) || ('auto' as string)
							}
						>
							<Stack>
								<ListItemButtonStyled
									onClick={handleSetOpen(row?.id as string, isRowBlocked)}
									sx={{ pointerEvents: !isRowBlocked ? 'auto' : 'none' }}
								>
									<StackRow gap={1}>
										<Bullet
											diameter={22}
											color={(row as TableRow).name?.color}
										/>
										<TypographyFirstCell
											ref={firstColumnRef}
											Component="p"
											variant="m-500"
										>
											{(row as TableRow)?.name.name}
										</TypographyFirstCell>

										<ListItemIconStyled>
											{currentId.includes(row?.id as string) ? (
												<ArrowIcon />
											) : (
												<ArrowIcon sx={{ transform: 'rotate(180deg)' }} />
											)}
										</ListItemIconStyled>
									</StackRow>
								</ListItemButtonStyled>
							</Stack>
						</CustomWidthTooltip>
					</MaterialTableCellStyledFirst>
				) : (
					<MaterialTableCellStyledFirst
						component="th"
						id={labelId}
						scope="row"
						hover={!isRowBlocked}
						blockedCell={isRowBlocked}
						active={(row as TableRow).status}
					>
						{extendAvatar && (
							<CustomWidthTooltip
								title={isTooltip ? (row as TableRow)?.name.name : ''}
								currentWidth={(firstColumnWidth[title] as number) || 'auto'}
							>
								<BoxForBullet>
									<Bullet diameter={22} color={(row as TableRow).name?.color} />
									<TypographyFirstCell
										ref={firstColumnRef}
										Component="p"
										variant="m-500"
									>
										{(row as TableRow)?.name.name}
									</TypographyFirstCell>
								</BoxForBullet>
							</CustomWidthTooltip>
						)}
						{extendImg && (
							<Box display="flex" flexDirection="row" alignItems="center">
								<BoxForDepartmentIcon>
									<ImgDepartment
										src={
											DEPARTMENT_IMAGES[(row as TableRow).name.toLowerCase()]
										}
										alt={(row as TableRow).name.name}
									/>
								</BoxForDepartmentIcon>
								<TypographyFirstCell variant="m-500">
									{row.description}
								</TypographyFirstCell>
							</Box>
						)}
						{!extendAvatar && !extendImg && (
							<TypographyFirstCell variant="m-500">
								{(row as TableRow)?.name.name}
							</TypographyFirstCell>
						)}
					</MaterialTableCellStyledFirst>
				)}
				<TableCell
					isEquipment={isEquipment}
					cells={other}
					row={row as TableRow}
					blockedCell={isRowBlocked}
				/>
				{onOpen && isPencilVisible && (
					<MaterialTableCellEditButton isEquipment={isEquipment}>
						<EditButton
							onClick={handleEdit(row.id as string)}
							color="secondary"
						>
							<EditIcon />
						</EditButton>
					</MaterialTableCellEditButton>
				)}
			</MaterialTableRowStyled>
			{currentId.includes(row?.id as string) && (
				<ClientProjects
					clientId={row?.id as string}
					isOpen={currentId.includes(row?.id as string)}
					currentId={currentId}
				/>
			)}
		</Fragment>
	);
}
