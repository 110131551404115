import Box from '@mui/material/Box';
import MaterialTableHead from '@mui/material/TableHead';
import MaterialTableRow from '@mui/material/TableRow';
import { MouseEvent } from 'react';
import { useLocation } from 'react-router-dom';

import {
	MaterialTableCellStyledHead,
	MaterialTableSortLabelStyled,
} from '@/components/Table/styles';
import { firstColumnWidth, SortDirection } from '@/constants/index';

import type { TableHeadProps } from './types';

export function TableHead({
	order,
	orderBy,
	onRequestSort,
	headCells,
}: TableHeadProps) {
	const createSortHandler =
		(property: string) => (event: MouseEvent<HTMLSpanElement>) => {
			onRequestSort(event, property);
		};
	const location = useLocation();

	return (
		<MaterialTableHead>
			<MaterialTableRow>
				{headCells.map(
					({ id, numeric, disablePadding, label, disableSort }) => {
						const currentSort = Boolean(orderBy === id);

						return (
							<MaterialTableCellStyledHead
								key={id}
								align={numeric ? 'right' : 'left'}
								padding={disablePadding ? 'none' : 'normal'}
								sortDirection={currentSort ? order : false}
								colSpan={headCells[headCells.length - 1].id === id ? 2 : 1}
								width={firstColumnWidth[location.pathname.slice(1)]}
							>
								{!disableSort ? (
									<MaterialTableSortLabelStyled
										active={currentSort}
										direction={
											(currentSort ? order : SortDirection.ASC) as
												| 'asc'
												| 'desc'
												| undefined
										}
										onClick={createSortHandler(id)}
									>
										{label}
									</MaterialTableSortLabelStyled>
								) : (
									<Box>{label}</Box>
								)}
							</MaterialTableCellStyledHead>
						);
					}
				)}
			</MaterialTableRow>
		</MaterialTableHead>
	);
}
