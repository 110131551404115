import { SvgIconProps, createSvgIcon } from '@mui/material';

const SignOut = createSvgIcon(
	<path
		d="M1 6.9375V3C1 1.89543 1.89543 1 3 1H18C19.1046 1 20 1.89543 20 3V18C20 19.1046 19.1046 20 18 20H3C1.89543 20 1 19.1046 1 18V14.0625M1 10.5H12.875M12.875 10.5L8.125 5.75M12.875 10.5L8.125 15.25"
		strokeWidth="1.5"
		strokeLinecap="round"
		stroke="currentColor"
	/>,
	'Sign out'
);

export function SignOutIcon({ sx, ...props }: SvgIconProps) {
	return (
		<SignOut
			sx={{ width: 21, height: 21, fill: 'none', ...sx }}
			viewBox="0 0 21 21"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
