import { Scrollbar } from '@mms/mms-ui-library';
import { styled } from '@mui/material';
import { Form } from 'formik';

export const FormContainer = styled(Scrollbar)(() => ({
	display: 'flex',
	flexDirection: 'column',
	overflow: 'auto',
}));

export const FormContent = styled(Form)(({ theme }) => ({
	display: 'grid',
	flexWrap: 'wrap',
	rowGap: theme.spaces.l,
	columnGap: 10.5,

	gridTemplateColumns: '350px 350px 171px',
	gridTemplateAreas: `
    'name name name'
    'type serialNumber purchaseDate'
    'purchasePrice price guaranteeDate'
    'purchasePlace approver location'
    'characteristics characteristics characteristics'
    'comment comment comment'
  `,

	'div:nth-child(1)': {
		gridArea: 'name',
	},

	'div:nth-child(2)': {
		gridArea: 'type',
	},

	'div:nth-child(3)': {
		gridArea: 'serialNumber',
	},

	'div:nth-child(4)': {
		gridArea: 'purchaseDate',
	},

	'div:nth-child(5)': {
		gridArea: 'purchasePrice',
	},

	'div:nth-child(6)': {
		gridArea: 'price',
	},

	'div:nth-child(7)': {
		gridArea: 'guaranteeDate',
	},

	'div:nth-child(8)': {
		gridArea: 'purchasePlace',
	},

	'div:nth-child(9)': {
		gridArea: 'approver',
	},

	'div:nth-child(10)': {
		gridArea: 'location',
	},

	'div:nth-child(11)': {
		gridArea: 'characteristics',
	},

	'div:nth-child(12)': {
		gridArea: 'comment',
	},

	// Could be removed after replacing MIUI SelectField to Autocomplete from MMS UI Library
	'div:nth-of-type(2), div:nth-of-type(0),div:nth-of-type(10)': {
		'label[data-shrink=false]': { top: -7 },
	},

	[theme.breakpoints.down('lg')]: {
		gridTemplateColumns: '250px 250px 171px',
	},
	[theme.breakpoints.down(900)]: {
		gridTemplateColumns: '200px 200px 171px',
	},
	[theme.breakpoints.down(750)]: {
		gridTemplateColumns: '171px 171px 171px',
	},

	[theme.breakpoints.down(700)]: {
		gridTemplateColumns: `calc(100vw - ${theme.spaces.xl4 * 2}px)`,
		gridTemplateAreas: `
		'name'
		'type'
		'serialNumber'
		'purchaseDate'
		'purchasePrice'
		'price'
		'guaranteeDate'
		'purchasePlace'
		'approver'
		'location'
		'characteristics'
		'comment'
		'footer'
	  `,
	},
	[theme.breakpoints.down(600)]: {
		gridTemplateColumns: `calc(100vw - ${theme.spaces.xl8}px)`,
	},

	[theme.breakpoints.down(500)]: {
		gridTemplateColumns: `calc(100vw - ${theme.spaces.xl6}px)`,
	},
}));

export const Wrapper = styled('div')(({ theme }) => ({
	width: 892,
	backgroundColor: theme.palette['exc-mainPage-primary-1'],
	display: 'flex',
	gap: 20,

	[theme.breakpoints.down('lg')]: {
		width: 620,
	},
	[theme.breakpoints.down(900)]: {
		width: 520,
	},
	[theme.breakpoints.down(600)]: {
		width: 450,
	},
	[theme.breakpoints.down(390)]: {
		flexDirection: 'column',
		width: 325,
	},
}));
