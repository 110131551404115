import { memo } from 'react';

import { Directions } from '@/constants/directions';

import {
	ControlsWrapper,
	StyledArrowDownIcon,
	StyledArrowUpIcon,
	StyledIconButton,
	Value,
	Wrapper,
} from './styles';
import type { NumberInputProps } from './types';

function NumberInputInner({ value, onChange }: NumberInputProps) {
	return (
		<Wrapper direction="row">
			<Value variant="m-400">{value}</Value>
			<ControlsWrapper>
				<StyledIconButton
					aria-label="increase"
					onClick={onChange(Directions.Up)}
				>
					<StyledArrowUpIcon />
				</StyledIconButton>
				<StyledIconButton
					aria-label="decrease"
					onClick={onChange(Directions.Down)}
				>
					<StyledArrowDownIcon />
				</StyledIconButton>
			</ControlsWrapper>
		</Wrapper>
	);
}

export const NumberInput = memo(NumberInputInner);
