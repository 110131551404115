export function stringToColor(string: string) {
	let hash = 0;
	let i;

	for (i = 0; i < string.length; i += 1) {
		hash = string.charCodeAt(i) + (hash * 32 - hash);
	}

	let color = '#';

	for (i = 0; i < 3; i += 1) {
		const value = hash * (i * 8) + 0xffffff;
		color += `00${value.toString(16)}`.slice(-2);
	}

	return color;
}

export function stringAvatar(name: string) {
	return {
		sx: {
			bgcolor: stringToColor(name),
		},
		children: `${name.split(' ')[0][0] || ''}${name.split(' ')[1][0] || ''}`,
	};
}

export const getFullName = (firstName: string, lastName: string) =>
	`${lastName} ${firstName}`;

export const formatFullName = (lastName: string, firstName: string) =>
	getFullName(lastName ?? '-', firstName ?? '');
