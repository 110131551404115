import { Button } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';

import { PlusCircleIcon } from '@/assets/buttons/PlusCircleIcon';

import type { ButtonAddProps } from './types';

export function ButtonAdd({ onAdd, ...props }: ButtonAddProps) {
	const theme = useTheme();

	return (
		<Button
			{...props}
			size="small"
			fullWidth={false}
			onClick={onAdd}
			icon={<PlusCircleIcon color={theme.palette.buttons.main} />}
		>
			Add
		</Button>
	);
}
