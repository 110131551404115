import { Typography } from '@mms/mms-ui-library';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import { WarningDialogContentProps } from './types';

export const WarningDialogTitle = styled(DialogTitle)(({ theme }) => ({
	textTransform: 'uppercase',
	fontWeight: 700,
	fontSize: theme.typography.pxToRem(16),
	lineHeight: 1.9,
	color: theme.palette.warning.main,
	backgroundColor: theme.palette.warning.backgroundColor,
}));

export const WarningDialogContent = styled(
	Typography
)<WarningDialogContentProps>(({ theme, bold }) => ({
	padding: theme.spacing(2, 4.6, 2),
	fontWeight: theme.typography.fontWeightMedium,
	lineHeight: 1.3,
	color: theme.palette.primary.main,
	width: 320,
	textAlign: 'center',
	...(bold && {
		fontWeight: 700,
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(0),
	}),

	[theme.breakpoints.down(720)]: {
		width: '100%',
	},
}));
