import { Autoselect } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';
import { isEqual } from 'lodash';
import moment from 'moment';
import { useContext, useMemo, memo } from 'react';

import { ProtectedView } from '@/components/ProtectedView';
import { Roles } from '@/constants/index';
import { useAuth, BookingContext } from '@/context/index';
import { BookingControls, BookingTime } from '@/pages/Booking/components';
import { useUsersLookup } from '@/queries/useUsersLookup';

import { LeftBlockWrapper } from './styled';
import { getEmployeeOptions } from './utils';

const AutoselectMemoized = memo(Autoselect, isEqual);
const { CEO, CTO, HD, SE } = Roles;

export function BookingManagement() {
	const { firstDay, lastDay, employee, setEmployee, initialEmployee } =
		useContext(BookingContext);
	const { rightPosition, role } = useAuth();
	const theme = useTheme();
	const { data, isFetching } = useUsersLookup('', [], { enabled: role !== SE });
	const employeeOptions = useMemo(
		() => getEmployeeOptions(data, isFetching),
		[data, isFetching]
	);
	const isSameDate = moment(firstDay).isSame(lastDay, 'day');
	const label =
		initialEmployee?.id === employee?.id
			? rightPosition
			: employee?.positionDescription || '';

	return (
		<LeftBlockWrapper spacing={2}>
			<BookingTime>
				<ProtectedView roles={[CEO, CTO, HD]}>
					<AutoselectMemoized
						label={label}
						value={employee && employee.name ? employee : initialEmployee}
						valueField="name"
						options={employeeOptions}
						onChange={setEmployee as any}
						valueToClean={initialEmployee}
						labelAndErrorBgColor={theme.palette['primary-1']}
					/>
				</ProtectedView>
			</BookingTime>

			<BookingControls isControlDisabled={!isSameDate} />
		</LeftBlockWrapper>
	);
}
