import moment from 'moment';
import {
	createContext,
	PropsWithChildren,
	useReducer,
	useMemo,
	useCallback,
} from 'react';

import { getFullName } from '@/helpers/index';
import { Workplace } from '@/types/Booking';

import { useAuth } from './AuthContext';
import type {
	Action,
	BookingContextInitialValues,
	BookingContextValues,
	Payload,
} from './types';

const BookingContext = createContext({} as BookingContextValues);

const initialState: BookingContextInitialValues = {
	selected: 0,
	firstDay: moment(),
	lastDay: moment(),
	employee: null,
	initialEmployee: null,
	error: null,
	highlightedDays: {
		bookedList: [],
		yourList: [],
		blockedList: [],
	},
	workplaces: [],
};

const {
	SET_SELECTED,
	SET_FIRST_DAY,
	SET_LAST_DAY,
	SET_EMPLOYEE,
	SET_HIGHLIGHTED_DAYS,
	SET_WORKPLACES,
	SET_ERROR,
	INIT,
} = {
	SET_SELECTED: 'SET_SELECTED',
	SET_FIRST_DAY: 'SET_FIRST_DAY',
	SET_LAST_DAY: 'SET_LAST_DAY',
	SET_EMPLOYEE: 'SET_EMPLOYEE',
	SET_HIGHLIGHTED_DAYS: 'SET_HIGHLIGHTED_DAYS',
	SET_WORKPLACES: 'SET_WORKPLACES',
	SET_ERROR: 'SET_ERROR',
	INIT: 'INIT',
};

const reducer = (state: BookingContextValues, { type, payload }: Action) => {
	switch (type) {
		case SET_SELECTED:
			return { ...state, selected: payload };
		case SET_FIRST_DAY:
			return { ...state, firstDay: payload };
		case SET_LAST_DAY:
			return { ...state, lastDay: payload };
		case SET_EMPLOYEE:
			return { ...state, employee: payload };
		case SET_HIGHLIGHTED_DAYS:
			return {
				...state,
				highlightedDays: payload,
			};
		case SET_WORKPLACES:
			return {
				...state,
				workplaces: payload,
			};
		case SET_ERROR:
			return {
				...state,
				error: payload,
				selected: 0,
			};

		case INIT:
			return {
				...state,
				selected: 0,
				employee: state.initialEmployee,
			};
		default:
			return state;
	}
};

function BookingProvider({ children }: PropsWithChildren) {
	const { id, firstName, lastName } = useAuth();
	const initialEmployeeValue = useMemo(() => {
		if (!id || !firstName || !lastName) {
			return null;
		}

		return {
			id,
			name: getFullName(firstName, lastName),
		};
	}, [id, firstName, lastName]);
	const countInitializer = (state: BookingContextValues) => ({
		...state,
		employee: initialEmployeeValue,
		initialEmployee: initialEmployeeValue,
	});

	const [
		{
			selected,
			firstDay,
			lastDay,
			employee,
			highlightedDays,
			workplaces,
			initialEmployee,
			error,
		},
		dispatch,
	] = useReducer(reducer, initialState, countInitializer as any);

	const callback = useCallback(
		(type: string) => (payload: Payload) => dispatch({ type, payload }),
		[]
	);

	const selectedWorkplace = useMemo(
		() =>
			(workplaces as Array<Workplace>).find(
				(place: { workplaceNumber: number }) =>
					place.workplaceNumber === selected
			),
		[workplaces, selected]
	);

	const value = useMemo(
		() =>
			({
				selected,
				firstDay,
				lastDay,
				employee,
				initialEmployee,
				highlightedDays,
				workplaces,
				error,
				selectedWorkplace,
				setSelected: callback(SET_SELECTED),
				setFirstDay: callback(SET_FIRST_DAY),
				setLastDay: callback(SET_LAST_DAY),
				setEmployee: callback(SET_EMPLOYEE),
				setHighlightedDays: callback(SET_HIGHLIGHTED_DAYS),
				setWorkplaces: callback(SET_WORKPLACES),
				setError: callback(SET_ERROR),
				init: callback(INIT),
			} as unknown as BookingContextValues),
		[selected, firstDay, lastDay, employee, highlightedDays, workplaces, error]
	);

	return (
		<BookingContext.Provider value={value}>{children}</BookingContext.Provider>
	);
}

export { BookingProvider, BookingContext };
