import axios from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';

import { GET_KNOWLEDGE_BASE } from '@/constants/queryPaths';
import { KnowledgeBase } from '@/types/KnowledgeBase';

export const useGetKnowledgeBase = (options: UseQueryOptions<KnowledgeBase>) =>
	useQuery<KnowledgeBase>({
		queryKey: ['knowledge-base'],
		...options,
		queryFn: () => axios.get(GET_KNOWLEDGE_BASE).then((res) => res.data),
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
