export const ROOT_STORAGE_KEY = '@modsen/reports/';

export const AUTH_TOKEN_STORAGE_KEY = `${ROOT_STORAGE_KEY}auth`;
export const ROLE_STORAGE_KEY = `${ROOT_STORAGE_KEY}role`;
export const MICROSOFT_TOKEN_STORAGE_KEY = `${ROOT_STORAGE_KEY}token`;
export const USER_INFO_STORAGE_KEY = `${ROOT_STORAGE_KEY}userInfo`;
export const THEME_SCHEME_STORAGE_KEY = `${ROOT_STORAGE_KEY}theme`;
export const DB_DATE_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}DBDateFilter`;
export const DB_ROLE_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}DBRoleFilter`;
export const TS_DATE_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}TSDateFilter`;
export const TS_CLIENT_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}TSClientFilter`;
export const TS_PROJECT_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}TSProjectFilter`;
export const TS_ROLE_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}TSRoleFilter`;
export const TS_PERIOD_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}TSPeriodFilter`;
export const TS_USER_FILTER_STORAGE_KEY = `${ROOT_STORAGE_KEY}TSUserFilter`;
export const SIDEBAR_SELECTED_ITEM_STORAGE_KEY = `${ROOT_STORAGE_KEY}SidebarSelectedItem`;
