import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { toast } from 'react-toastify';

import { ToastContextValues } from './types';

const ToastContext = createContext({} as ToastContextValues);

export function ToastProvider({ children }: PropsWithChildren) {
	const contextValue = useMemo<ToastContextValues>(
		() => ({
			open: ({ message, settings }) => {
				const { type, ...rest } = settings;
				toast(message, {
					...rest,
				});
			},
		}),
		[]
	);

	return <ToastContext.Provider value={contextValue} children={children} />;
}

export const useToast = () => useContext(ToastContext);
