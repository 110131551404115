import { Typography } from '@mms/mms-ui-library';

import { NO_DATA_EQUIPMENT } from '@/constants/errors';

import { EquipmentHistoryItemMemo } from './HistoryItem';
import {
	HistoryPositionContainer,
	HistoryTypography,
	HistoryWrapper,
	List,
} from './styles';
import type { EquipmentHistoryProps } from './types';

export function EquipmentHistory({
	historyItems,
	equipmentId,
	refetch,
	setHistoryItems,
}: EquipmentHistoryProps) {
	return (
		<HistoryPositionContainer>
			<HistoryWrapper>
				<HistoryTypography Component="h6" variant="m-600">
					History
				</HistoryTypography>
				{historyItems.length > 0 ? (
					<List maxHeight="90%" maxWidth="fit-content" scroll>
						{historyItems.map(
							(item) =>
								item && (
									<EquipmentHistoryItemMemo
										key={item?.id}
										{...item}
										{...{
											equipmentId,
											refetch,
											setHistoryItems,
										}}
									/>
								)
						)}
					</List>
				) : (
					<Typography variant="m-400">{NO_DATA_EQUIPMENT}</Typography>
				)}
			</HistoryWrapper>
		</HistoryPositionContainer>
	);
}
