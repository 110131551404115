import axios, { AxiosError } from 'axios';
import type { Moment } from 'moment';
import { useMutation, UseMutationOptions } from 'react-query';

import {
	GET_EQUIPMENT_API_ENDPOINT,
	NotificationType,
	EQUIPMENT_UPDATED,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { getDateAsResponseType } from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';
import type { EquipmentItemResponse } from '@/types/Equipment';

import type { UpdateMutationVariables } from './types';

export const useUpdateEquipment = (
	toast: ToastContextValues,
	options?: UseMutationOptions<
		EquipmentItemResponse,
		AxiosError,
		UpdateMutationVariables
	>
) =>
	useMutation({
		...options,
		mutationFn: async ({
			name,
			equipmentType,
			serialNumber,
			purchasePrice,
			purchaseCurrency,
			purchasePriceUsd,
			purchaseDate,
			purchasePlace,
			guaranteeDate,
			characteristics,
			approverId,
			location,
			comment,
			equipmentId,
			activeHolderRequest,
		}) => {
			try {
				const { data } = await axios.put(GET_EQUIPMENT_API_ENDPOINT, {
					id: equipmentId,
					name,
					equipmentType,
					serialNumber,
					purchasePrice: Number(purchasePrice),
					purchasePriceUsd,
					purchaseCurrency,
					purchaseDate: getDateAsResponseType(purchaseDate as Moment),
					purchasePlace,
					guaranteeDate: getDateAsResponseType(guaranteeDate as Moment),
					characteristics,
					approverId,
					location,
					comment,
					activeHolder: activeHolderRequest,
				});
				toast.open(
					createToast(NotificationType.SUCCESS, null, EQUIPMENT_UPDATED)
				);

				return data;
			} catch (error) {
				return [];
			}
		},
	});
