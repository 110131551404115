import { Moment } from 'moment';
import numeral from 'numeral';
import { useMemo } from 'react';

import { EQUIPMENT_HEAD_CELLS, NUMERAL_PRICE_FORMAT } from '@/constants/index';
import { formatDateString } from '@/helpers/dateToISOString';
import { formatFullName } from '@/helpers/index';
import { ByPageResponse } from '@/queries/types';
import { EquipmentItemResponse } from '@/types/Equipment';

export const useEquipmentsValues = (
	data: ByPageResponse<EquipmentItemResponse>,
	dataUpdatedAt: Moment | Date | number,
	page: number,
	rowsPerPage: number
) =>
	useMemo(
		() => ({
			headCells: EQUIPMENT_HEAD_CELLS,
			rows:
				data?.items?.map(
					({
						id,
						name,
						equipmentType,
						purchasePriceUsd,
						approver,
						comment,
						activeHolder,
						guaranteeDate,
						location,
						serialNumber,
						characteristics,
						purchaseDate,
						purchasePlace,
					}) => ({
						name: { name: id },
						id,
						serialNumber,
						equipmentName: name,
						equipmentType,
						purchasePriceUsd:
							numeral(purchasePriceUsd).format(NUMERAL_PRICE_FORMAT),
						user: formatFullName(
							activeHolder?.lastName as string,
							activeHolder?.firstName as string
						),
						department: activeHolder?.department ?? '-',
						characteristics,
						purchasePlace,
						approver: formatFullName(approver?.lastName, approver?.firstName),
						purchaseDate: formatDateString(purchaseDate),
						guaranteeDate: formatDateString(guaranteeDate),
						location,
						comment,
					})
				) || [],
		}),
		[dataUpdatedAt, page, rowsPerPage]
	);
