import { SvgIconProps, createSvgIcon } from '@mui/material';

const Icon = createSvgIcon(
	<path
		d="M4.61603 11.4952L1.73205 6.5L4.61603 1.50481H10.384L13.2679 6.5L10.384 11.4952H4.61603Z"
		stroke="#F17900"
		strokeWidth="3"
		fill="none"
	/>,
	'Modsen logo polygon'
);

export function IconPolygon({ ...props }: SvgIconProps) {
	return <Icon width={15} height={13} viewBox="0 0 15 13" {...props} />;
}
