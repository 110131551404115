import { UserMenuIcon } from '@mms/mms-ui-library';
import { useTheme } from '@mui/material';
import axios from 'axios';
import { MouseEvent, useState, memo } from 'react';

import { UserMenuDropdown } from '@/components/UserMenu/UserMenuDropdown';
import { GET_BOOKING_API_ENDPOINT } from '@/constants/queryPaths';
import { ClientResponse } from '@/types/Clients';

import { UserMenuWrapper, CustomUserMenu, UserMenuIconWrapper } from './styles';

function UserMenuInner() {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const theme = useTheme();

	const handleMenu = (event: MouseEvent<HTMLDivElement>) => {
		axios.get<ClientResponse>(GET_BOOKING_API_ENDPOINT);
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<UserMenuWrapper>
			<UserMenuIconWrapper onClick={handleMenu}>
				<UserMenuIcon />
			</UserMenuIconWrapper>
			{anchorEl && (
				<CustomUserMenu
					id="menu-appbar"
					anchorEl={anchorEl}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorEl)}
					onClose={handleClose}
					keepMounted
					style={{ marginTop: theme.spacing(6.5) }}
				>
					<UserMenuDropdown onClose={handleClose} />
				</CustomUserMenu>
			)}
		</UserMenuWrapper>
	);
}

export const UserMenu = memo(UserMenuInner);
