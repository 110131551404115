import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { SOMETHING_WENT_WRONG } from '@/constants/errors';
import {
	FILE_EXTENSION_MAPPING,
	EquipmentQueriesKeys,
	GET_EQUIPMENT_FILE,
} from '@/constants/index';
import { NotificationType } from '@/constants/notifications';
import { ToastContextValues } from '@/context/types';
import { capitalizeString } from '@/helpers/capitalizeString';
import { createToast } from '@/helpers/createToast';

export const useExportEquipment = (
	toast: ToastContextValues,
	format: string,
	orderByCondition: string,
	isAvailable: true | null = null,
	assignedToUserId: number | null = null,
	equipmentType: string | null = null,
	approverId: number | null = null,
	location: string | null = null,
	purchaseDateFrom: string | null = null,
	purchaseDateTo: string | null = null,
	searchPattern: string | null = null,
	page = 1,
	pageSize = 50
) =>
	useQuery({
		queryKey: EquipmentQueriesKeys.file,
		queryFn: async () => {
			try {
				const res = await axios.get(GET_EQUIPMENT_FILE, {
					headers: {
						Accept:
							'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						'Content-Disposition': 'attachment',
					},
					params: {
						format,
						Page: page,
						PageSize: pageSize,
						OrderBy: `${capitalizeString(orderByCondition)}`,
						IsAvailable: isAvailable,
						AssignedToUserId: assignedToUserId,
						EquipmentType: equipmentType,
						ApproverId: approverId,
						Location: location,
						PurchaseDateFrom: purchaseDateFrom,
						PurchaseDateTo: purchaseDateTo,
						SearchPattern: searchPattern,
					},
					responseType: 'blob',
					paramsSerializer: {
						indexes: true,
					},
				});

				const href = URL.createObjectURL(res.data);
				const link = document.createElement('a');
				link.href = href;
				link.setAttribute(
					'download',
					`Equipment.${
						FILE_EXTENSION_MAPPING[
							format as keyof typeof FILE_EXTENSION_MAPPING
						]
					}`
				);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(href);

				return await Promise.resolve();
			} catch (error: unknown) {
				if ((error as AxiosError)?.message) {
					toast.open(
						createToast(
							NotificationType.ERROR,
							null,
							(error as AxiosError).message || SOMETHING_WENT_WRONG
						)
					);
				}

				return Promise.reject(error);
			}
		},
		enabled: false,
		keepPreviousData: true,
		refetchOnWindowFocus: false,
	});
