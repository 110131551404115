import { LinearLoader, Header, MenuIcon } from '@mms/mms-ui-library';
import { useState, useCallback, Suspense, useRef, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { ArrowLeft } from '@/assets/mainMenu/arrowLeft';
import { Logo } from '@/components/Logo';
import { UserMenu } from '@/components/UserMenu';
import { Routes } from '@/constants/index';
import { useNavigateAfterLogin } from '@/pages/hooks/useNavigateAfterLogin';

import { LeftMenu } from './LeftMenu';
import {
	AppWrapper,
	BurgerIconWrapper,
	LoaderWrapper,
	MainContent,
	StyledScroll,
} from './styles';

export function Layout() {
	useNavigateAfterLogin();
	const [open, setOpen] = useState(false);

	const burgerButtonRef = useRef<HTMLDivElement>(null);

	const { pathname } = useLocation();

	const handleDrawerOpen = useCallback(() => {
		setOpen((prev) => !prev);
	}, []);

	const exclusionClickAwayElements = useMemo(
		() => (burgerButtonRef.current ? [burgerButtonRef.current] : []),
		[burgerButtonRef.current]
	);

	return (
		<AppWrapper>
			<>
				<Header
					isFixed
					logo={<Logo />}
					style={{ height: 54, zIndex: 50 }}
					navigation={
						<BurgerIconWrapper
							id="burger"
							ref={burgerButtonRef}
							aria-label="open drawer"
							onClick={handleDrawerOpen}
						>
							{open ? <ArrowLeft /> : <MenuIcon width={24} height={24} />}
						</BurgerIconWrapper>
					}
					userMenu={<UserMenu />}
				/>
				<LeftMenu
					exclusionClickAwayElements={exclusionClickAwayElements}
					setIsOpen={setOpen}
					isOpen={open}
				/>
			</>
			<StyledScroll
				maxHeight="100%"
				maxWidth="100%"
				scroll={
					pathname === Routes.dashboard ||
					pathname === Routes.reports ||
					pathname === Routes.updateProfile
						? true
						: 'x'
				}
			>
				<MainContent lowPriority highPriority>
					<Suspense
						fallback={
							<LoaderWrapper>
								<LinearLoader />
							</LoaderWrapper>
						}
					>
						<Outlet />
					</Suspense>
				</MainContent>
			</StyledScroll>
		</AppWrapper>
	);
}
