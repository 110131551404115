import { SvgIconProps, createSvgIcon } from '@mui/material';

const Remove = createSvgIcon(
	<path
		d="M9 1L1 9M1 1L9 9"
		stroke="currentColor"
		strokeWidth="2"
		strokeLinecap="round"
	/>,
	'Remove Icon'
);

export function RemoveIcon({ ...props }: SvgIconProps) {
	return <Remove width={10} height={10} viewBox="0 0 10 10" {...props} />;
}
