import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import {
	SOMETHING_WENT_WRONG,
	UsersQueriesKeys,
	NotificationType,
	GET_USERS_API_ENDPOINT,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import { LookupUserResponse } from '@/types/UserInfo';

export const useAllUsersLookup = (toast: ToastContextValues) =>
	useQuery<Array<LookupUserResponse>, AxiosError>({
		queryKey: UsersQueriesKeys.allUsers,
		queryFn: async () => {
			try {
				const { data } = await axios.get(`${GET_USERS_API_ENDPOINT}/lookup`);

				return data;
			} catch (error: unknown) {
				if ((error as AxiosError)?.message) {
					toast.open(
						createToast(
							NotificationType.ERROR,
							null,
							(error as AxiosError).message || SOMETHING_WENT_WRONG
						)
					);
				}

				return Promise.reject();
			}
		},
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		retry: false,
	});
