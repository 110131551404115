import { Scrollbar, Typography } from '@mms/mms-ui-library';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import MaterialTable from '@mui/material/Table';
import MaterialTableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import MaterialTableRow from '@mui/material/TableRow';
import MaterialTableSortLabel from '@mui/material/TableSortLabel';

import { ActivityStatus } from '@/constants/index';

import type {
	MaterialTableCellStyledFirstProps,
	TypographyTooltipProps,
	MaterialTableRowStyledProps,
	StyledPaperProps,
	StyledTableContainerProps,
	MaterialTableCellEditButtonProps,
} from './types';

const calculateMaxHeight = (
	height: number | undefined,
	withPagination: boolean,
	percentage: string
) => {
	if (height) {
		return { maxHeight: `${height - 55}px` };
	}

	return { maxHeight: `${withPagination ? percentage : '100%'}` };
};

export const TablePaginationWrapper = styled('div')(({ theme }) => ({
	marginTop: theme.spaces.l,
}));

export const StyledPaper = styled(Paper)<StyledPaperProps>(
	({ theme, height }) => ({
		width: '100%',
		height: height ? `${height}px` : '100%',
		overflow: 'hidden',
		marginBottom: 2,
		padding: height ? theme.spacing(0, 0, 0, 2) : theme.spacing(0, 0, 2, 2),
		boxShadow: theme.customShadows[5],
		borderRadius: theme.spacing(0.75),
		background: theme.palette.form.background,
	})
);

export const StyledScrollbar = styled(Scrollbar)<StyledTableContainerProps>(
	({ withPagination, height }) => ({
		...calculateMaxHeight(height, withPagination, '93%'),
	})
);

export const BoxFullWidth = styled(Box)(({ theme, height }) => ({
	width: '100%',
	height: height ? `${height}px` : '93%',
}));

export const StackRow = styled(Stack)({
	flexDirection: 'row',
	width: '100%',
	alignItems: 'center',
});

export const BoxForBullet = styled(Stack)(({ theme }) => ({
	flexDirection: 'row',
	alignItems: 'center',
	gap: theme.spacing(1.25),
	padding: theme.spacing(0.4, 0),
}));

export const BoxForDepartmentIcon = styled(Stack)(({ theme }) => ({
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	borderRadius: '50%',
	height: 30,
	width: 30,
	margin: theme.spacing(0.5, 1, 0.5, 0.25),
	boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
	background: theme.palette.icons.iconBox,
}));

export const TypographyFirstCell = styled(Typography)(({ theme }) => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	width: '70%',
	color: (theme.palette as any)['base-6'],
}));

export const TypographyTooltip = styled(Typography)<TypographyTooltipProps>(
	({ theme, cellWidth }) => ({
		maxWidth: cellWidth || 'auto',
		minWidth: cellWidth || 'auto',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		paddingRight: theme.spacing(4),
	})
);

export const MaterialTableFullWidth = styled(MaterialTable)({
	maxWidth: '100%',
});

export const MaterialTableCellStyledFirst = styled(
	MaterialTableCell
)<MaterialTableCellStyledFirstProps>(({ theme, blockedCell, active }) => ({
	padding: 0,
	borderTop: `1px solid ${theme.palette.table.rowBorder}`,
	borderBottom: 'none',
	opacity: blockedCell || active === ActivityStatus.Inactive ? 0.5 : 'unset',
	maxWidth: 450,
	minWidth: 110,
}));

export const MaterialTableCellStyledMap = styled(
	MaterialTableCell
)<MaterialTableCellStyledFirstProps>(
	({ theme, blockedCell, cell, active, isEquipment }) => ({
		opacity: blockedCell || active === ActivityStatus.Inactive ? 0.5 : 'unset',
		borderTop: `1px solid ${theme.palette.table.main}`,
		borderBottom: 'none',
		fontSize: theme.typography.pxToRem(16),
		fontWeight: 400,
		padding: 0,
		height: isEquipment ? 33 : 25,
		paddingLeft: cell === 'isCommercial' ? theme.spacing(2.5) : 0,
		maxWidth: 400,
		minWidth: 100,
		[theme.breakpoints.up('xl')]: {
			width: cell === 'email' ? 'max-content' : 'unset',
			maxWidth: cell === 'email' ? 520 : 400,
		},
	})
);

export const MaterialTableCellStyledHead = styled(MaterialTableCell)(
	({ theme }) => ({
		background: theme.palette.tableCells.background,
		fontWeight: 500,
		fontSize: theme.typography.pxToRem(13),
		borderBottom: `1px solid ${theme.palette.table.secondary}`,
		color: theme.palette.table.headColor,
		padding: theme.spacing(1, 0),
		'.MuiTableSortLabel-icon': {
			width: 12,
			height: 12,
		},
	})
);

export const MaterialTableCellEditButton = styled(
	MaterialTableCell
)<MaterialTableCellEditButtonProps>(({ theme, isEquipment }) => ({
	position: isEquipment ? 'sticky' : 'static',
	right: isEquipment ? 0 : 'unset',
	backgroundColor: theme.palette.tableCells.background,
	padding: theme.spacing(0, 1, 0, 0),
	width: 20,
	borderTop: `1px solid ${theme.palette.table.main}`,
	borderBottom: 'none',
}));

export const MaterialTableRowStyled = styled(
	MaterialTableRow
)<MaterialTableRowStyledProps>(({ theme, blockedRow }) => ({
	position: 'relative',
	fontSize: theme.typography.pxToRem(16),
	opacity: blockedRow ? 0.5 : 'unset',
	borderTop: `1px solid ${theme.palette.table.rowBorder}`,
	'&.MuiTableRow-hover.MuiTableRow-root': {
		background: 'none',
	},
}));

export const MaterialTableSortLabelStyled = styled(MaterialTableSortLabel)(
	({ theme }) => ({
		'&.Mui-active': { color: theme.palette.table.headColor },
		'& .MuiTableSortLabel-icon': {
			color: `${theme.palette.table.headColor} !important`,
		},
	})
);

export const ListItemButtonStyled = styled(ListItemButton)(({ theme }) => ({
	cursor: 'pointer',
	padding: theme.spacing(0.5, 0),
	'&:hover': {
		background: 'none',
	},
}));

export const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
	paddingLeft: theme.spacing(1.25),
}));

export const EditButton = styled(Button)({
	position: 'relative',
	minWidth: 30,
	minHeight: 25,
	padding: 0,
	borderRadius: '50%',
});

export const ImgDepartment = styled('img')({
	height: '70%',
	width: 'auto',
});
