import {
	PropsWithChildren,
	createContext,
	useContext,
	useMemo,
	useState,
	useCallback,
	useEffect,
} from 'react';
import { Timeout } from 'react-number-format/types/types';
import { useNavigate, useLocation } from 'react-router-dom';

import {
	INACTIVE_LIMIT,
	LOGOUT_REASON,
	USER_ACTIVITY_EVENTS,
	USER_LAST_ACTIVITY_DATE,
	PATH_KEY,
	BROADCAST_CHANNEL_NAME,
} from '@/constants/authConstants';
import { Routes } from '@/constants/index';
import {
	getAuthState,
	setAuthState,
	removeAuthState,
	initialAuthState,
	isUserLoggedIn,
} from '@/helpers/localStorage';
import { useAxiosInterCeptors } from '@/hooks/useAxiosInterceptors';
import { useLogout } from '@/queries/useLogout';
import { useUserInfo } from '@/queries/useUserInfo';

import {
	AuthState,
	HandleReceivedToken,
	InitialAuthState,
	SessionBroadcastEvents,
	SessionBroadcastMessage,
} from './types';

// Variable containing the date of the last user activity
let lastUserActivityDate = Date.now();
// Function to notify the current and other tabs in the application that the user was active
function notifyCurrentAndOtherTabsUserWasActive() {
	// Update last activity time
	lastUserActivityDate = Date.now();

	// Record the time of last activity in localStorage
	localStorage.setItem(
		USER_LAST_ACTIVITY_DATE,
		lastUserActivityDate.toString()
	);
}

// Function to notify inactive tabs that it is necessary to logout
function logoutInOtherTabs(reason: string) {
	// Set the logout reason to localStorage
	localStorage.setItem(LOGOUT_REASON, reason);
}

// Function to logout on the current tab
function logoutInCurrentTab(reason: string, logoutFn: () => void) {
	// Call the logout function
	logoutFn();
}

// Variable that stores Timeout, which is responsible for the time of inactivity
let inactivityInterval: Timeout;

// Callback function to respond to changes in storage
function handleStorageEvent(event: StorageEvent) {
	if (event.key === LOGOUT_REASON) {
		// Call the global function for logout if there is a reason for this in localStorage
		window.logout();
	} else if (event.key === USER_LAST_ACTIVITY_DATE) {
		if (event.newValue) {
			lastUserActivityDate = +new Date(+event.newValue);
		}
	}
}

// Function to stop tracking user inactivity
function destroyLogout() {
	// Disable interval
	clearInterval(inactivityInterval);
	window.removeEventListener('storage', handleStorageEvent);
	USER_ACTIVITY_EVENTS.forEach((event) => {
		window.removeEventListener(event, notifyCurrentAndOtherTabsUserWasActive);
	});
}

/**
 * Initializing the logout system
 */
export function initLogout(logoutFn: () => void) {
	/**
	 * We call this function during initialization in order to handle the situation,
	 * when the user opens the page and does not interact with it.
	 * In this case, we still need to start tracking inactivity
	 */
	notifyCurrentAndOtherTabsUserWasActive();

	// Clear the logout reason
	localStorage.setItem(LOGOUT_REASON, '');

	// Add a logout method to the global window object to exit the application
	window.logout = (reason = 'UserInactivity') => {
		destroyLogout();
		logoutInOtherTabs(reason);
		logoutInCurrentTab(reason, logoutFn);
	};

	// Set the interval to track user inactivity
	inactivityInterval = setInterval(() => {
		if (Date.now() - lastUserActivityDate > INACTIVE_LIMIT) {
			window.logout('UserInactivity');
		}
	}, 1000);

	// Add a change listener to storage
	window.addEventListener('storage', handleStorageEvent);

	// Add listeners to all user activity events
	USER_ACTIVITY_EVENTS.forEach((event) => {
		window.addEventListener(event, notifyCurrentAndOtherTabsUserWasActive);
	});
}

const AuthContext = createContext<Omit<AuthState, 'loading'>>(initialAuthState);

function AuthProvider({ children }: PropsWithChildren) {
	const navigate = useNavigate();

	const { refetch: logoutRequest } = useLogout();
	const { state, pathname } = useLocation();
	const [authorization, setAuthorization] = useState(getAuthState());
	const [enabledRedirect, setEnabledRedirect] = useState(isUserLoggedIn());
	const { token } = authorization;

	const broadcastChannel = new BroadcastChannel(BROADCAST_CHANNEL_NAME);

	const handleReceivedToken = useCallback<HandleReceivedToken>(
		(newToken) => {
			const newState: InitialAuthState = {
				...authorization,
				token: newToken,
			};
			setAuthorization(newState);
			setAuthState(newState);

			setEnabledRedirect(true);
		},
		[authorization]
	);

	const onLogout = () => {
		removeAuthState();
		setAuthorization(initialAuthState);
		navigate(Routes.signIn);
		logoutInOtherTabs('logout-via-btn');
		destroyLogout();
		logoutRequest();
	};

	useAxiosInterCeptors(token, onLogout);

	const { data } = useUserInfo(setAuthState, {
		isChangedOnlyTimeZone: false,
		enabled: Boolean(token),
		onSuccess: () => {
			if (enabledRedirect) {
				let origin = state?.from?.pathname ?? pathname;

				if (origin === Routes.authCallback) {
					origin = Routes.home;
				}

				navigate(origin);
			}
		},
	});

	useEffect(() => {
		const handleSessionSharing = (event: MessageEvent) => {
			if (event.data.type === SessionBroadcastEvents.requestSession) {
				if (sessionStorage.length) {
					const message: SessionBroadcastMessage = {
						type: SessionBroadcastEvents.sendSession,
						data: JSON.stringify(sessionStorage),
					};

					broadcastChannel.postMessage(message);
				}
			}
		};

		broadcastChannel.addEventListener('message', handleSessionSharing);

		return () =>
			broadcastChannel.removeEventListener('message', handleSessionSharing);
	}, []);

	useEffect(() => {
		if (!sessionStorage.length) {
			broadcastChannel.postMessage({
				type: SessionBroadcastEvents.requestSession,
			});
		}

		const handleGetSession = (event: MessageEvent) => {
			const message: SessionBroadcastMessage = event.data;

			if (message.type === SessionBroadcastEvents.sendSession) {
				if (sessionStorage.length) {
					return;
				}

				const storageInfo = JSON.parse(message.data as string);
				Object.keys(storageInfo || []).forEach((key) =>
					sessionStorage.setItem(key, storageInfo[key])
				);

				const path = localStorage.getItem(PATH_KEY) as string;
				navigate(path || Routes.home);

				window.location.reload();
			}
		};

		broadcastChannel.addEventListener('message', handleGetSession, {
			once: true,
		});
	}, []);

	const value = useMemo(
		() => ({
			...authorization,
			role: data?.position ?? authorization.role,
			...data,
			department: data?.department ?? '0',
			handleReceivedToken,
			onLogout,
		}),
		[authorization, data]
	);
	useEffect(() => {
		if (token) {
			initLogout(onLogout);
		}

		return () => {
			destroyLogout();
		};
	}, [token]);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

/**
 * Hook to use AuthContext.
 * Includes data about current user, authorization events and loading status.
 */
const useAuth = () => useContext(AuthContext) as Required<AuthState>;

export { AuthProvider, AuthContext, useAuth };
