export const cellWidth = {
	userName: 200,
	email: 440,
	position: 240,
	department: 150,
	location: 150,
	notes: 590,
	clients: 300,
	tracked: {
		clients: 250,
		projects: 150,
	},
	purchasePrice: 130,
	issueDate: 130,
	returnDate: 130,
	purchasePlace: 340,
};

export const firstColumnWidth: Record<string, number> = {
	users: 100,
	clients: 300,
	projects: 320,
	departments: 300,
};
