import { SvgIconProps, createSvgIcon } from '@mui/material';

const NightIcon = createSvgIcon(
	<path
		d="M5.35319 2.22193C5.90788 1.85125 6.48465 1.54239 7.07569 1.29354C5.35966 4.9278 5.5166 9.35493 7.87516 12.9601C10.234 16.5657 14.2068 18.4493 18.1934 18.2779C17.7314 18.7358 17.2195 19.156 16.6592 19.5305C11.9386 22.6851 5.57955 21.3777 2.45404 16.6002C-0.674558 11.818 0.627766 5.3798 5.35319 2.22193Z"
		strokeWidth="1.5"
		stroke="currentColor"
	/>,
	'Moon'
);

export function NightMode({ sx, ...props }: SvgIconProps) {
	return (
		<NightIcon
			sx={{ width: 20, height: 22, fill: 'none', ...sx }}
			viewBox="0 0 20 22"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
