export const MORE_THEN_THREE = 'Must be more than three characters.';
export const NOT_VALID_EMAIL =
	'Enter a valid Email in the “example@modsen-software.com” format.';
export const NOT_VALID_COMMENT = 'Enter a valid Note.';
export const TOO_MUCH = 'Too much characters.';
export const FILLED_IN = 'Must be filled in.';
export const NOT_FOUND_MESSAGE = `404 - PAGE NOT FOUND`;
export const CLIENT_NOT_SELECTED = 'The Client field is required.';
export const NOT_ONLY_SPACES = 'Value must not contain only spaces.';
export const NO_DATA_FOUND = 'No data found.';
export const TOKEN_EXPIRED = 'Token has already expired!';
export const ACCESS_DENIED = 'Access denied.';
export const MODSEN_DOMAIN = 'Must be in modsen-software domain!';
export const MORE_THEN_TWO = 'Min. length 2 characters.';
export const NOT_VALID_FIRST_NAME = 'Enter a valid First Name.';
export const NOT_VALID_LAST_NAME = 'Enter a valid Last Name.';
export const NOT_ENTER_VALUE = 'Enter';
export const CHOOSE_PROJECT_FIRST = 'Select a project.';
export const FIELD_EDIT_FORBIDDEN = 'Field edit forbidden.';
export const FIRST_NEED_TRACK_HOURS =
	'First you need to track some hours for the last task.';
export const ADD_TIME_FORBIDDEN =
	'Add time is forbidden after 3rd day of the next month.';
export const PROJECT_NOT_MORE_AVAILABLE =
	'Project is not further available to you.';
export const PROJECT_UPDATED_BUT_NOT_AVAILABLE =
	'Project updated, but not further available to you.';
export const AXIOS_ERROR = 'AxiosError';
export const SELECT_DESK = 'Please, select a desk.';
export const SELECT_DATE = 'Please, select a date.';
export const BOOKING_ALREADY_EXISTS = 'Booking for this date already exists.';
export const SOMETHING_WENT_WRONG = 'Something went wrong.';
export const MORE_THEN_TWO_HUNDRED_AND_FIFTY = 'Max. length 250 characters.';
export const MORE_THEN_FORTY = 'Max. length 40 characters.';
export const MORE_THEN_FIFTY = 'Max. length 50 characters.';
export const LESS_THEN_FOUR = 'Min. length 4 characters.';
export const LESS_THEN_TWO = 'Min. length 2 characters.';
export const MORE_THEN_SIX_HUNDRED = 'Max. length 600 characters.';
export const MORE_THEN_HUNDRED = 'Max. length 100 characters.';
export const PROJECT_NAME_MUST_BE_FILLED_IN = 'Enter Project name.';
export const CLIENT_NAME_MUST_BE_FILLED_IN = 'Enter Client name.';
export const NOT_VALID_PROJECT_NAME = 'Enter a valid Project name.';
export const NOT_VALID_CLIENT_NAME = 'Enter a valid Client name.';
export const NOT_VALID_DATE = 'Enter a valid date.';
export const EMPTY_DATE = 'Enter the Date.';
export const EMAIL_MUST_BE_FILLED_IN = 'Enter Email.';
export const DEPARTMENT_MUST_BE_FILLED_IN = 'The Department field is required.';
export const LOCATION_MUST_BE_FILLED_IN = 'The Location field is required.';
export const POSITION_MUST_BE_FILLED_IN = 'The Position field is required.';
export const SELECT_STACK = 'Select Stack.';
export const NOT_SELECTED_USER = 'Select User.';
export const NOT_SELECTED_ISSUE_DATE = 'Select issue date.';
export const NOT_SELECTED_RETURN_DATE = 'Select return date.';
export const NO_DATA_EQUIPMENT = 'There is no data to display.';
export const RESPONSE_USER_WITH_ROLE_EXISTS_START = 'A User with';
export const RESPONSE_USER_WITH_ROLE_EXISTS_END = 'role is already existed.';
export const RESPONSE_USER_WITH_EMAIL_EXISTS =
	'User with this email already exists!';
