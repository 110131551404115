import { useEffect } from 'react';

import { initializeApplicationInsights } from '@/services/telemetryService';

export const useTelemetry = () => {
	useEffect(() => {
		if (
			process.env.NODE_ENV !== 'development' &&
			process.env.REACT_APP_VERCEL_ENV !== 'development'
		) {
			initializeApplicationInsights();
		}
	}, []);
};
