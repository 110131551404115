export enum SortDirection {
	ASC = 'asc',
	DESC = 'desc',
}

export enum UsersOrderBy {
	id = 'id',
	lastName = 'LastName',
	email = 'Email',
	position = 'Position',
	department = 'Department',
	location = 'Location',
}

export const DefaultSortFields = {
	ID: 'id',
	NAME: 'name',
	FIRSTNAME: 'firstName',
	LASTNAME: 'lastName',
	TYPE: 'type',
};
