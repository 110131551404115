// The key that will indicate the date of the user's last activity in localStorage
export const USER_LAST_ACTIVITY_DATE = 'user-last-activity-date';

export const BROADCAST_CHANNEL_NAME = 'bc-session-storage';

// This key in local storage contains the reason for the logout in localStorage
export const LOGOUT_REASON = 'logout-reason';
// This last visited page
export const PATH_KEY = 'path-key';
// Time after which logout will occur if the user is inactive
export const INACTIVE_LIMIT = 1800000;
// Array of events that will be considered user activity
export const USER_ACTIVITY_EVENTS = [
	'load',
	'mousemove',
	'click',
	'contextmenu',
	'scroll',
	'drag',
	'copy',
	'cut',
	'paste',
	'touchstart',
	'touchend',
	'touchcancel',
	'touchmove',
];
