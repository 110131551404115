import { memo } from 'react';

import { NumberInput } from '@/fields/NumberInput';

import { Wrapper } from './styles';
import type { RepeatWeeksInnerProps } from './types';

function RepeatWeeksInner({ value, onChange }: RepeatWeeksInnerProps) {
	return (
		<Wrapper>
			<span>Repeat every</span>
			<NumberInput value={value} onChange={onChange} />
			<span>{`week${value === 1 ? '' : 's'}`}</span>
		</Wrapper>
	);
}

export const RepeatWeeks = memo(RepeatWeeksInner);
