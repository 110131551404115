import { Buffer } from 'buffer';

import axios from 'axios';
import { useQuery } from 'react-query';

import { GET_FILE } from '@/constants/index';

export const useGetFile = (fileId: string) =>
	useQuery({
		queryKey: ['file', fileId],
		queryFn: async () => {
			if (!fileId) {
				return undefined;
			}

			try {
				const response = await axios.get(`${GET_FILE}/${fileId}`, {
					responseType: 'arraybuffer',
				});

				return Buffer.from(response.data, 'binary').toString('base64');
			} catch {
				return undefined;
			}
		},
		keepPreviousData: false,
		refetchOnWindowFocus: false,
	});
