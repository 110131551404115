import { alpha, Badge } from '@mui/material';
import { Palette, styled } from '@mui/material/styles';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Moment } from 'moment';

import type { CustomPickersDayProps } from './types';

export const BadgeWithBorder = styled(Badge)(({ theme }) => ({
	' .MuiBadge-badge': {
		border: `1px solid ${theme.palette.secondary.contrastText}`,
	},
}));

const getBgColor = (
	condition: boolean | number,
	{ palette }: { palette: Palette }
) => (condition ? palette.primary.main : palette.secondary.main);

const css = (
	{ palette }: { palette: Palette },
	completeRange: Moment | null
) => ({
	content: "''",
	height: 12,
	width: 18,
	position: 'absolute',
	backgroundColor: alpha(getBgColor(!completeRange, { palette }), 0.2),
});

const checkCamelCase = (prop: string) =>
	![
		'dayIsBetween',
		'isFirstDay',
		'isLastDay',
		'isSameDate',
		'completeRange',
		'hovered',
		'daysIsHoveredBetween',
		'isFirstHoveredDay',
		'isLastHoveredDay',
	].includes(prop);

export const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: checkCamelCase,
})<CustomPickersDayProps>(
	({
		theme,
		dayIsBetween,
		isFirstDay,
		isLastDay,
		isSameDate,
		completeRange,
		hovered,
		daysIsHoveredBetween,
		isFirstHoveredDay,
		isLastHoveredDay,
	}) => ({
		transition: 'background-color 1s cubic-bezier(0.04, 0.67, 0.01, 1.01)',
		...((dayIsBetween || daysIsHoveredBetween) && {
			transition: 'padding 0.5s cubic-bezier(0.04, 0.67, 0.01, 1.01)',
			marginRight: 0,
			marginLeft: 0,
			'&.MuiPickersDay-root': {
				paddingLeft: 20,
				paddingRight: 20,
			},
			borderRadius: 0,
			border: 'none',
			backgroundColor: alpha(getBgColor(daysIsHoveredBetween, theme), 0.2),

			'&:hover, &:focus': {
				border: 'none',
				backgroundColor: getBgColor(daysIsHoveredBetween, theme),
			},
		}),
		...((isFirstDay || isFirstHoveredDay) && {
			marginLeft: 4,
			marginRight: 0,
			borderRadius: '50%',
			backgroundColor: getBgColor(!completeRange, theme),
			color: `${theme.palette.secondary.contrastText} !important`,
			...((completeRange || isFirstHoveredDay) && {
				'&:after': {
					...css(theme, completeRange),
					bottom: 0,
					right: 0,
				},
				'&:before': {
					...css(theme, completeRange),
					top: 0,
					right: 0,
				},
				'&.MuiPickersDay-root': {
					paddingLeft: 0,
					paddingRight: 0,
					'&:not(.Mui-selected)': {
						border: 'none',
					},
				},
			}),
		}),
		...((isLastDay || isLastHoveredDay) && {
			marginRight: 4,
			marginLeft: 0,
			backgroundColor: getBgColor(!completeRange, theme),
			borderRadius: '50%',
			color: theme.palette.secondary.contrastText,
			...((completeRange || isLastHoveredDay) && {
				'&:after': {
					...css(theme, completeRange),
					bottom: 0,
					left: 0,
				},
				'&:before': {
					...css(theme, completeRange),
					top: 0,
					left: 0,
				},
				'&.MuiPickersDay-root': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			}),
		}),
		'&.MuiPickersDay-root.Mui-selected': {
			backgroundColor: theme.palette.secondary.main,
			opacity: '1 !important',
		},
		'&.Mui-disabled.MuiPickersDay-root.Mui-disabled': {
			color: alpha(theme.palette.primary.main, 0.75),
		},
		'&.MuiPickersDay-root.MuiPickersDay-today': {
			transition: 'unset',
			...(!isFirstDay &&
				!daysIsHoveredBetween && {
					color: theme.palette.primary.main,
					backgroundColor: alpha(theme.palette.secondary.main, 0.1),
					border: `1px solid ${theme.palette.secondary.main}`,
					'&.Mui-disabled.MuiPickersDay-root.Mui-disabled:after, &.Mui-disabled.MuiPickersDay-root.Mui-disabled:before':
						{
							backgroundColor: 'transparent !important',
						},
				}),
			'&:focus': {
				border: 'none',
			},
		},
		...(isSameDate && {
			'&.Mui-disabled.MuiPickersDay-root.Mui-disabled:after, &.Mui-disabled.MuiPickersDay-root.Mui-disabled:before':
				{
					backgroundColor: 'transparent !important',
				},
		}),
		...(hovered && {
			'&:hover:not(.Mui-selected)': {
				backgroundColor: !daysIsHoveredBetween
					? alpha(theme.palette.primary.main, 0.5)
					: undefined,
			},
		}),
	})
) as React.ComponentType<CustomPickersDayProps>;
