import { Scrollbar, Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material/styles';

export const BookingWorkplaceWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	paddingLeft: theme.spaces.m,
	gap: theme.spaces.m,
}));

export const WorkplaceWrapper = styled('div')(({ theme }) => ({
	width: '100%',
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spaces.xl2,
	[theme.breakpoints.down(900)]: {
		flexDirection: 'column-reverse',
		gap: theme.spaces.m,
	},
}));

export const Workplace = styled(Scrollbar)(({ theme }) => ({
	display: 'grid',
	gridTemplateAreas:
		'"desk-1 desk-2 . desk-3 desk-4 . desk-5 desk-6 . desk-7" "desk-8 desk-9 . desk-10 desk-11 . desk-12 desk-13 . ." ". . . . . . . . . ." ". . . . . . . . desk-22 desk-27" ". . desk-14 desk-18 . wall . . desk-23 desk-28" ". . desk-15 desk-19 . wall . . desk-24 desk-29" ". . desk-16 desk-20 . wall . . desk-25 desk-30" ". . desk-17 desk-21 . wall . . desk-26 desk-31"',
	gridTemplateRows: 'repeat(8, minmax(60px, 1.2fr))',
	gridTemplateColumns: 'repeat(10, minmax(60px, 0.5fr))',
	gridGap: 3,
	position: 'relative',
	paddingLeft: theme.spaces.m,
	paddingRight: theme.spaces.m,
	width: '100%',
	height: '100%',

	[theme.breakpoints.down(1400)]: {
		gridTemplateAreas:
			'"desk-1 desk-2 . desk-3 desk-4" "desk-5 desk-6 . desk-8 desk-7" "desk-10 desk-9 . desk-12 desk-11" "desk-14 desk-13 . desk-15 desk-19" "desk-17 desk-18 . desk-16 desk-20" "desk-22 desk-21 . desk-23 desk-27" "desk-25 desk-29 . desk-24 desk-28" "desk-26 desk-30 . . desk-31"',
		gridTemplateColumns: 'repeat(5, minmax(60px, 0.5fr))',
		paddingRight: theme.spaces.s,
	},

	[theme.breakpoints.down('md')]: {
		gridTemplateAreas:
			'"desk-1 desk-2" "desk-3 desk-4" "desk-5 desk-6" "desk-8 desk-7" "desk-10 desk-9" "desk-12 desk-11" "desk-14 desk-13" "desk-15 desk-18" "desk-16 desk-19" "desk-17 desk-20" "desk-22 desk-21" "desk-23 desk-27" "desk-25 desk-29" "desk-24 desk-28" "desk-26 desk-30" ". desk-31"',
		gridTemplateColumns: 'repeat(2, minmax(60px, 0.5fr))',
		gridTemplateRows: 'repeat(16, 80px)',
		paddingRight: theme.spaces.m,
	},
	[theme.breakpoints.down(720)]: {
		gridTemplateColumns: 'repeat(2, minmax(60px, 0.3fr))',
		gridTemplateRows: 'repeat(16, 160px)',
		gridGap: 6,
		justifyContent: 'center',
		padding: 0,
	},
}));

export const Wall = styled('div')(({ theme }) => ({
	gridArea: 'wall',
	width: '50%',
	height: '85%',
	backgroundColor: theme.palette.bookingWorkplace.available,
	borderRadius: 3,
	justifySelf: 'end',
	alignSelf: 'center',
	transform: 'translateY(-15%)',
}));
