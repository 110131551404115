import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

export const StyledAccordion = styled(Accordion)({
	backgroundColor: 'transparent',
	'& .MuiPaper-root, & .MuiAccordionSummary-content': {
		margin: 0,
	},
	'& .MuiAccordionSummary-root, & .MuiAccordionDetails-root': {
		padding: 0,
	},
	'&::before': {
		display: 'none',
	},
});

export const StyledAccordionSummary = styled(AccordionSummary)({
	minHeight: 0,
});
export const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
	margin: theme.spacing(0, 0, 2, 0),
	gap: theme.spacing(1),
	userSelect: 'none',
}));
