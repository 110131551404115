import { IconButton, InputAdornment, Paper, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
	width: 200,
	position: 'relative',
	backgroundColor: theme.palette.background.default,
	' .MuiInputAdornment-root': {
		pointerEvents: 'none',
		svg: {
			width: 14,
			height: 15,
			color: theme.palette.secondary.main,
			pointerEvents: 'none',
		},
		'button svg': {
			width: 24,
			height: 24,
			pointerEvents: 'all',
		},
	},
	input: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
}));

export const StyledTextField = styled(TextField)({
	width: 200,
	minWidth: 150,
	'& .clear-button': {
		display: 'none',
	},
	'&:hover .clear-button': {
		display: 'inline-flex',
	},
});

export const StyledPaper = styled(Paper)(({ theme }) => ({
	marginTop: 4,
	width: '100%',
	padding: theme.spacing(1.75),
	position: 'absolute',
	zIndex: 100,
	backgroundColor: 'inherit',
}));

export const StyledInputAdornment = styled(InputAdornment)({
	'& button svg.clear-icon': {
		width: 20,
		height: 20,
	},
});

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
	padding: theme.spacing(0.5),
	marginRight: theme.spacing(0.5),
}));
