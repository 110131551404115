import { SvgIconProps, createSvgIcon, useTheme } from '@mui/material';

const Refresh = createSvgIcon(
	<path d="M17.1918 0.637841L17.2036 3.06845C15.8206 1.63478 14.0411 0.646632 12.093 0.230615C10.145 -0.185403 8.11703 -0.0103473 6.26907 0.733354C4.42111 1.47705 2.83726 2.75553 1.72043 4.405C0.603602 6.05447 0.00465463 7.99981 0.00032056 9.9918C-0.0233006 10.8422 1.26641 10.8492 1.25224 9.99653C1.2525 8.21692 1.79572 6.47976 2.80936 5.01704C3.823 3.55433 5.25879 2.4357 6.92498 1.81056C8.59117 1.18542 10.4084 1.08352 12.1341 1.51848C13.8597 1.95344 15.4115 2.90455 16.5823 4.24479L13.6061 4.26132C12.7723 4.26604 12.777 5.51796 13.6108 5.51324L17.839 5.48962C18.1697 5.48726 18.4649 5.21325 18.4649 4.85657L18.4413 0.628393C18.4413 0.545079 18.4248 0.462596 18.3926 0.385742C18.3604 0.308888 18.3133 0.239198 18.254 0.180728C18.1946 0.122258 18.1242 0.0761745 18.0469 0.0451598C17.9696 0.0141451 17.8869 -0.00118178 17.8036 7.10504e-05C17.6379 0.0044035 17.4807 0.073731 17.3658 0.193071C17.2508 0.31241 17.1898 0.472177 17.1918 0.637841ZM18.7508 10.0013C18.7505 11.7809 18.2073 13.518 17.1936 14.9807C16.18 16.4434 14.7442 17.5621 13.078 18.1872C11.4118 18.8124 9.59456 18.9143 7.86893 18.4793C6.1433 18.0443 4.59146 17.0932 3.42066 15.753L6.39456 15.7365C7.22838 15.7317 7.2213 14.4798 6.38983 14.4845L2.16165 14.5082C1.99499 14.51 1.83579 14.5775 1.7186 14.6961C1.6014 14.8146 1.53568 14.9745 1.53569 15.1412L1.55931 19.367C1.55931 19.7213 1.84749 20.0095 2.20181 20.0001C2.36639 19.9945 2.5222 19.9246 2.63574 19.8053C2.74927 19.686 2.81144 19.5269 2.80887 19.3623L2.79706 16.9293C4.18018 18.3631 5.9599 19.3513 7.90819 19.7673C9.85649 20.1832 11.8846 20.008 13.7327 19.264C15.5807 18.52 17.1645 17.2411 18.2812 15.5913C19.3978 13.9415 19.9965 11.9958 20.0003 10.0036C20.0098 9.57843 19.6932 9.36584 19.3767 9.36584C19.0602 9.36584 18.7413 9.57371 18.7484 10.0013H18.7508Z" />,
	'Refresh'
);

export function RefreshIcon({ sx, ...props }: SvgIconProps) {
	const theme = useTheme();

	return (
		<Refresh
			viewBox="0 0 20 20"
			xmlns="http://www.w3.org/2000/svg"
			sx={{ width: 20, height: 20, fill: theme.palette.table.headColor, ...sx }}
			{...props}
		/>
	);
}
