interface NavigatorConnection extends Navigator {
	connection?: {
		effectiveType?: string;
	};
}

const vitalsUrl = 'https://vitals.vercel-analytics.com/v1/vitals';
function getConnectionSpeed(): string {
	const connection = navigator as NavigatorConnection;

	return connection.connection?.effectiveType ?? '';
}
export function sendToVercelAnalytics(metric: {
	id: string;
	name: string;
	value: number;
}): void {
	const analyticsId: string | undefined =
		process.env.REACT_APP_VERCEL_ANALYTICS_ID;

	if (!analyticsId) {
		return;
	}
	const body = {
		dsn: analyticsId,
		id: metric.id,
		page: window.location.pathname,
		href: window.location.href,
		event_name: metric.name,
		value: metric.value.toString(),
		speed: getConnectionSpeed(),
	};
	const blob = new Blob([new URLSearchParams(body).toString()], {
		type: 'application/x-www-form-urlencoded',
	});

	if (navigator.sendBeacon) {
		navigator.sendBeacon(vitalsUrl, blob);
	} else {
		fetch(vitalsUrl, {
			body: blob,
			method: 'POST',
			credentials: 'omit',
			keepalive: true,
		});
	}
}
