export const EQUIPMENT_HEAD_CELLS = [
	{
		id: 'id',
		numeric: false,
		disablePadding: true,
		label: 'Id',
	},
	{
		id: 'serialNumber',
		numeric: false,
		disableSort: true,
		label: 'Serial Number',
	},
	{
		id: 'name',
		numeric: false,
		label: 'Name',
	},
	{
		id: 'type',
		numeric: false,
		label: 'Type',
	},
	{
		id: 'purchasePriceUsd',
		numeric: false,
		label: 'Price, $',
	},
	{
		id: 'user',
		numeric: false,
		label: 'User',
	},
	{
		id: 'department',
		numeric: false,
		label: 'Department',
	},
	{
		id: 'equipmentCharacteristics',
		numeric: false,
		disableSort: true,
		label: 'Equipment characteristics',
	},
	{
		id: 'purchasePlace',
		numeric: false,
		disableSort: true,
		label: 'Purchase place',
	},
	{
		id: 'approver',
		numeric: false,
		label: 'Approver',
	},
	{
		id: 'purchaseDate',
		numeric: false,
		label: 'Purchase date',
	},
	{
		id: 'guaranteeDate',
		numeric: false,
		disableSort: true,
		label: 'Guarantee date',
	},
	{
		id: 'location',
		numeric: false,
		label: 'Location',
	},
	{
		id: 'comment',
		numeric: false,
		disableSort: true,
		label: 'Comment',
	},
];
