import { styled } from '@mui/material/styles';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

import type { StyledCalendarProps } from './types';

const checkProps = (prop: string) => !['muiIconContent'].includes(prop);

export const StyledCalendar = styled(DateCalendar, {
	shouldForwardProp: checkProps,
})<StyledCalendarProps>(({ theme, muiIconContent }) => ({
	width: 290,
	margin: 0,
	' .MuiPickersDay-root': {
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
	},
	' .MuiPickersCalendarHeader-root': {
		padding: theme.spacing(0, 1),
		marginTop: theme.spacing(1),
		fontSize: theme.typography.pxToRem(17),
	},
	' .MuiPickersDay-dayOutsideMonth': {
		fontWeight: 400,
		color: theme.palette.cancel.main,
	},
	' .MuiDayCalendar-weekDayLabel': {
		color: theme.palette.cancel.main,
		fontWeight: 600,
		fontSize: theme.typography.pxToRem(16),
		'&:nth-of-type(6), :nth-of-type(7)': {
			color: theme.palette.secondary.main,
		},
	},
	' .MuiPickersCalendarHeader-labelContainer': {
		fontSize: theme.typography.pxToRem(18),
		fontWeight: 600,
		color: theme.palette.primary.main,
		cursor: 'auto',
		'&:before': {
			content: "''",
			WebkitMask: `${muiIconContent} no-repeat 50% 50%`,
			mask: `${muiIconContent} no-repeat 50% 50%`,
			WebkitMaskSize: 'cover',
			maskSize: 'cover',
			marginRight: theme.spacing(1),
			width: 21,
			height: 23,
			display: 'inline-block',
			backgroundColor: theme.palette.secondary.main,
		},
	},
	'.MuiPickersDay-root.Mui-selected': {
		color: 'white !important',
		backgroundColor: `${theme.palette.secondary.main} !important`,
		boxShadow: '0px 2px 3px rgba(255, 138, 0, 0.28)',
	},
})) as React.ComponentType<StyledCalendarProps>;
