import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { useColorMode } from '@/context/Theme';

import { StyledCloseButton } from './styles';
import { CloseButtonProps } from './types';

export function CloseButton({ closeToast }: CloseButtonProps) {
	const { theme } = useColorMode();

	// use "styles" instead "theme" attr because ToastContainer gives to CloseButton its own "theme"
	return (
		<StyledCloseButton onClick={closeToast} styles={theme}>
			<CloseRoundedIcon color={theme.palette.toast.icon} />
		</StyledCloseButton>
	);
}
