import { TextArea as TextAreaField } from '@mms/mms-ui-library';
import { Field, FieldProps } from 'formik';
import { JSX } from 'react/jsx-runtime';

import type { MMSTextAreaProps } from './types';

export function TextArea({
	name,
	error: err,
	...otherProps
}: MMSTextAreaProps) {
	return (
		<Field name={name}>
			{(props: JSX.IntrinsicAttributes & FieldProps<string>) => {
				const {
					field,
					meta: { value, error, touched },
				} = props;

				const errorMessage = (value || touched) && error;

				return (
					<TextAreaField
						error={err || Boolean(errorMessage)}
						errorText={error}
						{...otherProps}
						{...field}
					/>
				);
			}}
		</Field>
	);
}
