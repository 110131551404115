import { styled } from '@mui/material/styles';

export const StyledList = styled('div')<{ isEmploye?: boolean }>(
	({ theme }) => ({
		width: '100%',
		height: 'fit-content',
		boxShadow: theme.namedShadows.bookListWrapper,
		[theme.breakpoints.down('lg')]: {
			width: '46%',
		},
		[theme.breakpoints.down(720)]: {
			width: '100%',
		},
	})
);
