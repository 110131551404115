import { CrossCircle, PopUp, Unblock } from '@mms/mms-ui-library';
import { useContext, useEffect, useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import { PopUpBody } from '../PopUpBody';

import { BookingQueriesKeys, isEmployee, Roles } from '@/constants/index';
import { useAuth } from '@/context/AuthContext';
import { BookingContext } from '@/context/Booking';
import { useToast } from '@/context/Toast';
import type { HighlightedDays } from '@/context/types';
import {
	getDeleteDialogHeader,
	getDeleteDialogSubtext,
	getDeleteDialogText,
	getDialogTextComponent,
} from '@/helpers/index';
import { useGetBookings, useDeleteBooking } from '@/queries/booking/index';

import { Item } from './Item';
import { StyledList } from './styles';
import type { BookingItem } from './types';

export function BookingList() {
	const { id, role } = useAuth();

	const toast = useToast();
	const queryClient = useQueryClient();
	const { setHighlightedDays } = useContext(BookingContext);
	const [itemToDelete, setItemToDelete] = useState<BookingItem | null>(null);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const { data, dataUpdatedAt, refetch, isSuccess } = useGetBookings(
		id,
		role !== Roles.SA,
		toast
	);

	const { mutate } = useDeleteBooking(
		{
			onSuccess: () => {
				queryClient.invalidateQueries(BookingQueriesKeys.bookings);
				queryClient.refetchQueries(BookingQueriesKeys.reservations);
				refetch();
			},
		},
		toast
	);

	useEffect(() => {
		if (data) {
			const days = data.reduce(
				(acc: HighlightedDays, item) => {
					if (item.isBlock) {
						acc.blockedList?.push(...(item.dateList as unknown as number[]));
					} else if (item.userId === id) {
						acc.yourList?.push(...(item.dateList as unknown as number[]));
					} else {
						acc.bookedList?.push(...(item.dateList as unknown as number[]));
					}

					return acc;
				},
				{
					bookedList: [],
					blockedList: [],
					yourList: [],
				}
			);

			setHighlightedDays(days);
		}
	}, [dataUpdatedAt]);

	const handleCloseDialog = useCallback(() => {
		setIsDialogOpen(false);
		setItemToDelete(null);
	}, []);

	const initiateBookDelete = useCallback(
		(item: BookingItem) => () => {
			setItemToDelete(item);
			setIsDialogOpen(true);
		},
		[]
	);

	const handleDelete = useCallback(() => {
		const { bookingId, isBlock: isBlockMessage } = itemToDelete as BookingItem;

		if (!bookingId) {
			return;
		}

		mutate({ id: bookingId, isBlockMessage });
		setIsDialogOpen(false);
	}, [itemToDelete?.bookingId, itemToDelete?.isBlock]);

	return (
		<>
			<StyledList isEmploye={isEmployee(role)}>
				{isSuccess &&
					data?.map((props) => (
						<Item
							key={props.bookingId}
							handleDelete={initiateBookDelete(props as BookingItem)}
							currentUserId={id}
							userRole={role as Roles}
							{...props}
						/>
					))}
			</StyledList>
			{itemToDelete && isDialogOpen && (
				<PopUp
					title={getDeleteDialogHeader(itemToDelete)}
					onClose={handleCloseDialog}
					type="approve"
					showCloseButton={false}
					headerIcon={itemToDelete.isBlock ? <Unblock /> : <CrossCircle />}
					controls={{
						negativeControl: { onClick: handleCloseDialog },
						positiveControl: { onClick: handleDelete },
					}}
				>
					<PopUpBody
						text={getDeleteDialogText(itemToDelete, id) || ''}
						textComponent={getDialogTextComponent(itemToDelete, id)}
						subtext={getDeleteDialogSubtext(itemToDelete)}
					/>
				</PopUp>
			)}
		</>
	);
}
