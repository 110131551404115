import { styled } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

import type { StyledToastContainerProps } from './types';

export const StyledToastContainer = styled(
	ToastContainer
)<StyledToastContainerProps>(({ styles }) => ({
	'& .Toastify__toast': {
		backgroundColor: styles?.palette?.toast?.backgroundColor,
		boxShadow: '-3px 4px 33px rgba(0, 0, 0, 0.25)',
		color: styles.palette.text.primary,
		borderRadius: '6px',
		padding: 0,
		width: 384,
	},

	'& .Toastify__toast-body': {
		padding: 0,
	},
}));
