import { useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { getTabIdBySlug, tabsConfig } from '../config';
import { NavigationItemPayload } from '../types';

import { RouteGuard } from '@/components/RouteGuard';
import { SIDEBAR_SELECTED_ITEM_STORAGE_KEY } from '@/constants/storageKeys';
import { useStickyState } from '@/hooks/useStickyState';

import { StyledSidebar } from './styles';
import { LeftMenuProps } from './types';

export function LeftMenu({
	isOpen,
	setIsOpen,
	exclusionClickAwayElements,
}: LeftMenuProps) {
	const location = useLocation();

	const [selectedMenuItemId, setSelectedMenuItemId] = useStickyState(
		tabsConfig[0][0].id,
		SIDEBAR_SELECTED_ITEM_STORAGE_KEY
	);

	useEffect(() => {
		const currentId = getTabIdBySlug(location.pathname);

		if (currentId) {
			setSelectedMenuItemId(currentId);
		}
	}, [location.pathname]);

	const onMenuItemClickHandler = (itemId: number | null) => () => {
		setSelectedMenuItemId(itemId);
	};

	const handleClickAway = useCallback(() => {
		setIsOpen(false);
	}, []);

	return (
		<StyledSidebar
			zIndex={102}
			selectedId={selectedMenuItemId}
			setSelectedId={setSelectedMenuItemId}
			isOpen={isOpen}
			menuItems={tabsConfig}
			small={false}
			exclusionClickAwayElements={exclusionClickAwayElements}
			onClickAway={handleClickAway}
			render={({ icon, label, id, payload }) => {
				const itemPayload = payload as NavigationItemPayload;

				return (
					<RouteGuard
						key={id}
						isOpen={isOpen}
						icon={icon}
						label={label}
						onClick={onMenuItemClickHandler(id)}
						selected={selectedMenuItemId === id}
						payload={itemPayload}
					/>
				);
			}}
		/>
	);
}
