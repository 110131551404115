import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import type { CustomWidthTooltipProps } from './types';

export const CustomWidthTooltip = styled(
	({ className, ...props }: CustomWidthTooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	)
)<CustomWidthTooltipProps>(({ theme, currentWidth }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: currentWidth || 'auto',
		minWidth: currentWidth || 'auto',
		marginTop: '0 !important',
		background: theme.palette.tooltip.main,
		padding: theme.spacing(1.75),
		position: 'relative',
	},
}));
