import { ThemeMode } from '@mms/mms-ui-library';
import { Backdrop, styled } from '@mui/material';

export const Overlay = styled(Backdrop)(({ theme }) => ({
	position: 'absolute',
	backgroundColor:
		theme.palette.mode === ThemeMode.LIGHT
			? theme.palette['primary-1']
			: theme.palette['primary-8'],
	opacity: 0.77,
	backdropFilter: 'blur(2px)',
	zIndex: 5,
}));
