import { Typography } from '@mms/mms-ui-library';
import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import MaterialTableCell from '@mui/material/TableCell';
import MaterialTableRow from '@mui/material/TableRow';

import type { BoxProjectProps } from '../types';

export const MaterialTableRowStyled = styled(MaterialTableRow)(({ theme }) => ({
	background: theme.palette.table.nestedTable,
	padding: 0,
}));

export const MaterialTableCellSkeleton = styled(MaterialTableCell)({
	padding: 0,
	border: 'none',
});

export const MaterialTableTwoCell = styled(MaterialTableCell)({
	padding: 0,
	border: 'none',
	maxWidth: 180,
});

export const BoxProject = styled(Box)<BoxProjectProps>(({ theme, active }) => ({
	padding: theme.spacing(1, 0, 1, 4),
	border: 'none',
	borderBottom: `1px solid ${theme.palette.table.rowBorder}`,
	opacity: active ? 'unset' : 0.5,
}));

export const BoxProjectTime = styled(BoxProject)(({ theme, active }) => ({
	padding: theme.spacing(1, 0),
	opacity: active ? 'unset' : 0.5,
}));

export const TypographyProjectName = styled(Typography)(({ theme }) => ({
	textAlign: 'left',
	width: 270,
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	fontSize: theme.typography.pxToRem(14),
	paddingRight: theme.spacing(2),
}));

export const TypographyStatus = styled(Typography)(({ theme }) => ({
	width: 153,
	textAlign: 'left',
	fontSize: theme.typography.pxToRem(14),
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
	fontSize: theme.typography.pxToRem(14),
	textAlign: 'left',
}));

export const StyledMinHours = styled('span')(({ theme }) => ({
	color: theme.palette.field.secondary,
}));
