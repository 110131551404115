/* eslint-disable no-nested-ternary */
import moment, { Moment } from 'moment';

import {
	SELECT_DATE,
	UNBLOCK_DIALOG_HEADER,
	CANCEL_DIALOG_HEADER,
	BookPlaceType,
	BookType,
	SURE_TO_UNBLOCK,
	SURE_TO_CANCEL_BOOKING,
	SELECT_DESK,
} from '@/constants/index';
import { BookingEntity } from '@/types/Booking';

const { Repetitive, Multiple, Single } = BookType;
const { Blocked, Selected, Booked } = BookPlaceType;

export const getBookType = (
	weeksInInterval: number,
	dateList: Array<number | string>
) => (weeksInInterval ? Repetitive : dateList.length > 1 ? Multiple : Single);

export const getBookingPlaceType = (
	currentUserId: number,
	bookUserId: number,
	isBlock: boolean
) => (isBlock ? Blocked : currentUserId === bookUserId ? Selected : Booked);

export const getTextFromMultipleDate = (
	dateList: Array<Moment | number | string>
) => {
	const [day1, month1, year1] = moment(dateList[0])
		.format('DD MMMM YYYY')
		.split(' ');
	const [day2, month2, year2] = moment(dateList.at(-1))
		.format('DD MMMM YYYY')
		.split(' ');

	if (year1 !== year2) {
		return `${day1} ${month1}, ${year1} - ${day2} ${month2}, ${year2}`;
	}

	if (month1 !== month2) {
		return `${day1} ${month1} - ${day2} ${month2}, ${year1}`;
	}

	if (day1 === day2 && month1 === month2 && year1 === year2) {
		return `${day1} ${month1}, ${year1}`;
	}

	return `${day1}-${day2} ${month1}, ${year1}`;
};

export const createPeriodInText = (
	weeksInInterval: number,
	dateList: Array<number | string>
) => {
	const date = dateList[0];
	switch (getBookType(weeksInInterval, dateList)) {
		case Single:
			return moment(date).format('DD MMMM, YYYY');
		case Multiple:
			return getTextFromMultipleDate(dateList);
		case Repetitive:
			return `${getTextFromMultipleDate(dateList)} on ${moment(date).format(
				'dddd'
			)}s`;
		default:
			return null;
	}
};

export function getStartOfMonthForRequest(date: Moment | null) {
	return date?.clone().startOf('month').toISOString(true);
}

export function getEndOfMonthForRequest(date: Moment | null) {
	return date
		?.clone()
		.endOf('month')
		.hours(0)
		.minutes(0)
		.seconds(0)
		.milliseconds(0)
		.toISOString(true);
}

export const getDateAsResponseType = (date: Moment) =>
	date
		.clone()
		.hours(0)
		.minutes(0)
		.seconds(0)
		.milliseconds(0)
		.toISOString(true)
		.replace(/\+.*/, 'Z');

export const getDeleteDialogHeader = ({ isBlock }: BookingEntity) =>
	isBlock ? UNBLOCK_DIALOG_HEADER : CANCEL_DIALOG_HEADER;

export const getDeleteDialogText = (
	{ isBlock, userId }: BookingEntity,
	currentUserId: number
) => {
	if (isBlock) {
		return SURE_TO_UNBLOCK;
	}

	return userId === currentUserId ? SURE_TO_CANCEL_BOOKING : null;
};

export const getDeleteDialogSubtext = ({
	isBlock,
	weeksInInterval,
	dateList,
	workplaceNumber,
}: BookingEntity) =>
	!isBlock
		? `${createPeriodInText(
				weeksInInterval,
				dateList
		  )} - Desk M-${workplaceNumber}`
		: null;

export const getDeskBlockDialogSubtext = (
	firstDay: Moment,
	selectedTable: number
) =>
	`${getTextFromMultipleDate([
		firstDay,
		moment(firstDay).add(3, 'months'),
	])} - Desk M-${selectedTable}`;

export const getTooltipTitle = (
	lastDay: Moment | undefined,
	selected: number
) => (!lastDay ? SELECT_DATE : selected === 0 ? SELECT_DESK : null);
moment(moment());
