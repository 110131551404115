import axios from 'axios';
import { Moment } from 'moment';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_COLLISIONS_API_ENDPOINT,
	BookingQueriesKeys,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import {
	getTextFromMultipleDate,
	getDateAsResponseType,
} from '@/helpers/booking';
import { createToast } from '@/helpers/createToast';

export const useCheckCollisions = (
	workplaceNumber: number,
	fromDate: Moment | null,
	toDate: Moment | null,
	weeksInterval: number,
	toast: ToastContextValues
) =>
	useQuery({
		queryKey: [
			BookingQueriesKeys.collisions,
			workplaceNumber,
			fromDate,
			toDate,
			weeksInterval,
		],
		queryFn: async () => {
			if (workplaceNumber > 0) {
				try {
					const response = await axios.get(GET_COLLISIONS_API_ENDPOINT, {
						params: {
							workplaceNumber,
							fromDate: getDateAsResponseType(fromDate as Moment),
							toDate: getDateAsResponseType(toDate as Moment),
							weeksInterval,
						},
					});

					if (response.data?.length > 0) {
						const dates = getTextFromMultipleDate(response.data);
						toast.open(
							createToast(
								NotificationType.INFO,
								null,
								`You cannot book it, because the workplace M-${workplaceNumber} is booked for the following period: ${dates}.`
							)
						);

						return true;
					}
				} catch {
					return true;
				}

				return false;
			}

			return false;
		},
		keepPreviousData: true,
		enabled: Boolean(fromDate) && Boolean(toDate),
		refetchOnWindowFocus: false,
	});
