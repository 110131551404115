import { Pen, PlusCircle, PopUp } from '@mms/mms-ui-library';
import { useMemo, useRef, useState } from 'react';

import { LaptopIcon } from '@/assets/buttons/LaptopIcon';
import { PersonIcon } from '@/assets/buttons/PersonIcon';
import { CustomTabPanel } from '@/components/Tabs/TabPanel';
import { useEquipmentContext } from '@/context/Equipment';

import { MainEquipmentForm } from './MainForm';
import { FormContainer, Wrapper } from './styles';
import type { EquipmentFormProps, HistoryItem } from './types';
import { UserEquipmentForm } from './UserForm';
import { EquipmentHistory } from './UserForm/History';

export function EquipmentForm({ onClose, id, refetch }: EquipmentFormProps) {
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const { setEquipmentModel } = useEquipmentContext();
	const [equipmentId, setEquipmentId] = useState(id || '');
	const [historyItems, setHistoryItems] = useState<HistoryItem[]>([]);
	const [isEquipmentFormValid, setIsEquipmentFormValid] = useState(Boolean(id));
	const [isLoading, setIsLoading] = useState(false);
	const submitFormButtonRef = useRef<HTMLButtonElement>(null);

	const submitButtonClick = () => {
		if (submitFormButtonRef?.current) {
			submitFormButtonRef?.current.click();
		}
	};

	const onChangeActiveTab = (newValue: number) => {
		setActiveTabIndex(newValue);
	};

	const tabs = useMemo(
		() => [
			{
				id: 0,
				text: 'Equipment',
				icon: <LaptopIcon />,
			},
			{
				id: 1,
				text: 'User',
				icon: <PersonIcon />,
				disabled: !isEquipmentFormValid,
			},
		],
		[isEquipmentFormValid]
	);

	return (
		<PopUp
			title={`Equipment ${id ? 'editing' : 'adding'}`}
			headerIcon={id ? <Pen /> : <PlusCircle />}
			onClose={onClose}
			type="form"
			tabs={{
				fullWidth: false,
				type: 'withIcon',
				data: tabs,
				activeTab: activeTabIndex,
				onChangeTab: onChangeActiveTab,
			}}
			controls={
				activeTabIndex === 0
					? {
							negativeControl: { onClick: onClose },
							positiveControl: { onClick: submitButtonClick },
					  }
					: undefined
			}
			showLoader={isLoading}
		>
			<FormContainer maxHeight="" maxWidth="">
				<CustomTabPanel value={activeTabIndex} index={0}>
					<MainEquipmentForm
						{...{
							onClose,
							setEquipmentModel,
							setEquipmentId,
							refetch,
						}}
						id={equipmentId}
						setIsFormValid={setIsEquipmentFormValid}
						onLoad={setIsLoading}
						submitFormButtonRef={submitFormButtonRef}
					/>
				</CustomTabPanel>
				<CustomTabPanel value={activeTabIndex} index={1}>
					<Wrapper>
						<UserEquipmentForm
							isEdit={Boolean(id)}
							refetchGrid={refetch}
							{...{
								equipmentId,
								historyItems,
								setHistoryItems,
								onClose,
							}}
							onLoad={setIsLoading}
						/>
						<EquipmentHistory
							{...{
								historyItems,
								equipmentId: equipmentId as string,
								refetch,
								setHistoryItems,
							}}
						/>
					</Wrapper>
				</CustomTabPanel>
			</FormContainer>
		</PopUp>
	);
}
