import axios, { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_USERS_API_ENDPOINT,
	EquipmentQueriesKeys,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';
import { UserEquipment } from '@/types/Equipment';

export const useGetUserEquipment = (
	toast: ToastContextValues,
	userId: number
) =>
	useQuery({
		queryKey: [EquipmentQueriesKeys.user, userId],
		queryFn: async () => {
			try {
				const { data } = await axios.get<UserEquipment[]>(
					`${GET_USERS_API_ENDPOINT}/${userId}/equipment`,
					{
						params: {
							userId,
						},
					}
				);

				return data;
			} catch (error) {
				toast.open(
					createToast(
						NotificationType.ERROR,
						null,
						(error as AxiosError)?.message
					)
				);

				return [];
			}
		},
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		enabled: Boolean(userId),
	});
