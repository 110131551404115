import { Typography } from '@mms/mms-ui-library';
import { Dialog } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ButtonsWrapperProps } from './types';

export const StyledDialog = styled(Dialog)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	'.MuiDialog-paper': {
		borderRadius: 6,
		boxShadow: theme.customShadows[5],
		width: 368,
	},
}));

export const DialogHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	backgroundColor: theme.palette.primary.main,
	color: theme.palette.secondary.contrastText,
	fontWeight: 700,
	height: 49,
	padding: theme.spacing(2, 5),
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
	marginLeft: theme.spacing(2),
	marginTop: theme.spacing(0.25),
	lineHeight: theme.spacing(3.2),
}));

export const DialogBody = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	padding: theme.spacing(2, 4, 3, 4),
	background: theme.palette.form.background,
}));

export const Text = styled(Typography)(({ theme }) => ({
	margin: theme.spacing(0, 4, 2),
}));

export const ButtonsWrapper = styled('div')<ButtonsWrapperProps>(
	({ isReverseBtnOrder }) => ({
		display: 'flex',
		justifyContent: 'flex-start',
		gap: 32,
		flexDirection: isReverseBtnOrder ? 'row-reverse' : 'row',
	})
);
