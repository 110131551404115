import { createSvgIcon } from '@mui/material';

const Icon = createSvgIcon(
	<>
		<rect
			x="2.75"
			y="0.75"
			width="12.5"
			height="9.5"
			rx="1.25"
			stroke="currentColor"
			strokeWidth="1.5"
			fill="none"
		/>
		<path
			d="M1 12H17"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
	</>,
	'Laptop Icon'
);

export function LaptopIcon({ ...props }) {
	return (
		<Icon width="18" height="13" viewBox="0 0 18 13" fill="none" {...props} />
	);
}
