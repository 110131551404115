import { IconPolygon } from '@/assets/logo/IconPolygon';

import { LogoWrapper, SubTitle, Title } from './styles';

export function Logo() {
	return (
		<LogoWrapper>
			<Title>Modsen</Title>
			<IconPolygon fontSize="small" sx={{ mx: 1, width: 12, height: 12 }} />
			<SubTitle>Management System</SubTitle>
		</LogoWrapper>
	);
}
