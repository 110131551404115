import {
	BookingIcon,
	Dashboard,
	EquipmentIcon,
	ReportsIcon,
	TimesheetIcon,
	DepartamentIcon,
	ClientsIcon,
	ProjectsIcon,
	UserIcon,
	SignOutIcon,
} from '@mms/mms-ui-library';

import { AllRoles, chiefsAndSA, Roles, Routes } from '@/constants/index';

import { NavigationItem } from './types';

const userTabsConfig: NavigationItem[] = [
	{
		id: 1,
		label: 'Dashboard',
		icon: <Dashboard />,
		payload: {
			slug: Routes.dashboard,
			roles: AllRoles,
		},
	},
	{
		id: 2,
		label: 'Timesheet',
		icon: <TimesheetIcon />,
		payload: {
			slug: Routes.timeSheet,
			roles: AllRoles,
		},
	},
	{
		id: 3,
		label: 'Reports',
		icon: <ReportsIcon />,
		payload: {
			slug: Routes.reports,
			roles: AllRoles,
		},
	},
	{
		id: 4,
		label: 'Booking',
		icon: <BookingIcon />,
		payload: {
			slug: Routes.booking,
			roles: AllRoles,
		},
	},
];

const advancedTabsConfig: NavigationItem[] = [
	{
		id: 5,
		label: 'Departments',
		icon: <DepartamentIcon />,
		payload: {
			slug: Routes.departments,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 6,
		label: 'Clients',
		icon: <ClientsIcon />,
		payload: {
			slug: Routes.clients,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 7,
		label: 'Projects',
		icon: <ProjectsIcon />,
		payload: {
			slug: Routes.projects,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
	{
		id: 8,
		label: 'Users',
		icon: <UserIcon />,
		payload: {
			slug: Routes.users,
			roles: [Roles.CTO, Roles.CEO, Roles.HD],
		},
	},
];

const actionsTabsConfig: NavigationItem[] = [
	{
		id: 9,
		label: 'Equipment',
		icon: <EquipmentIcon />,
		payload: {
			slug: Routes.equipment,
			roles: chiefsAndSA,
		},
	},
	{
		id: 10,
		label: 'Sign Out',
		icon: <SignOutIcon />,
		payload: {
			slug: Routes.signOut,
			roles: AllRoles,
		},
	},
];

export const tabsConfig: NavigationItem[][] = [
	userTabsConfig,
	advancedTabsConfig,
	actionsTabsConfig,
];

export const drawerWidth = 210;
export const closedDrawerWidth = 56;

export function getTabIdBySlug(slug: string): number | null {
	let foundId: number | null = null;
	tabsConfig.forEach((item) => {
		const id = item.find((tab) => tab.payload?.slug === slug)?.id;

		if (id) {
			foundId = id;
		}
	});

	return foundId;
}
