import type { BulletProps } from './types';

export function Bullet({ color, diameter = 12 }: BulletProps) {
	return (
		<svg height={diameter} width={diameter}>
			<circle
				cx={diameter / 2}
				cy={diameter / 2}
				r={diameter / 2}
				fill={color}
			/>
		</svg>
	);
}
