export const S_BREAKPOINT = 510;
export const M_BREAKPOINT = 767;
export const L_BREAKPOINT = 1275;
export const XL_BREAKPOINT = 1585;
export const XXL_BREAKPOINT = 1890;

export const SliderDataKeys = {
	equipmentName: 'Equipment name',
	serialNumber: 'Serial number',
	issueDate: 'Issue date',
	returnDate: 'Return date',
};
