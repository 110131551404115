import axios from 'axios';
import { useQuery } from 'react-query';

import {
	NotificationType,
	GET_RESERVATION_API_ENDPOINT,
	BookingQueriesKeys,
	SOMETHING_WENT_WRONG,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { createToast } from '@/helpers/createToast';

import { GetReservationResponse } from './types';

export const useGetReservation = (
	fromDate: string | undefined,
	toDate: string | undefined,
	toast: ToastContextValues,
	error: string | null
) =>
	useQuery({
		queryKey: [BookingQueriesKeys.reservations, fromDate, toDate],
		queryFn: async () => {
			try {
				if (fromDate && toDate && !error) {
					const response = axios.get<GetReservationResponse>(
						GET_RESERVATION_API_ENDPOINT,
						{
							params: {
								fromDate: fromDate.replace(/\+.*/, 'Z'),
								toDate: toDate.replace(/\+.*/, 'Z'),
							},
						}
					);

					return (await response).data;
				}

				return undefined;
			} catch {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return undefined;
			}
		},
		keepPreviousData: true,
		enabled: Boolean(fromDate) && Boolean(toDate),
		refetchOnWindowFocus: false,
	});
