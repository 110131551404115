import { SvgIconProps, createSvgIcon } from '@mui/material';

const Profile = createSvgIcon(
	<path
		fillRule="evenodd"
		clipRule="evenodd"
		d="M11.905 5.33298C11.905 7.43034 10.1753 9.16596 7.99761 9.16596C5.81992 9.16596 4.09017 7.43034 4.09017 5.33298C4.09017 3.23562 5.81992 1.5 7.99761 1.5C10.1753 1.5 11.905 3.23562 11.905 5.33298ZM10.8722 9.85081C12.3938 8.90715 13.405 7.23654 13.405 5.33298C13.405 2.38766 10.9841 0 7.99761 0C5.01116 0 2.59017 2.38766 2.59017 5.33298C2.59017 7.23675 3.60164 8.90752 5.1235 9.85112C2.47073 10.843 0.482769 13.1676 0 15.9961H1.52763C2.15355 13.0602 4.7983 10.8342 7.99828 10.8342C11.1983 10.8342 13.843 13.0602 14.4689 15.9961H15.9966C15.5137 13.1673 13.5254 10.8425 10.8722 9.85081Z"
		fill="currentColor"
	/>,
	'Profile'
);

export function ProfileIcon({ sx, ...props }: SvgIconProps) {
	return (
		<Profile
			sx={{ width: 16, height: 16, fill: 'none', ...sx }}
			viewBox="0 0 16 16"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
