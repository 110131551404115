import { styled } from '@mui/material';

export const BodyWrapper = styled('div')(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	gap: theme.spaces.s,
	maxWidth: 288,
}));

export const TextWrapper = styled('div')({
	display: 'flex',
	justifyContent: 'center',
});
