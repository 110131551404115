import { isMoment } from 'moment';
import { useCallback, useState } from 'react';

import type {
	EquipmentFilterOption,
	EquipmentFilterValue,
	EquipmentFilters,
} from '@/components/Equipment/EquipmentControls/types';
import { FilterKeys } from '@/constants/filters';
import { swapNameAndDescription } from '@/helpers/swapKnowledgees';

const FILTER_DEFAULT_VALUES = {
	availability: 'All',
	equipmentType: 'All Equipment',
	equipmentLocation: 'All Locations',
	approver: 'All Approvers',
	user: 'All Users',
	firstDay: null,
	lastDay: null,
};

const checkIfDefaultFilterValue = (
	key: FilterKeys,
	filterValue: EquipmentFilterValue
) => {
	const incomingValue =
		typeof filterValue === 'string' || filterValue === null
			? filterValue
			: filterValue.name;

	if (incomingValue === FILTER_DEFAULT_VALUES[key]) {
		return true;
	}

	return false;
};

export const useEquipmentFilter = (initialValues = {}) => {
	const [filtersValues, setFilterValues] =
		useState<EquipmentFilters>(initialValues);

	const removeFilter = useCallback((filterKey: FilterKeys) => {
		setFilterValues((prevState) => {
			const newState = { ...prevState };
			delete newState[filterKey];

			return newState;
		});
	}, []);

	const handleFilterChanges = useCallback(
		(key: FilterKeys) => (filterValue: EquipmentFilterValue) => {
			if (checkIfDefaultFilterValue(key, filterValue)) {
				removeFilter(key);
			} else {
				const newFilterValue =
					isMoment(filterValue) || typeof filterValue === 'string'
						? filterValue
						: swapNameAndDescription(filterValue as EquipmentFilterOption);

				setFilterValues((prevState) => ({
					...prevState,
					[key]: newFilterValue,
				}));
			}
		},
		[]
	);

	const resetAllFilters = useCallback(() => {
		setFilterValues(initialValues);
	}, []);

	return {
		filtersValues,
		resetAllFilters,
		handleFilterChanges,
		removeFilter,
	};
};
