import axios, { AxiosError } from 'axios';
import { UseQueryOptions, useQuery } from 'react-query';

import {
	EquipmentQueriesKeys,
	GET_EQUIPMENT_API_ENDPOINT,
	NotificationType,
	SOMETHING_WENT_WRONG,
	SortDirection,
} from '@/constants/index';
import { ToastContextValues } from '@/context/types';
import { capitalizeString } from '@/helpers/capitalizeString';
import { createToast } from '@/helpers/createToast';
import { normalizeSearchString } from '@/helpers/normalizeSearchString';
import { GetEquipmentsResponse } from '@/queries/types';

export const useGetEquipments = (
	toast: ToastContextValues,
	page: number,
	pageSize: number,
	order: SortDirection,
	orderByCondition: string,
	search: string,
	isAvailable: true | null,
	assignedToUserId: number | undefined,
	equipmentType: string | undefined,
	approverId: number | undefined,
	location: string | undefined,
	purchaseDateFrom: string | null = null,
	purchaseDateTo: string | null = null,
	options: UseQueryOptions<GetEquipmentsResponse, AxiosError> = {}
) =>
	useQuery<GetEquipmentsResponse, AxiosError>({
		queryKey: [
			EquipmentQueriesKeys.equipments,
			page,
			pageSize,
			order,
			orderByCondition,
			search,
			isAvailable,
			assignedToUserId,
			equipmentType,
			approverId,
			location,
			purchaseDateFrom,
			purchaseDateTo,
		],
		queryFn: async () => {
			try {
				const normalizedSearch = normalizeSearchString(search);
				const res = await axios.get<GetEquipmentsResponse>(
					GET_EQUIPMENT_API_ENDPOINT,
					{
						params: {
							Page: page,
							PageSize: pageSize,
							SearchPattern: normalizedSearch,
							OrderBy: `${capitalizeString(orderByCondition)}`,
							OrderByDesc: order === SortDirection.DESC,
							IsAvailable: isAvailable,
							AssignedToUserId: assignedToUserId,
							EquipmentType: equipmentType,
							ApproverId: approverId,
							Location: location,
							PurchaseDateFrom: purchaseDateFrom,
							PurchaseDateTo: purchaseDateTo,
						},
					}
				);

				return res.data;
			} catch (error) {
				toast.open(
					createToast(NotificationType.ERROR, null, SOMETHING_WENT_WRONG)
				);

				return Promise.reject(error);
			}
		},
		keepPreviousData: true,
		refetchOnWindowFocus: false,
		...options,
	});
