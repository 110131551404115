import { SvgIconProps, createSvgIcon } from '@mui/material';

const ArrowLeftIcon = createSvgIcon(
	<path
		d="M22 8H1M1 8L7 1M1 8L7 15"
		stroke="#FFFF"
		strokeWidth="1.5"
		strokeLinecap="round"
	/>,
	'Arrow left'
);

export function ArrowLeft({ sx, ...props }: SvgIconProps) {
	return (
		<ArrowLeftIcon
			sx={{ width: 24, height: 16, ...sx }}
			viewBox="0 0 23 16"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		/>
	);
}
