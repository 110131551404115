import { ChangeEvent, memo, useCallback, useState } from 'react';

import { SearchIcon } from '@/assets/buttons/SearchIcon';

import { BoxWrapper, CloseIconStyled, TextFieldStyled } from './styles';
import type { SearchInputProps } from './types';

function SearchInputInner({ value, onChange }: SearchInputProps) {
	const [focused, setFocused] = useState(false);
	const handleInputChange = useCallback(
		(event: ChangeEvent<HTMLInputElement>) => {
			onChange(event.target.value);
		},
		[]
	);

	const clearHandler = useCallback(() => {
		onChange('');
	}, []);

	const handleFocus = useCallback(() => setFocused((prev) => !prev), []);

	return (
		<BoxWrapper>
			<TextFieldStyled
				label="Search"
				value={value}
				onChange={handleInputChange}
				onFocus={handleFocus}
				onBlur={handleFocus}
				size="medium"
				endAdornment={
					value ? (
						<CloseIconStyled onClick={clearHandler} />
					) : (
						!focused && <SearchIcon style={{ marginRight: 12 }} />
					)
				}
			/>
		</BoxWrapper>
	);
}
export const SearchInput = memo(SearchInputInner);
