import { Autoselect, Button } from '@mms/mms-ui-library';
import moment, { Moment } from 'moment';
import numeral from 'numeral';
import { useCallback, useEffect, useState } from 'react';

import { AutocompleteOption } from '@/components/Autocomplete/types';
import {
	NOT_SELECTED_ISSUE_DATE,
	NOT_SELECTED_RETURN_DATE,
	NOT_SELECTED_USER,
	ENTER_VALID_RETURN_DATE,
	Roles,
} from '@/constants/index';
import { useEquipmentContext } from '@/context/Equipment';
import { useKnowledgeBase } from '@/context/KnowledgeBase';
import { useToast } from '@/context/Toast';
import { DatePicker } from '@/fields/DatePicker';
import { getDateAsResponseType } from '@/helpers/booking';
import { getFullName } from '@/helpers/utils';
import { useAllUserFilter } from '@/hooks/useAllUserFilter';
import { useCreateEquipment } from '@/queries/equipment';
import { useGetEquipmentHistory } from '@/queries/equipment/useGetEquipmentHistory';
import { useUpdateEquipment } from '@/queries/equipment/useUpdateEquipment';
import { useUsersLookup } from '@/queries/useUsersLookup';
import type {
	ActiveHolder,
	EquipmentHistoryItem,
	EquipmentItemResponse,
} from '@/types/Equipment';
import { KnowledgeType } from '@/types/KnowledgeBase';

import { DEFAULT_VALUE, initUserFilter } from './constants';
import { Wrapper, DatePickerWrapper } from './styles';
import type { InitUserFilterState, UserEquipmentFormProps } from './types';

const { CEO, CTO, HD } = Roles;

export function UserEquipmentForm({
	isEdit,
	setHistoryItems,
	historyItems,
	equipmentId,
	refetchGrid,
	onLoad,
}: UserEquipmentFormProps) {
	const toast = useToast();
	const userFilterOptions = useAllUserFilter();
	const { data, isLoading, refetch } = useGetEquipmentHistory(
		toast,
		equipmentId
	);
	const [lastUser, setLastUser] = useState<EquipmentHistoryItem | null>();
	const isEquipmentAssigned =
		Boolean(lastUser?.issueDate) && Number.isInteger(lastUser?.id);
	const isEquipmentReturned = Boolean(lastUser?.returnDate);
	const isDisabled = isEquipmentAssigned && !isEquipmentReturned;

	useEffect(() => {
		if (data) {
			setLastUser(data[0]);
		} else {
			setLastUser(null);
		}
	}, [data]);

	useEffect(() => {
		if (!isLoading) {
			setHistoryItems(data || []);
			setLastUser(data ? data[0] : null);
		}
	}, [data, isLoading]);
	const { knowledgeBase } = useKnowledgeBase();
	const { EquipmentLocationType, EquipmentType } = knowledgeBase;
	const { data: usersData } = useUsersLookup('', [CEO, CTO, HD]);
	const approver = (usersData || []).map(({ id, firstName, lastName }) => ({
		id,
		name: getFullName(firstName, lastName),
		description: getFullName(firstName, lastName),
	})) as KnowledgeType[];

	const { equipmentModel, setEquipmentModel } = useEquipmentContext();
	const { mutate } = useCreateEquipment(toast);
	const { mutate: updateEquipment } = useUpdateEquipment(toast);
	const [userFilter, setUserFilter] =
		useState<InitUserFilterState>(initUserFilter);

	useEffect(() => {
		onLoad(isLoading);
	}, [isLoading]);

	useEffect(() => {
		if (
			!historyItems ||
			historyItems.length === 0 ||
			historyItems[0]?.returnDate !== null
		) {
			setLastUser(null);
			setUserFilter(initUserFilter);
			setEquipmentModel({ ...equipmentModel, activeHolder: null });
		} else {
			setLastUser(historyItems[0]);
		}
	}, [historyItems]);

	useEffect(() => {
		if (lastUser && isEquipmentAssigned && !isEquipmentReturned) {
			setUserFilter({
				returnDate: lastUser.returnDate ? moment(lastUser?.returnDate) : null,
				name: getFullName(
					lastUser?.firstName as string,
					lastUser?.lastName as string
				),
				selectedUserId: (lastUser?.id as number).toString(),
				issueDate: moment(lastUser?.issueDate),
				id: equipmentId,
			});
			setEquipmentModel({
				...equipmentModel,
				activeHolder: {
					id: lastUser?.id,
					issueDate: moment(lastUser?.issueDate),
				} as ActiveHolder,
			});
		}
	}, [data, isEquipmentAssigned]);
	const [errorMessage, setErrorMessage] = useState<null | string>(null);
	const [issueDateErrorMessage, setIssueDateErrorMessage] = useState('');
	const [returnDateErrorMessage, setReturnDateErrorMessage] = useState('');

	const filteredUserOptions = [
		{ id: 0, name: DEFAULT_VALUE, description: DEFAULT_VALUE },
		...userFilterOptions,
	];

	const hasNoSelectedUserId =
		errorMessage !== null && userFilter.selectedUserId === '';
	const hasNoIssueDate = issueDateErrorMessage !== '' && !userFilter.issueDate;
	const hasNoReturnDate =
		returnDateErrorMessage !== '' && !userFilter.returnDate;

	const usersFilterValue = userFilter?.name ? userFilter : initUserFilter;

	const handleUserChange = (
		newValue: Pick<InitUserFilterState, 'id' | 'name'>
	) => {
		const selectedUserId = newValue?.id ?? '';
		const name = newValue?.name || '';
		setUserFilter({
			...userFilter,
			name,
			selectedUserId,
		});
	};

	const handleIssueDateChange = (newValue: Moment | null) => {
		setUserFilter({ ...userFilter, issueDate: newValue });
	};

	const handleReturnDateChange = (newValue: Moment | null) => {
		setUserFilter({ ...userFilter, returnDate: newValue });
	};

	const handleSuccessResponse = (response: EquipmentItemResponse) => {
		setHistoryItems([response.activeHolder, ...historyItems]);

		setLastUser(response.activeHolder);

		setErrorMessage(null);

		setIssueDateErrorMessage('');

		setReturnDateErrorMessage('');

		setUserFilter(initUserFilter);

		refetchGrid();
	};
	const handleSubmitForm = useCallback(() => {
		if (!userFilter.selectedUserId) {
			setErrorMessage(NOT_SELECTED_USER);
		}

		if (!userFilter.issueDate) {
			setIssueDateErrorMessage(NOT_SELECTED_ISSUE_DATE);
		}

		if (!userFilter.returnDate && !isEquipmentReturned && isEquipmentAssigned) {
			setReturnDateErrorMessage(NOT_SELECTED_RETURN_DATE);
		}

		if (
			userFilter.selectedUserId === '' ||
			!userFilter.issueDate ||
			(!userFilter.returnDate && !isEquipmentReturned && isEquipmentAssigned)
		) {
			return;
		}

		if (
			moment(userFilter.returnDate)
				.add(3, 'hours')
				.isBefore(moment(userFilter.issueDate), 'day')
		) {
			setReturnDateErrorMessage(ENTER_VALID_RETURN_DATE);

			return;
		}

		const issueDate = getDateAsResponseType(
			userFilter.issueDate as unknown as Moment
		) as unknown as Moment;
		const request = {
			...equipmentModel,
			purchasePrice: numeral(equipmentModel.purchasePrice).value() ?? undefined,
			purchasePriceUsd:
				numeral(equipmentModel.purchasePriceUsd).value() ?? undefined,
			type: EquipmentType.find(
				(type) =>
					type.name === equipmentModel.equipmentType ||
					type.description === equipmentModel.equipmentType
			)?.id,
			equipmentType: EquipmentType.find(
				(type) =>
					type.name === equipmentModel.equipmentType ||
					type.description === equipmentModel.equipmentType
			)?.id as string,
			location: EquipmentLocationType.find(
				(type) => type.description === equipmentModel.location
			)?.id as string,
			approverId: approver.find(
				(type) =>
					type.description === (equipmentModel.approver as unknown as string)
			)?.id as string,
			activeHolderRequest: {
				userId: Number(userFilter.selectedUserId),
				issueDate,
			},
		};

		if (isEquipmentAssigned && !isEquipmentReturned) {
			updateEquipment(
				{
					...request,
					activeHolderRequest: {
						...request.activeHolderRequest,
						returnDate: userFilter.returnDate
							? (getDateAsResponseType(
									userFilter.returnDate
							  ) as unknown as Moment)
							: undefined,
					},
					equipmentId,
				},
				{
					onSuccess: (response) => {
						handleSuccessResponse(response);
						refetch();
					},
				}
			);

			return;
		}

		if (equipmentId) {
			updateEquipment(
				{
					...request,
					equipmentId,
				},
				{
					onSuccess: handleSuccessResponse,
				}
			);
		} else {
			mutate(
				{ ...request },
				{
					onSuccess: handleSuccessResponse,
				}
			);
		}
	}, [userFilter]);

	return (
		<Wrapper>
			<Autoselect
				label="User"
				value={usersFilterValue.name === '' ? null : usersFilterValue}
				valueField="name"
				options={filteredUserOptions as Array<AutocompleteOption>}
				onChange={handleUserChange as any}
				error={hasNoSelectedUserId}
				errorText={hasNoSelectedUserId ? errorMessage : ''}
				disabled={isDisabled}
			/>
			{!isEdit ? (
				<DatePicker
					value={userFilter.issueDate}
					label="Issue date"
					onChange={handleIssueDateChange}
					error={hasNoIssueDate}
					helperText={hasNoIssueDate ? issueDateErrorMessage : ''}
					disabled={isDisabled}
				/>
			) : (
				<DatePickerWrapper>
					<DatePicker
						value={userFilter.issueDate}
						label="Issue date"
						onChange={handleIssueDateChange}
						error={hasNoIssueDate}
						disabled={isEdit && isDisabled}
						helperText={hasNoIssueDate ? issueDateErrorMessage : ''}
						sx={{ width: 171 }}
					/>

					<DatePicker
						value={userFilter.returnDate}
						onChange={handleReturnDateChange}
						error={hasNoReturnDate || Boolean(returnDateErrorMessage)}
						helperText={returnDateErrorMessage}
						disabled={!isEquipmentAssigned || isEquipmentReturned}
						sx={{ width: 171 }}
						label="Return date"
					/>
				</DatePickerWrapper>
			)}

			<Button onClick={handleSubmitForm}>Apply</Button>
		</Wrapper>
	);
}
