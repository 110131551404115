import axios from 'axios';
import { useQuery } from 'react-query';

import { LOGOUT_URL } from '@/constants/queryPaths';

export const useLogout = () =>
	useQuery({
		queryFn: () => axios.get(LOGOUT_URL).then((res) => res.data),
		enabled: false,
	});
